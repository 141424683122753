import styled from 'styled-components';
import {
  backgroundColor,
  gray3,
  primaryColor,
  titleFont,
} from '../../../../../assets/theme/themeVariables';

const SubMenu = styled.li`
  width: 100%;

  .submenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      width: 100%;
      height: 34px;
      font-family: ${titleFont};
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${backgroundColor};
        color: ${primaryColor};
      }
    }
  }

  .tab {
    color: ${gray3};
    text-decoration: underline;
    margin-bottom: 0;

    &:hover {
      color: ${primaryColor};
      text-decoration: none;
    }
  }
`;

export default SubMenu;
