const selectCreateMemberErrors = state => state.subscriptions.createMemberErrors;
const selectEmailIsValid = state => state.subscriptions.emailIsValid;
const selectLoadingCreate = state => state.subscriptions.loadingCreate;
const selectLoadingUpdate = state => state.subscriptions.loadingUpdate;
const selectLoadingValidateEmail = state => state.subscriptions.loadingValidateEmail;
const selectUpdateMemberErrors = state => state.subscriptions.updateMemberErrors;

export default {
  selectCreateMemberErrors,
  selectEmailIsValid,
  selectLoadingCreate,
  selectLoadingUpdate,
  selectLoadingValidateEmail,
  selectUpdateMemberErrors,
};
