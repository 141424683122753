import styled from 'styled-components';

const MyEvents = styled.div`
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 720px) {
    padding: 0 30px;
  }
`;

export default MyEvents;
