import styled from 'styled-components';
import {
  backgroundColor,
  black,
} from '../../../assets/theme/themeVariables';

const Step7 = styled.div`
  background-color: ${backgroundColor};

  .title {
    margin-bottom: 16px;
  }

  .text {
    margin-bottom: 36px;
    color: ${black};
    font-weight: normal;
  }
`;

export default Step7;
