import types from './actionTypes';

const initialState = {
  currentVehicle: null,
  currentVehicleErrors: {},
  loadingVehiclesList: false,
  loadingVehicle: false,
  vehiclesSearchResults: {},
  vehiclesListErrors: {},
};

const searchVehicles = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CLEAR_VEHICLE_LIST_RESULTS: {
      return {
        ...state,
        vehiclesSearchResults: {},
      };
    }
    case types.GET_VEHICLE_SEARCH_PROFILE_REQUEST: {
      return {
        ...state,
        currentVehicle: null,
        currentVehicleErrors: {},
        loadingVehicle: true,
      };
    }
    case types.GET_VEHICLE_SEARCH_PROFILE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        currentVehicle: data,
        loadingVehicle: false,
      };
    }
    case types.GET_VEHICLE_SEARCH_PROFILE_FAIL: {
      const { data } = (payload.response || {});
      return {
        ...state,
        currentVehicleErrors: data,
        loadingVehicle: false,
      };
    }
    case types.GET_VEHICLES_SEARCH_RESULTS_REQUEST: {
      return {
        ...state,
        loadingVehiclesList: true,
        vehiclesListErrors: {},
      };
    }
    case types.GET_VEHICLES_SEARCH_RESULTS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingVehiclesList: false,
        vehiclesSearchResults: data,
      };
    }
    case types.GET_VEHICLES_SEARCH_RESULTS_FAIL: {
      const { data } = (payload.response || {});
      return {
        ...state,
        loadingVehiclesList: false,
        vehiclesListErrors: data,
        vehiclesSearchResults: {},
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default searchVehicles;
