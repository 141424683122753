import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Step3 from './styles';
import {
  plansActions,
  plansSelectors,
} from '../../../store/ducks/plans';

import {
  H5,
  Paragraph,
} from '../../../components/common/Text';
import Button from '../../../components/common/Button';
import RadioCarts from '../../../components/Forms/RadioCarts';

const MSG_TEXT = 'Select a membership level from the options below (view our Membership FAQ for full list of membership types). Once you’ve completed this step, you will be prompted for regional and associate memberships before checkout.';

const Step3Component = ({
  data,
  handleNextStep,
  loadingPlans,
  nationalPlans,
  prevStep,
}) => {
  const [chosenPlan, setChosenPlan] = useState(data.nationalPlan || null);
  const [errors, setErrors] = useState({});

  const changePlan = useCallback((value) => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
    setChosenPlan(value);
  }, [errors]);

  const handleSubmit = useCallback(() => {
    if (!chosenPlan) {
      return setChosenPlan({
        error: 'Select a membership level',
      });
    }
    return handleNextStep({ nationalPlan: chosenPlan });
  }, [chosenPlan, handleNextStep]);

  return (
    <Step3>
      <H5 className="title">STEP 3: SELECT NATIONAL MEMBERSHIP(S)</H5>
      <Paragraph className="msg-text">{MSG_TEXT}</Paragraph>
      <RadioCarts
        data={nationalPlans.filter((plan) => (plan.title === 'LIFE Member (USA)') ? 0 : 1)}
        loading={loadingPlans}
        onChange={changePlan}
        value={chosenPlan}
      />
      <div className="errors">
        {Object.keys(errors).map(e => <p key={e} className="error">{errors[e]}</p>)}
      </div>
      <div className="btnWrapper">
        <Button
          disabled={!chosenPlan}
          title="SAVE & CONTINUE TO STEP 4"
          onClick={handleSubmit}
        />
        {/* <Button
          onClick={() => {}}
          title="SAVE"
        /> */}
        <Button
          title="BACK"
          styleType="border-less"
          onClick={prevStep}
        />
      </div>
    </Step3>
  );
};

Step3Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  loadingPlans: PropTypes.bool,
  nationalPlans: PropTypes.array,
  prevStep: PropTypes.func.isRequired,
};

Step3Component.defaultProps = {
  loadingPlans: false,
  nationalPlans: null,
};

const mapStateToProps = state => ({
  loadingPlans: plansSelectors.selectLoadingNationalPlans(state),
  nationalPlans: plansSelectors.selectNationalPlans(state),
});

const mapDispatchToProps = {
  getNationalPlans: plansActions.getNationalPlans,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step3Component);
