import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MemberSearchForm from './styles';

import InputField from '../../common/InputField';
import Button from '../../common/Button';
import SelectField from '../../common/Select';

import {
  locationActions,
  locationSelectors,
} from '../../../store/ducks/location';

import {
  plansActions,
  plansSelectors,
} from '../../../store/ducks/plans';


const MemberSearchFormComponent = ({
  countries,
  getCountries,
  initData,
  loadingCountries,
  loadingRegionalPlans,
  onReset,
  onSubmit,
  getRegionPlans,
  regionalPlans,
  loadingRegions,
  regions,
  getRegion,
}) => {
  useEffect(() => {
    if (!Object.keys(countries).length) {
      getCountries();
    }
  }, [countries, getCountries]);

  const countriesOptions = useMemo(() => (
    [
      { label: 'All', value: '' },
      ...Object.keys(countries).map(e => ({ label: e, value: countries[e] })),
    ]
  ), [countries]);

  useEffect(() => {
    if (!regionalPlans) {
      getRegionPlans();
    }
  }, [getRegionPlans, regionalPlans]);


  const regionsOptions = useMemo(() => (
    [
      { label: 'Any Regions', value: 'Any' },
      ...(regionalPlans || []).map(e => ({ label: e.title, value: e.regionCode })),
    ]
  ), [regionalPlans]);

  const regionsForSelect = useMemo(() => (
    regions.map(e => ({ label: e, value: e }))
  ), [regions]);

  const onCountryChange = useCallback((name, value, callback) => {
    getRegion({ countryCode: value });

    callback('state', '');
    callback(name, value);
  }, [getRegion]);

  const handleSubmit = useCallback((fields) => {
    console.log('formulario')
    const filteredValues = Object.keys(fields)
      .filter(e => fields[e])
      .reduce((obj, curr) => ({
        ...obj,
        [curr]: fields[curr],
      }), {});
    onSubmit(filteredValues);
  }, [onSubmit]);

  return (
    <MemberSearchForm>
      <Formik
        key={JSON.stringify(initData)}
        initialValues={{
          firstName: initData.firstName || '',
          lastName: initData.lastName || '',
          email: initData.email || '',
          city: initData.city || '',
          country: initData.country || '',
          state: initData.state || '',
          region: initData.region || '',
        }}
        validationSchema={
          Yup.object()
            .shape({
              firstName: Yup.string(),
              lastName: Yup.string(),
              email: Yup.string(),
              city: Yup.string(),
              country: Yup.string(),
              state: Yup.string(),
              region: Yup.string(),
            })
        }
        onSubmit={handleSubmit}
      >
        {({
          errors,
          resetForm,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <div className="form-container">
              <InputField
                errors={errors}
                label="FIRST NAME"
                name="firstName"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="LAST NAME"
                name="lastName"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="EMAIL"
                name="email"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="CITY"
                name="city"
                touched={touched}
              />
              <SelectField
                errors={errors}
                label="COUNTRY"
                loading={loadingCountries}
                name="country"
                onChange={(name, val) => onCountryChange(name, val, setFieldValue)}
                options={countriesOptions}
                touched={touched}
                values={values}
              />
              <SelectField
                errors={errors}
                label="STATE/PROVINCE"
                name="state"
                touched={touched}
                loading={loadingRegions}
                onChange={setFieldValue}
                options={regionsForSelect}
                required
                values={values}
              />
              <SelectField
                errors={errors}
                label="REGION"
                loading={loadingRegionalPlans}
                name="region"
                onChange={setFieldValue}
                options={regionsOptions}
                touched={touched}
                values={values}
              />
            </div>
            <div className="errors">
              {Object.keys(errors)
                .map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
            </div>
            <div className="btn-wrapper">
              <Button
                disabled={!Object.values(values).find(e => Boolean(e))}
                title="SEARCH"
                type="submit"
              />
              <Button
                onClick={() => {
                  resetForm();
                  onReset();
                }}
                styleType="border-less"
                title="CLEAR SEARCH FORM"
              />
            </div>
          </Form>
        )}
      </Formik>
    </MemberSearchForm>
  );
};

MemberSearchFormComponent.propTypes = {
  countries: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  getRegionPlans: PropTypes.func.isRequired,
  initData: PropTypes.object,
  loadingCountries: PropTypes.bool,
  loadingRegionalPlans: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  regionalPlans: PropTypes.array,
  loadingRegions: PropTypes.bool,
  getRegion: PropTypes.func.isRequired,
};

MemberSearchFormComponent.defaultProps = {
  initData: {},
  loadingCountries: true,
  loadingRegionalPlans: true,
  regionalPlans: null,
  loadingRegions: false,
};

const mapStateToProps = state => ({
  countries: locationSelectors.selectCountries(state),
  loadingCountries: locationSelectors.selectLoadingCountries(state),
  loadingRegionalPlans: plansSelectors.selectLoadingRegionalPlans(state),
  regionalPlans: plansSelectors.selectRegionalPlans(state),
  regions: locationSelectors.selectRegions(state),
  loadingRegions: locationSelectors.selectLoadingRegions(state),
});

const mapDispatchToProps = {
  getCountries: locationActions.getCountries,
  getRegionPlans: plansActions.getRegionPlans,
  getRegion: locationActions.getRegions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberSearchFormComponent);
