import styled from 'styled-components';

const NotificationModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .buttons-wrapper {
    margin-top: 20px;
  }
`;

export default NotificationModal;
