import React from 'react';
import {
  Link,
  Paragraph,
} from '../Text';
import Footer from './styles';

import { footerLogo, speedDigitalLogo } from '../../../assets/images';
import links from '../../../utils/links';

const linksList = [
  { externalLink: true, href: links.events, label: 'EVENTS' },
  { externalLink: true, href: links.news, label: 'NEWS' },
  { externalLink: true, href: links.store, label: 'STORE' },
  { externalLink: false, href: links.login, label: 'SIGN IN' },
  { externalLink: false, href: links.join, label: 'JOIN' },
];

const FooterComponent = () => {
  const renderLinks = linksList.map(({ externalLink, href, label }) => (
    <Link
      className="link"
      externalLink={externalLink}
      key={label}
      href={href}
    >
      <span className="label">{label}</span>
    </Link>
  ));

  return (
    <Footer>
      <div className="first-row">
        <Link href={links.mainDomain} externalLink>
          <img src={footerLogo} className="footer-logo" alt=""/>
        </Link>
        <div className="links">
          {renderLinks}
        </div>
      </div>
      <div className="wrapper">
        <div className="footer-wrapper">
          <div className="hr" />
          <Link href={links.speedDigital} externalLink>
            <img src={speedDigitalLogo} className="icon" alt=""/>
          </Link>
          <div className="hr" />
        </div>

        <div className="footer-wrapper text-wrapper">
          <div>
            <Paragraph className="paragraph">
              ©{new Date().getFullYear()} Classic Car Club of America. All Rights Reserved.
            </Paragraph>
          </div>
          <div className="button-wrapper">
            <Link
              className="p-with-border signature-text"
              externalLink
              href={links.privacyPolicy}
            >
              Privacy Notice
            </Link>
            <Link
              className="p-with-border signature-text"
              externalLink
              href={links.advertise}
            >
              Advertise
            </Link>
            <Link
              className="signature-text"
              externalLink
              href={links.sponsors}
            >
              Sponsors
            </Link>
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default FooterComponent;
