import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Step5 from './styles';
import {
  plansSelectors,
} from '../../../store/ducks/plans';

import { H5, Paragraph } from '../../../components/common/Text';
import RegionalBoxes from '../../../components/Forms/RegionalBoxes';
import Button from '../../../components/common/Button';

const MSG_TEXT = 'Choose one or more regional memberships for which you would like to register along with this national membership (for more information on a particular region, simply click on its name). If you do not wish to join any regional clubs, do not check any boxes and click the "Continue" button to proceed.';

const Step5Component = ({
  data,
  handleNextStep,
  prevStep,
  regionalPlans,
}) => {
  const [values, setValues] = useState(data.regionalPlans || []);

  const handleFormData = useCallback(() => (
    handleNextStep({ regionalPlans: values })
  ), [handleNextStep, values]);

  return (
    <Step5>
      <H5 className="title">STEP 5: SELECT REGIONAL MEMBERSHIP(S)</H5>
      <Paragraph className="msg-text">{MSG_TEXT}</Paragraph>
      <RegionalBoxes
        data={data}
        onChange={setValues}
        regionalPlans={regionalPlans}
        values={values}
      />
      <div className="btnWrapper">
        <Button
          title="SAVE & CONTINUE TO STEP 6"
          onClick={handleFormData}
        />
        {/* <Button
          onClick={() => {}}
          title="SAVE"
        /> */}
        <Button
          title="BACK"
          styleType="border-less"
          onClick={prevStep}
        />
      </div>
    </Step5>
  );
};

Step5Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  regionalPlans: PropTypes.array,
  prevStep: PropTypes.func.isRequired,
};

Step5Component.defaultProps = {
  regionalPlans: [],
};

const mapStateToProps = state => ({
  regionalPlans: plansSelectors.selectRegionalPlans(state),
});

export default connect(
  mapStateToProps,
  null,
)(Step5Component);
