import styled from 'styled-components';
import {
  backgroundColor,
  gray1,
} from '../../../assets/theme/themeVariables';

const GivingOpportunities = styled.div`
  background-color: ${backgroundColor};

  .section {
    &:first-child {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid ${gray1};
    }

    .fields {
      margin: 30px 0;

      .checkbox {
        margin-bottom: 20px;
      }

      .text-input {
        max-width: 300px;
      }

      .select {
        max-width: 300px;
        margin-bottom: 26px;
      }
    }
  }

  .errors {
    padding-top: 20px;
  }

  .button-wrapper {
    margin-top: 20px;

    button {
      max-width: max-content;
    }
  }

  @media screen and (max-width: 325px) {
    .button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export default GivingOpportunities;
