import types from './actionTypes';

const initialState = {
  events: null,
  loadingEvents: false,
  loadingRegisteredEvents: false,
  registeredEvents: null,
};

const events = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_ALL_EVENTS_REQUEST: {
      return {
        ...state,
        loadingEvents: true,
      };
    }
    case types.GET_ALL_EVENTS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingEvents: false,
        events: data,
      };
    }
    case types.GET_ALL_EVENTS_FAIL: {
      return {
        ...state,
        loadingEvents: false,
      };
    }
    case types.GET_REGISTERED_EVENTS_REQUEST: {
      return {
        ...state,
        loadingRegisteredEvents: true,
      };
    }
    case types.GET_REGISTERED_EVENTS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingRegisteredEvents: false,
        registeredEvents: data,
      };
    }
    case types.GET_REGISTERED_EVENTS_FAIL: {
      return {
        ...state,
        loadingRegisteredEvents: false,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default events;
