import styled from 'styled-components';
import {
  backgroundColor,
  gray4,
} from '../../assets/theme/themeVariables';

const Join = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${backgroundColor};
  padding-bottom: 278px;
  box-sizing: border-box;
  position: relative;

  .content {
    padding: 60px;

    .separator {
      width: 100%;
      height: 1px;
      background-color: ${gray4};
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 300px;
  }

  @media screen and (max-width: 576px) {
    .content {
      padding: 30px;
    }
  }
`;

export default Join;
