const selectCurrentVehicle = state => state.searchVehicles.currentVehicle;
const selectCurrentVehicleErrors = state => state.searchVehicles.currentVehicleErrors;
const selectLoadingVehiclesList = state => state.searchVehicles.loadingVehiclesList;
const selectLoadingVehicle = state => state.searchVehicles.loadingVehicle;
const selectVehiclesListErrors = state => state.searchVehicles.vehiclesListErrors;
const selectVehiclesSearchResults = state => state.searchVehicles.vehiclesSearchResults;

export default {
  selectCurrentVehicle,
  selectCurrentVehicleErrors,
  selectLoadingVehiclesList,
  selectLoadingVehicle,
  selectVehiclesListErrors,
  selectVehiclesSearchResults,
};
