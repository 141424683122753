import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logo } from '../../../assets/images';
import VehicleCard from './styles';

import {
  H2,
  H5,
  Paragraph,
} from '../../common/Text';
import TextButton from '../../common/TextButton';

import {
  memberSelectors,
} from '../../../store/ducks/member';
import {
  vehiclesActions,
  vehiclesSelectors,
} from '../../../store/ducks/vehicles';

const VehicleCardComponent = ({
  associateName,
  deleteVehicle,
  makes,
  memberName,
  onModifyVehicle,
  vehicle,
}) => {
  const {
    bodyMaker,
    bodyNumber,
    bodyStyle,
    engineNumber,
    id,
    makeId,
    model,
    ownedByAssociate,
    images,
    serialNumber,
    seniorCategory,
    year,
  } = vehicle;
  const ownerName = ownedByAssociate
    ? (associateName || 'Associate') : (memberName || 'Member');

  const make = useMemo(() => ((makes || []).find(e => e.id === makeId) || {}).name || '', [makeId, makes]);

  const renderImage = useCallback(() => {
    const src = (images || {}).length ? images[0] : logo;

    return (
      <div className="image">
        <img
          alt=""
          className="photo"
          src={src}
        />
      </div>
    );
  }, [images]);

  const renderVehiclePropsRow = useCallback(arr => arr.reduce((acc, elem) => {
    if (!(elem || '').trim() || elem === 'None') {
      return acc;
    }
    if (!acc) {
      return elem;
    }
    return `${acc}, ${elem}`;
  }, ''), []);

  return (
    <VehicleCard>
      {renderImage()}
      <div className="vehicle-description">
        <H5 className="first-row">
          {`${ownerName}’s ${year} ${make}`.toLocaleUpperCase()}
        </H5>
        <H2 className="second-row">
          {`${model} ${bodyStyle}`}
        </H2>
        <div className="details">
          <Paragraph className="details-text">
            {renderVehiclePropsRow([seniorCategory, bodyStyle, bodyMaker])}
          </Paragraph>
          <Paragraph className="details-text">
            {renderVehiclePropsRow([serialNumber, bodyNumber, engineNumber])}
          </Paragraph>
        </div>
        <div className="button-block">
          <TextButton
            onClick={() => onModifyVehicle(vehicle)}
            title="Modify Vehicle"
          />
          <div className="separator" />
          <TextButton
            onClick={() => {
              deleteVehicle(id);
              onModifyVehicle(null);
            }}
            title="Delete Vehicle"
          />
        </div>
      </div>
    </VehicleCard>
  );
};

VehicleCardComponent.propTypes = {
  associateName: PropTypes.string,
  deleteVehicle: PropTypes.func.isRequired,
  makes: PropTypes.array,
  memberName: PropTypes.string,
  onModifyVehicle: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired,
};

VehicleCardComponent.defaultProps = {
  associateName: '',
  makes: null,
  memberName: '',
};

const mapStateToProps = state => ({
  associateName: memberSelectors.selectAssociateName(state),
  makes: vehiclesSelectors.selectMakes(state),
  memberName: memberSelectors.selectMemberName(state),
});

const mapDispatchToProps = {
  deleteVehicle: vehiclesActions.deleteVehicle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleCardComponent);
