const selectCreateErrors = state => state.vehicles.createErrors;
const selectLoadingCreateVehicles = state => state.vehicles.loadingCreateVehicles;
const selectLoadingDeleteVehicles = state => state.vehicles.loadingDeleteVehicles;
const selectLoadingMakes = state => state.vehicles.loadingMakes;
const selectLoadingVehicles = state => state.vehicles.loadingVehicles;
const selectLoadingUpdateVehicles = state => state.vehicles.loadingUpdateVehicles;
const selectMakes = state => state.vehicles.makes;
const selectUpdateErrors = state => state.vehicles.updateErrors;
const selectVehicles = state => state.vehicles.vehicles;

export default {
  selectCreateErrors,
  selectLoadingCreateVehicles,
  selectLoadingDeleteVehicles,
  selectLoadingMakes,
  selectLoadingVehicles,
  selectLoadingUpdateVehicles,
  selectMakes,
  selectUpdateErrors,
  selectVehicles,
};
