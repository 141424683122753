import React from 'react';
import PropTypes from 'prop-types';
import BasicInfoList from './styles';

import links from '../../utils/links';
import {
  book,
  house,
  location,
  notebook,
  star,
} from '../../assets/images';

import {
  HighlightedText,
  Link,
  Paragraph,
} from '../common/Text';

const INFO_ROWS = [
  {
    image: notebook,
    message: 'Download our printable Membership Form to join or renew by mail.',
    href: links.membershipFormPdf,
    title: 'MEMBERSHIP FORM',
  }, {
    image: star,
    message: 'Learn why our members believe the CCCA experience is unequalled.',
    href: links.ourClub,
    title: 'About Our Club',
  }, {
    image: location,
    message: 'Interested in getting more involved in your community? Contact your local region to become a member.',
    href: links.regionalClubs,
    title: 'JOIN YOUR LOCAL REGION',
  }, {
    image: house,
    message: 'Gear up and show off your membership pride with our large selection of branded merchadise.',
    href: links.sale,
    title: 'CCCA STORE',
  }, {
    image: book,
    message: 'Review our official listing of approved classic cars to see if yours made the list.',
    href: links.approvedClassics,
    title: 'LIST OF APPROVED CCCA CLASSIC CARS',
  },
];

const BasicInfoListComponent = ({ className }) => {
  const renderRow = item => (
    <Link
      className="row"
      externalLink
      key={item.title}
      href={item.href}
    >
      <div className="image-container">
        <img alt="" src={item.image} />
      </div>
      <div className="text-container">
        <HighlightedText>{item.title}</HighlightedText>
        <Paragraph>{item.message}</Paragraph>
      </div>
    </Link>
  );

  return (
    <BasicInfoList className={className}>
      {INFO_ROWS.map(renderRow)}
    </BasicInfoList>
  );
};

BasicInfoListComponent.propTypes = {
  className: PropTypes.string,
};

BasicInfoListComponent.defaultProps = {
  className: '',
};

export default BasicInfoListComponent;
