const CLEAR_VEHICLE_LIST_RESULTS = 'CLEAR_VEHICLE_LIST_RESULTS';

const GET_VEHICLES_SEARCH_RESULTS_REQUEST = 'GET_VEHICLES_SEARCH_RESULTS_REQUEST';
const GET_VEHICLES_SEARCH_RESULTS_SUCCESS = 'GET_VEHICLES_SEARCH_RESULTS_SUCCESS';
const GET_VEHICLES_SEARCH_RESULTS_FAIL = 'GET_VEHICLES_SEARCH_RESULTS_FAIL';

const GET_VEHICLE_SEARCH_PROFILE_REQUEST = 'GET_VEHICLE_SEARCH_PROFILE_REQUEST';
const GET_VEHICLE_SEARCH_PROFILE_SUCCESS = 'GET_VEHICLE_SEARCH_PROFILE_SUCCESS';
const GET_VEHICLE_SEARCH_PROFILE_FAIL = 'GET_VEHICLE_SEARCH_PROFILE_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  CLEAR_VEHICLE_LIST_RESULTS,

  GET_VEHICLES_SEARCH_RESULTS_REQUEST,
  GET_VEHICLES_SEARCH_RESULTS_SUCCESS,
  GET_VEHICLES_SEARCH_RESULTS_FAIL,

  GET_VEHICLE_SEARCH_PROFILE_REQUEST,
  GET_VEHICLE_SEARCH_PROFILE_SUCCESS,
  GET_VEHICLE_SEARCH_PROFILE_FAIL,

  RESET_STATE,
};
