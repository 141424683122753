import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import Header from './styles';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const REDESIGN_NOTIFICATION_TEXT = "We're redesigning our site! Click here to learn more!";

const HeaderComponent = () => {
  useEffect(() => {
    const gtmScript = document.createElement('script');

    gtmScript.type = 'text/javascript';
    gtmScript.async = true;
    gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-134342051-1';

    document.body.appendChild(gtmScript);
  });

  useEffect(() => {
    const dataLayerScript = document.createElement('script');

    dataLayerScript.type = 'text/javascript';

    var strLayer = "window.dataLayer = window.dataLayer || [];"
    strLayer = strLayer + "function gtag(){dataLayer.push(arguments);}"
    strLayer = strLayer + "gtag('js', new Date());"
    strLayer = strLayer + "gtag('config', 'UA-134342051-1');"

    dataLayerScript.innerHTML = strLayer;

    document.body.appendChild(dataLayerScript);
  });

  const [isNotificationClosed, setIsNotificationClosed] = useState(true);

  const handleClose = useCallback(() => {
    setIsNotificationClosed(true);
    localStorage.setItem('redesignNotificationClosed', true);
  }, []);

  const renderNotificationRow = useCallback(() => (
    isNotificationClosed
      ? null
      : (
        <div className="notification-row">
          <a className="notification-text" href="/login">{REDESIGN_NOTIFICATION_TEXT}</a>
          <button className="close-btn" onClick={handleClose} type="button">+</button>
        </div>
      )
  ), [
    handleClose,
    isNotificationClosed,
  ]);

  return (
    <Header>
      {renderNotificationRow()}
      <DesktopHeader />
      <MobileHeader isNotificationClosed={isNotificationClosed} />
    </Header>
  );
};

export default HeaderComponent;
