import styled from 'styled-components';
import {
  backgroundColor,
  gray0,
  gray3,
  gray7,
  white,
} from '../../assets/theme/themeVariables';

const Login = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${backgroundColor};
  padding-bottom: 278px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .login-container {
    width: 100%;
    max-width: 800px;
    margin: 120px auto;
    padding: 40px 45px;
    box-sizing: border-box;
    background-color: ${white};
  }

  .title {
    margin-bottom: 20px;
  }

  .hr {
    width: 100%;
    height: 1px;
    background-color: ${gray3};
  }

  .login-content {
    margin-top: 35px;
  }

  .login-message {
    margin-bottom: 15px;
  }

  .form-fields-container {
    display: flex;

    .checkbox {
      margin-top: 50px;
      margin-left: 20px;
      color: ${gray7};
    }
  }

  .form-fields {
    margin-bottom: 20px;

    input {
      width: 330px;
      margin-bottom: 10px;
      background-color: ${gray0};
    }
  }

  .buttons-block {
    margin-top: 20px;
    display: flex;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 300px;
  }

  @media screen and (max-width: 668px) {
    .form-fields-container {
      display: flex;
      flex-direction: column;

      .checkbox {
        margin-top: 0;
        margin-left: 0;
      }
    }

    .form-fields {
      input {
        width: 100%;
        max-width: 330px;
      }
    }
  }
`;

export default Login;
