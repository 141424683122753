const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';

const LOGOUT = 'LOGOUT';

const NEW_PASSWORD_ON_RESET_PASSWORD_REQUEST = 'NEW_PASSWORD_ON_RESET_PASSWORD_REQUEST';
const NEW_PASSWORD_ON_RESET_PASSWORD_SUCCESS = 'NEW_PASSWORD_ON_RESET_PASSWORD_SUCCESS';
const NEW_PASSWORD_ON_RESET_PASSWORD_FAIL = 'NEW_PASSWORD_ON_RESET_PASSWORD_FAIL';

const SEND_EMAIL_ON_RESET_PASSWORD_REQUEST = 'SEND_EMAIL_ON_RESET_PASSWORD_REQUEST';
const SEND_EMAIL_ON_RESET_PASSWORD_SUCCESS = 'SEND_EMAIL_ON_RESET_PASSWORD_SUCCESS';
const SEND_EMAIL_ON_RESET_PASSWORD_FAIL = 'SEND_EMAIL_ON_RESET_PASSWORD_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,

  LOGOUT,

  NEW_PASSWORD_ON_RESET_PASSWORD_REQUEST,
  NEW_PASSWORD_ON_RESET_PASSWORD_SUCCESS,
  NEW_PASSWORD_ON_RESET_PASSWORD_FAIL,

  SEND_EMAIL_ON_RESET_PASSWORD_REQUEST,
  SEND_EMAIL_ON_RESET_PASSWORD_SUCCESS,
  SEND_EMAIL_ON_RESET_PASSWORD_FAIL,

  RESET_STATE,
};
