const NATIONAL_PLANS_REQUEST = 'NATIONAL_PLANS_REQUEST';
const NATIONAL_PLANS_SUCCESS = 'NATIONAL_PLANS_SUCCESS';
const NATIONAL_PLANS_FAIL = 'NATIONAL_PLANS_FAIL';

const REGIONAL_PLANS_REQUEST = 'REGIONAL_PLANS_REQUEST';
const REGIONAL_PLANS_SUCCESS = 'REGIONAL_PLANS_SUCCESS';
const REGIONAL_PLANS_FAIL = 'REGIONAL_PLANS_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  NATIONAL_PLANS_REQUEST,
  NATIONAL_PLANS_SUCCESS,
  NATIONAL_PLANS_FAIL,

  REGIONAL_PLANS_REQUEST,
  REGIONAL_PLANS_SUCCESS,
  REGIONAL_PLANS_FAIL,

  RESET_STATE,
};
