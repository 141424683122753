import styled from 'styled-components';
import {
  gray4,
  primaryColor,
} from '../../assets/theme/themeVariables';

const MembershipWrapper = styled.div`
  width: calc(100% / 3);
  padding: 0 40px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: ${props => (props.border ? `1px solid ${gray4}` : 'none')};

  .fields {
    margin-top: 25px;

    .associate-name {
      font-weight: bold;
    }
  }

  .field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .value {
    text-align: end;
    margin-left: 15px;
  }

  .red {
    color: ${primaryColor};
  }

  .button-wrapper {
    margin-top: 40px;
  }

  @media screen and (max-width: 991px) {
    width: auto;
    border-right: none;
    border-bottom: ${props => (props.border ? `1px solid ${gray4}` : 'none')};
    padding: 0;
    margin: 28px 40px 0 40px;

    .button-wrapper {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 720px) {
    margin: 28px 30px 0 30px;
  }
`;

export default MembershipWrapper;
