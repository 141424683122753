import types from './actionTypes';

const initialState = {
  countries: {},
  loadingCountries: false,
  loadingRegions: false,
  regions: [],
};

const location = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.COUNTRIES_REQUEST: {
      return {
        ...state,
        loadingCountries: true,
      };
    }
    case types.COUNTRIES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        countries: data || {},
        loadingCountries: false,
      };
    }
    case types.COUNTRIES_FAIL: {
      return {
        ...state,
        loadingCountries: false,
      };
    }
    case types.REGIONS_REQUEST: {
      return {
        ...state,
        loadingRegions: true,
      };
    }
    case types.REGIONS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingRegions: false,
        regions: data || [],
      };
    }
    case types.REGIONS_FAIL: {
      return {
        ...state,
        loadingRegions: false,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default location;
