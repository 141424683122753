import styled from 'styled-components';
import {
  backgroundColor,
  black,
  gray5,
  white,
  primaryFont,
} from '../../../assets/theme/themeVariables';

const MobileHeader = styled.div`
  padding: 40px 30px 30px 30px;
  background-color: ${backgroundColor};

  .text-wrapper {
    padding-top: 15px;
    position: relative;

    h1 {
      font-size: 48px;
      text-align: left;

      span {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .separator {
      width: 100%;
      height: 1px;
      background-color: ${gray5};
      margin-top: 20px;
    }

  .mobile-tabs-btn {
    .burger-icon {
      width: 34px;
      height: 24px;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    .burger-line {
      width: 22px;
      height: 3px;
      background-color: ${black};
      border-radius: 2px;
    }
  }

  .mobile-tabs-wrapper {
    width: 91vw;
    border: solid 1px ${gray5};
    display: none;
    position: absolute;
    left: calc(5vw - 35px);
    bottom: 5px;
    transform: translateY(100%);
    background-color: ${white};
    z-index: 1000;

    &.show {
      display: initial;
    }

    .tabs {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tab {
        width: 100%;
        height: 50px !important;
        height: inherit;
        font-family: ${primaryFont};
        font-weight: bold;
        font-size: 16px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &.hide {
          display: none;
        }

        button {
          font-weight: bold;
          margin: 0;
        }

        .active-tab {
          color: ${black};
        }

        a {
          width: 100%;
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${gray5};
        }

        &:hover {
          a {
            color: 'red';
          }
        }

        &.tab-icon {
          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }

  @media screen and (min-width: 721px) {
    display: none;
  }
`;

export default MobileHeader;
