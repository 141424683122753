import React from 'react';
import BrowserCompatibilityChecker from './styles';

import links from '../../utils/links';

import {
  Link,
  Paragraph,
} from '../common/Text';

const MESSAGE = 'Internet Explorer is not capable of rendering the CCCA site member section at this time. To access this section of the site, please use the latest version of one of the following compatible browsers: Chrome, Edge, Firefox, or Safari.';

const BrowserCompatibilityCheckerComponent = ({
  children,
}) => {
  const { userAgent } = window.navigator;

  if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return (
      <BrowserCompatibilityChecker>
        <div className="container">
          <Paragraph className="message">
            {MESSAGE}
          </Paragraph>
          <Link
            className="link"
            externalLink
            href={links.mainDomain}
            target=""
          >
            Return to Home Page
          </Link>
        </div>
      </BrowserCompatibilityChecker>
    );
  }

  return children;
};

export default BrowserCompatibilityCheckerComponent;
