import styled from 'styled-components';
import {
  gray5,
} from '../../../assets/theme/themeVariables';

const UploadImage = styled.div`
  .upload-area {
    margin-top: 35px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .description {
    margin-bottom: 30px;
    font-size: 12px;
    color: ${gray5};
  }

  .preview-image {
    min-width: 90px;
    min-height: 90px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 90px;
      max-height: 90px;
    }
  }

  label {
    min-height: 45px;
    padding: 12px 30px;
    color: ${gray5};
    text-align: center;
    border: 2px solid ${gray5};
    box-sizing: border-box;
    cursor: pointer;
  }

  input {
    display: none;
  }
`;

export default UploadImage;
