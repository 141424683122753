import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DesktopHeader from './styles';

import { logo, simpleLogo } from '../../../../assets/images';
import links from '../../../../utils/links';

import SubMenu from './SubMenu';

import {
  authSelectors,
} from '../../../../store/ducks/auth';

const DesktopHeaderComponent = ({ token }) => (
  <DesktopHeader>
    <ul className="tabs">
      <li className="tab-icon left-icon">
        <a href={links.mainDomain}>
          <img alt="Classic Car Club of America" src={simpleLogo} />
        </a>
      </li>
      <SubMenu
        className="left-side first-tab"
        href={links.vehicles}
        title="CLASSIFIEDS"
        items={[
          {
            href: links.vehicles,
            title: 'VEHICLES',
          }, {
            href: links.classifiedAdSubmission,
            title: 'CLASSIFIED AD SUBMISSION',
          }, {
            href: links.classifiedsFAQ,
            title: 'CLASSIFIEDS FAQ',
          },
        ]}
      />
      <SubMenu
        className="left-side"
        href={links.ourClub}
        title="ABOUT"
        items={[
          {
            href: links.ourClub,
            title: 'OUR CLUB',
          }, {
            href: links.approvedClassics,
            title: 'APPROVED CLASSICS',
          }, {
            href: links.biographies,
            title: 'BIOGRAPHIES',
          }, {
            href: links.museum,
            title: 'MUSEUM',
          }, {
            href: links.educationalFoundation,
            title: 'EDUCATIONAL FOUNDATION',
          }, {
            href: links.news,
            title: 'NEWS',
          },
        ]}
      />
      <SubMenu
        className="left-side"
        href={links.events}
        title="EVENTS"
        items={[
          {
            href: links.events,
            title: 'CCCA EVENTS',
          }, {
            href: links.concoursEvents,
            title: 'COLLECTOR & CONCOURS EVENTS',
          }
        ]}
      />
      <SubMenu
        className="left-side"
        href={links.regionalClubs}
        title='REGIONAL CLUBS'
      />
      <li className="tab-icon central-icon">
        <a href={links.mainDomain}>
          <img alt="Classic Car Club of America" src={logo} />
        </a>
      </li>
      <SubMenu
        href={links.articles}
        title="RESOURCES"
        items={[
          {
            href: links.membershiphandbook,
            title: 'MEMBERSHIP HANDBOOK',
          }, {
            href: links.caravan,
            title: 'CARAVAN HANDBOOK',
          }, {
            href: links.joyOfTouring,
            title: 'JOY OF TOURING'
          }, {
            href: links.judging,
            title: 'JUDGING',
          }, {
            href: links.articles,
            title: 'ARTICLES',
          }, {
            href: links.relatedLinks,
            title: 'RELATED LINKS',
          },
        ]}
      />
      <SubMenu
        className="right-side"
        href={links.contactUs}
        title="CONTACT"
        items={[
          {
            href: links.board,
            title: 'LEADERSHIP BOARD',
          }, {
            href: links.contactUs,
            title: 'CONTACT US',
          },
        ]}
      />
      <SubMenu
        className="right-side"
        href={links.store}
        title="STORE"
      />
      <li className="tab right-side">
        {
          token
            ? <NavLink to={links.myProfile} activeClassName="active-tab">MY PROFILE</NavLink>
            : <NavLink to={links.login} exact activeClassName="active-tab">SIGN IN</NavLink>
        }
      </li>
      <li className="tab primary right-side">
        <NavLink to={links.join} activeClassName="active-tab">JOIN</NavLink>
      </li>
    </ul>
  </DesktopHeader>
);

DesktopHeaderComponent.propTypes = {
  token: PropTypes.string,
};

DesktopHeaderComponent.defaultProps = {
  token: '',
};

const mapStateToProps = state => ({
  token: authSelectors.selectToken(state),
});

export default connect(
  mapStateToProps,
  null,
)(DesktopHeaderComponent);
