import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import range from 'lodash.range';
import { imageFileExtensions } from '../../../utils/constants';
import { formErrorParser } from '../../../utils/errorHandle';
import { checkIfOnlyDigits } from '../../../utils/regExp';
import { transformMomentToRequestFormat } from '../../../utils/serializer';
import VehicleForm from './style';

import Button from '../../common/Button';
import CheckboxField from '../../common/CheckboxField';
import DatePicker from '../../common/DatePicker';
import InputField from '../../common/InputField';
import SelectField from '../../common/Select';
import UploadFile from '../../common/UploadFile';
import {
  HighlightedText,
  Paragraph,
} from '../../common/Text';

import {
  memberSelectors,
} from '../../../store/ducks/member';
import {
  vehiclesActions,
  vehiclesSelectors,
} from '../../../store/ducks/vehicles';

const SENIOR_CATEGORY_DESCRIPTION = 'Premier, Senior and Primary First Place Winners at CCCA Grand Classics or the Annual Meeting list Senior Number. If number is unknown, furnish owner’s name when the car became Premier, Senior, or Primary Winner and/or former owner We will confirm the vehicle’s senior status and apply it to the vehicle record.';

const cylinderOptions = [2,4,6,8,12,16].map(e => ({ label: e, value: e }));

const seniorCategoryOptions = [
  { label: 'None', value: 'None' },
  { label: 'Senior', value: 'Senior' },
  { label: 'Senior Premier', value: 'Senior Premier' },
];

const yearOptions = range(1914, 1949).map(e => ({ label: e, value: e }));

const VehicleFormComponent = ({
  associateName,
  className,
  createVehicle,
  createVehicleErrors,
  data,
  description,
  loadingCreateVehicles,
  loadingMakes,
  loadingUpdateVehicles,
  makes,
  memberName,
  onCloseCallback,
  onModifyVehicle,
  resetErrors,
  title,
  updateVehicle,
  updateVehicleErrors,
}) => {
  const {
    id,
    images,
    ownedByAssociate,
    year,
    makeId,
    model,
    numberOfCylinders,
    bodyStyle,
    bodyMaker,
    engineNumber,
    bodyNumber,
    serialNumber,
    trackAcquiredFrom,
    trackDateAcquired,
    trackSoldTo,
    trackDateSold,
    trackOtherInfo,
    seniorCategory,
    seniorNumber,
    newCoachWork,
    altered,
  } = data || {};

  const onSubmit = useCallback((
    {
      trackDateAcquired: trackDateAcquiredValue,
      trackDateSold: trackDateSoldValue,
      ...fields
    },
    { resetForm },
  ) => {
    const newFields = {
      ...fields,
      trackDateAcquired: transformMomentToRequestFormat(trackDateAcquiredValue),
      trackDateSold: transformMomentToRequestFormat(trackDateSoldValue),
    };
    if (newFields.seniorCategory === 'None') {
      delete newFields.seniorNumber;
    }
    if ((newFields.images || [])[0] && (typeof newFields.images[0] === 'string')) {
      delete newFields.images;
    }
    if (data) {
      return updateVehicle(id, newFields).then((status) => {
        if (status === 'success') {
          onModifyVehicle(null);
          onCloseCallback();
        }
      });
    }
    createVehicle(newFields).then((status) => {
      if (status === 'success') {
        onCloseCallback();
        resetForm();
      }
    });
  }, [
    createVehicle,
    data,
    id,
    onCloseCallback,
    onModifyVehicle,
    updateVehicle,
  ]);

  const makesOptions = useMemo(
    () => (makes || []).map(e => ({ label: e.name, value: e.id })),
    [makes],
  );

  const ownedByOptions = [
    { label: memberName || 'Me', value: false },
    ...(associateName ? [{ label: associateName, value: true }] : []),
  ];

  return (
    <VehicleForm className={className}>
      <HighlightedText className="title">
        {title}
      </HighlightedText>
      <Paragraph className="description">
        {description}
      </Paragraph>
      <Formik
        key={id || ''}
        initialValues={{
          images: images || null,
          ownedByAssociate: (ownedByAssociate !== undefined) ? data.ownedByAssociate : null,
          year: year || null,
          makeId: makeId || null,
          model: model || '',
          numberOfCylinders: numberOfCylinders || null,
          bodyStyle: bodyStyle || '',
          bodyMaker: bodyMaker || '',
          engineNumber: engineNumber || '',
          bodyNumber: bodyNumber || '',
          serialNumber: serialNumber || '',
          trackAcquiredFrom: trackAcquiredFrom || '',
          trackDateAcquired: trackDateAcquired || null,
          trackSoldTo: trackSoldTo || '',
          trackDateSold: trackDateSold || null,
          trackOtherInfo: trackOtherInfo || '',
          seniorCategory: seniorCategory || 'None',
          seniorNumber: seniorNumber || '',
          newCoachWork: newCoachWork || false,
          altered: altered || false,
          authentic: Boolean(data) || false,
        }}
        validationSchema={
          Yup.object()
            .shape({
              images: Yup.object()
                .nullable(),
              ownedByAssociate: Yup.boolean()
                .nullable()
                .required('Owned By is required'),
              year: Yup.number()
                .nullable()
                .required('Year is required'),
              makeId: Yup.number()
                .nullable()
                .required('Make is required'),
              model: Yup.string()
                .required('Model is required'),
              numberOfCylinders: Yup.number()
                .nullable()
                .required('Cylinders is required'),
              bodyStyle: Yup.string(),
              bodyMaker: Yup.string(),
              engineNumber: Yup.string(),
              bodyNumber: Yup.string(),
              serialNumber: Yup.string(),
              trackAcquiredFrom: Yup.string(),
              trackDateAcquired: Yup.string()
                .nullable(),
              trackSoldTo: Yup.string(),
              trackDateSold: Yup.string()
                .nullable(),
              trackOtherInfo: Yup.string(),
              seniorCategory: Yup.string(),
              seniorNumber: Yup.string()
                .max(5, 'Senior Number can include not more than 5 digit')
                .matches(checkIfOnlyDigits, 'Senior Number must be a number')
                .when('seniorCategory', {
                  is: seniorCategoryValue => Boolean(seniorCategoryValue !== 'None'),
                  then: Yup.string().required('Senior Number field is required'),
                }),
              newCoachWork: Yup.boolean(),
              altered: Yup.boolean(),
              authentic: Yup.boolean()
                .oneOf([true], 'Authentic must be checked'),
            })
        }
        onSubmit={onSubmit}
      >
        {({
          errors,
          resetForm,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <UploadFile
              acceptTypes={imageFileExtensions}
              description="Max 5mb image size in .png, .jpeg, or .jpg format"
              error={Boolean(errors.images)}
              editTitle="REPLACE IMAGE"
              name="images"
              onChange={setFieldValue}
              value={values.images}
              title="UPLOAD IMAGE"
              touched={Boolean(touched.images)}
            />
            <div className="row">
              <SelectField
                className="select-input first-child"
                errors={errors}
                label="OWNED BY"
                name="ownedByAssociate"
                options={ownedByOptions}
                onChange={setFieldValue}
                required
                touched={touched}
                values={values}
              />
              <SelectField
                className="select-input second-child"
                errors={errors}
                label="YEAR"
                name="year"
                options={yearOptions}
                onChange={setFieldValue}
                required
                touched={touched}
                values={values}
              />
            </div>
            <div className="row">
              <SelectField
                className="select-input first-child"
                errors={errors}
                label="MAKE"
                loading={loadingMakes}
                name="makeId"
                options={makesOptions}
                onChange={setFieldValue}
                required
                touched={touched}
                values={values}
              />
              <InputField
                className="text-input second-child"
                errors={errors}
                label="MODEL"
                name="model"
                required
                touched={touched}
              />
            </div>
            <div className="row">
              <SelectField
                className="select-input first-child"
                errors={errors}
                label="CYLINDERS"
                name="numberOfCylinders"
                options={cylinderOptions}
                onChange={setFieldValue}
                required
                touched={touched}
                values={values}
              />
              <InputField
                className="text-input second-child"
                errors={errors}
                label="BODY STYLE"
                name="bodyStyle"
                touched={touched}
              />
            </div>
            <div className="row">
              <InputField
                className="text-input first-child"
                errors={errors}
                label="Coachbuilder"
                name="bodyMaker"
                touched={touched}
              />
              <InputField
                className="text-input second-child"
                errors={errors}
                label="ENGINE NUMBER"
                name="engineNumber"
                touched={touched}
              />
            </div>
            <div className="row">
              <InputField
                className="text-input first-child"
                errors={errors}
                label="BODY NUMBER"
                name="bodyNumber"
                touched={touched}
              />
              <InputField
                className="text-input second-child"
                errors={errors}
                label="SERIAL NUMBER"
                name="serialNumber"
                touched={touched}
              />
            </div>
            <div className="row">
              <InputField
                className="text-input first-child"
                errors={errors}
                label="ACQUIRED FROM"
                name="trackAcquiredFrom"
                touched={touched}
              />
              <DatePicker
                className="text-input second-child"
                errors={errors}
                label="ACQUIRED ON"
                name="trackDateAcquired"
                onChange={setFieldValue}
                value={values.trackDateAcquired}
                touched={touched}
              />
            </div>
            <div className="row">
              <InputField
                className="text-input first-child"
                errors={errors}
                label="SOLD TO"
                name="trackSoldTo"
                touched={touched}
              />
              <DatePicker
                className="text-input second-child"
                errors={errors}
                label="SOLD ON"
                name="trackDateSold"
                onChange={setFieldValue}
                value={values.trackDateSold}
                touched={touched}
              />
            </div>
            <div className="row">
              <InputField
                className="text-input"
                errors={errors}
                label="OTHER INFO"
                name="trackOtherInfo"
                touched={touched}
              />
            </div>
            <div className="row">
              <SelectField
                className="select-input first-child"
                disabled={Boolean(data)}
                errors={errors}
                label="CATEGORY"
                name="seniorCategory"
                options={seniorCategoryOptions}
                onChange={setFieldValue}
                touched={touched}
                values={values}
              />
              <InputField
                className="text-input second-child"
                disabled={Boolean(values.seniorCategory === 'None') || Boolean(data)}
                errors={errors}
                label="SENIOR NUMBER"
                name="seniorNumber"
                touched={touched}
              />
            </div>
            <Paragraph className="field-description">
              {SENIOR_CATEGORY_DESCRIPTION}
            </Paragraph>
            <div className="row checkboxes-row">
              <CheckboxField
                className="input checkbox-input"
                label="New Coach Work"
                name="newCoachWork"
                styleType="white"
              />
              <CheckboxField
                className="checkbox-input"
                label="Altered"
                name="altered"
                styleType="white"
              />
            </div>
            <div className="row checkboxes-row">
              <CheckboxField
                className="checkbox-input"
                label="Authentic"
                name="authentic"
                styleType="white"
              />
            </div>
            <div className="errors">
              {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
              {formErrorParser(createVehicleErrors).map(e => <p key={e} className="error">{e}</p>)}
              {formErrorParser(updateVehicleErrors).map(e => <p key={e} className="error">{e}</p>)}
            </div>
            <Button
              className="btn"
              disabled={loadingCreateVehicles || loadingUpdateVehicles}
              title={data ? 'Edit Vehicle' : 'Add Vehicle'}
              type="submit"
            />
            <Button
              className="btn"
              onClick={() => {
                if (data) {
                  onModifyVehicle(null);
                  onCloseCallback();
                  resetErrors();
                } else {
                  resetForm();
                  resetErrors();
                }
              }}
              styleType="border-less"
              title={data ? 'Cancel' : 'Reset'}
            />
          </Form>
        )}
      </Formik>
    </VehicleForm>
  );
};

VehicleFormComponent.propTypes = {
  associateName: PropTypes.string,
  className: PropTypes.string,
  createVehicle: PropTypes.func.isRequired,
  createVehicleErrors: PropTypes.object,
  data: PropTypes.object,
  description: PropTypes.string.isRequired,
  loadingCreateVehicles: PropTypes.bool,
  loadingMakes: PropTypes.bool,
  loadingUpdateVehicles: PropTypes.bool,
  makes: PropTypes.array,
  memberName: PropTypes.string,
  onCloseCallback: PropTypes.func,
  onModifyVehicle: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateVehicle: PropTypes.func.isRequired,
  updateVehicleErrors: PropTypes.object,
};

VehicleFormComponent.defaultProps = {
  associateName: '',
  className: '',
  createVehicleErrors: {},
  data: null,
  loadingCreateVehicles: false,
  loadingMakes: false,
  loadingUpdateVehicles: false,
  makes: null,
  memberName: '',
  onCloseCallback: () => {},
  updateVehicleErrors: {},
};

const mapStateToProps = state => ({
  associateName: memberSelectors.selectAssociateName(state),
  createVehicleErrors: vehiclesSelectors.selectCreateErrors(state),
  loadingCreateVehicles: vehiclesSelectors.selectLoadingCreateVehicles(state),
  loadingMakes: vehiclesSelectors.selectLoadingMakes(state),
  loadingUpdateVehicles: vehiclesSelectors.selectLoadingUpdateVehicles(state),
  makes: vehiclesSelectors.selectMakes(state),
  memberName: memberSelectors.selectMemberName(state),
  updateVehicleErrors: vehiclesSelectors.selectUpdateErrors(state),
});

const mapDispatchToProps = {
  createVehicle: vehiclesActions.createVehicle,
  resetErrors: vehiclesActions.resetErrors,
  updateVehicle: vehiclesActions.updateVehicle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleFormComponent);
