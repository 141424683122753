import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './styles';

import { Paragraph } from '../Text';
import Button from '../Button';
import Modal from '../Modal';

const defaultCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    width: '90%',
    maxWidth: '650px',
    height: 'max-content',
    minHeight: '175px',
    padding: '32px',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
};

const ConfirmationModalComponent = ({
  cancelButton,
  confirmButton,
  customStyles,
  isOpen,
  message,
  onSubmit,
  onRequestClose,
}) => {
  const onContinue = useCallback(() => {
    onSubmit();
    onRequestClose();
  }, [
    onRequestClose,
    onSubmit,
  ]);

  return (
    <Modal
      customStyles={{ ...defaultCustomStyles, ...customStyles }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ConfirmationModal>
        <Paragraph>{message}</Paragraph>
        <div className="buttons-wrapper">
          <Button
            title={confirmButton}
            onClick={onContinue}
          />
          <Button
            styleType="border-less"
            title={cancelButton}
            onClick={onRequestClose}
          />
        </div>
      </ConfirmationModal>
    </Modal>
  );
};

ConfirmationModalComponent.propTypes = {
  cancelButton: PropTypes.string,
  customStyles: PropTypes.object,
  confirmButton: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

ConfirmationModalComponent.defaultProps = {
  cancelButton: 'Cancel',
  customStyles: {},
  confirmButton: 'Confirm',
  isOpen: false,
};

export default ConfirmationModalComponent;
