import React from 'react';
import PropTypes from 'prop-types';

import RadioCarts from './styles';
import CartComponent from './Cart';
import { H4 } from '../../common/Text';

const RadioCartsComponent = ({
  data,
  loading,
  onChange,
  value,
}) => (
  <RadioCarts>
    {loading
      ? (
        <H4>Loading...</H4>
      ) : (
        data.map(item => (
          <CartComponent
            item={item}
            key={item.id}
            onChange={onChange}
            value={value}
          />
        ))
      )}
  </RadioCarts>
);

RadioCartsComponent.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

RadioCartsComponent.defaultProps = {
  data: [],
  value: null,
};

export default RadioCartsComponent;
