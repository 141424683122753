import React from 'react';
// import PropTypes from 'prop-types';
import Home from './styles';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

const HomeComponent = () => (
  <Home>
    <Header />
    <Footer/>
  </Home>
);

HomeComponent.propTypes = {
};

HomeComponent.defaultProps = {
};

export default HomeComponent;
