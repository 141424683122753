import styled from 'styled-components';

const BrowserCompatibilityChecker = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;

  .container {
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-bottom: 20px;
    }
  }
`;

export default BrowserCompatibilityChecker;
