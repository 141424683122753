import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import { formErrorParser } from '../../../utils/errorHandle';
import { emailRegExp } from '../../../utils/regExp';
import MembersInfoForm from './style';

import Button from '../../common/Button';
import CheckboxField from '../../common/CheckboxField';
import InputField from '../../common/InputField';
import SelectField from '../../common/Select';
import {
  Link,
  Paragraph,
} from '../../common/Text';

import {
  locationActions,
  locationSelectors,
} from '../../../store/ducks/location';
import ConfirmationModal from '../../common/ConfirmationModal';

const CONFIRM_MS = 'Are You Sure You Want to Leave the Page? Unsaved Changes will be lost';

const PUBLICATION_PREFERENCES_OPTIONS = [
  { label: 'Receive all publications in print by mail', value: 0 },
  { label: 'Receive "The Classic Car" by Print and the "Bulletin" by Email', value: 1 },
  { label: 'Receive All Publications Digitally by Email', value: 2 },
];

const MembersInfoFormComponent = ({
  children,
  countries,
  data,
  getCountries,
  getRegion,
  history,
  id,
  isCancelButton,
  isJoin,
  isProfile,
  isRenderModal,
  loadingCountries,
  loadingRegions,
  onCancel,
  onSubmit,
  prevStep,
  regions,
  requestErrors,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!Object.keys(countries).length) {
      getCountries();
    }
  }, [countries, getCountries]);

  useEffect(() => {
    if (data.country) {
      getRegion({ countryCode: data.country });
    }
  }, [data.country, getRegion]);

  const countriesForSelect = useMemo(() => (
    Object.keys(countries).map(e => ({ label: e, value: countries[e] }))
  ), [countries]);
  const regionsForSelect = useMemo(() => (
    regions.map(e => ({ label: e, value: e }))
  ), [regions]);

  const handleModalOpen = useCallback((nextLocation) => {
    setModalOpen(true);
    setUrl(nextLocation);
    return isModalOpen;
  }, [isModalOpen]);

  const onCountryChange = useCallback((name, value, callback) => {
    getRegion({ countryCode: value });

    callback('state', '');
    callback(name, value);
  }, [getRegion]);

  const handleFormData = useCallback(({ wasReferred, ...fields }) => onSubmit(fields), [onSubmit]);

  const renderTooltip = useCallback(() => (
    <>
      <Paragraph>
        {'To update your email address, please contact the CCCA office at '}
        <Link externalLink href="mailto:info@classiccarclub.org">info@classiccarclub.org</Link>
      </Paragraph>
    </>
  ), []);

  return (
    <MembersInfoForm>
      <Formik
        key={id}
        initialValues={{
          prefix: data.prefix || '',
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          suffix: data.suffix || '',
          company: data.company || '',
          email: data.email || '',
          addressLine1: data.addressLine1 || '',
          addressLine2: data.addressLine2 || '',
          city: data.city || '',
          state: data.state || '',
          zipCode: data.zipCode || '',
          country: data.country || '',
          residentialPhone: data.residentialPhone || '',
          cellPhone: data.cellPhone || '',
          businessPhone: data.businessPhone || '',
          publicationPreferences: (data.publicationPreferences !== undefined)
            ? data.publicationPreferences : 1,
          referrerName: data.referrerName || '',
          wasReferred: Boolean(data.referrerName) || false,
        }}
        validationSchema={
          Yup.object().shape({
            prefix: Yup.string(),
            firstName: Yup.string()
              .required('First Name is required'),
            lastName: Yup.string()
              .required('Last Name is required'),
            suffix: Yup.string(),
            company: Yup.string(),
            email: Yup.string()
              .required('Email Address is required')
              .matches(emailRegExp, 'Wrong Email Address format'),
            addressLine1: Yup.string()
              .required('Street Address is required'),
            addressLine2: Yup.string(),
            city: Yup.string()
              .required('City is required'),
            state: Yup.string()
              .required('State is required'),
            zipCode: Yup.string()
              .required('Postal Code is required'),
            country: Yup.string()
              .required('Country is required'),
            cellPhone: Yup.string(),
            businessPhone: Yup.string(),
            publicationPreferences: Yup.string(),
            referrerName: Yup.string()
              .when('wasReferred', {
                is: wasReferred => Boolean(wasReferred),
                then: Yup.string().required('Referrer field is required'),
              }),
            wasReferred: Yup.boolean(),
          })
        }
        onSubmit={handleFormData}
      >
        {({
          errors,
          setFieldValue,
          touched,
          values,
          dirty,
        }) => (
          <Form>
            <div className="form">
              <div className="block">
                <div className="half-block">
                  <InputField
                    className="field xs"
                    errors={errors}
                    label="PREFIX"
                    name="prefix"
                    touched={touched}
                  />
                  <InputField
                    className="field s"
                    errors={errors}
                    label="FIRST NAME"
                    name="firstName"
                    required
                    touched={touched}
                  />
                </div>
                <div className="half-block">
                  <InputField
                    className="field s"
                    errors={errors}
                    label="LAST NAME"
                    name="lastName"
                    required
                    touched={touched}
                  />
                  <InputField
                    className="field xs last-child"
                    errors={errors}
                    label="SUFFIX"
                    name="suffix"
                    touched={touched}
                  />
                </div>
              </div>
              <div className="block">
                <InputField
                  className="field m"
                  errors={errors}
                  label="COMPANY NAME"
                  name="company"
                  touched={touched}
                />
                <div
                  className="field m last-child"
                  data-for="email-tooltip"
                  data-tip
                >
                  <InputField
                    disabled={!isJoin}
                    errors={errors}
                    label="EMAIL ADDRESS"
                    name="email"
                    required
                    type="email"
                    touched={touched}
                  />
                </div>
                <ReactTooltip
                  className="tooltip"
                  clickable
                  border
                  delayHide={500}
                  disable={isJoin}
                  effect="solid"
                  getContent={renderTooltip}
                  id="email-tooltip"
                  overridePosition={(position, currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    let { left, top } = position;

                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top + 35);

                    return { top, left };
                  }}
                  type="light"
                />
              </div>
              <div className="block">
                <InputField
                  className="field xl"
                  errors={errors}
                  label="STREET ADDRESS"
                  name="addressLine1"
                  required
                  touched={touched}
                />
                <InputField
                  className="field s last-child"
                  errors={errors}
                  label="APT. OR SUITE #"
                  name="addressLine2"
                  touched={touched}
                />
              </div>
              <div className="block">
                <div className="half-block">
                  <InputField
                    className="field s"
                    errors={errors}
                    label="CITY"
                    name="city"
                    required
                    touched={touched}
                  />
                  <SelectField
                    className="field s"
                    errors={errors}
                    label="COUNTRY"
                    loading={loadingCountries}
                    name="country"
                    onChange={(name, val) => onCountryChange(name, val, setFieldValue)}
                    options={countriesForSelect}
                    required
                    touched={touched}
                    values={values}
                  />
                </div>
                <div className="half-block">
                  <InputField
                    className="field xs"
                    errors={errors}
                    label="POSTAL CODE"
                    name="zipCode"
                    required
                    touched={touched}
                  />
                  <SelectField
                    className="field s last-child"
                    errors={errors}
                    label="STATE"
                    loading={loadingRegions}
                    name="state"
                    onChange={setFieldValue}
                    options={regionsForSelect}
                    required
                    touched={touched}
                    values={values}
                  />
                </div>
              </div>
              <div className="block">
                <InputField
                  className="field s"
                  errors={errors}
                  label="HOME PHONE"
                  name="residentialPhone"
                  type="phone"
                  touched={touched}
                />
                <InputField
                  className="field s"
                  errors={errors}
                  label="CELL PHONE"
                  name="cellPhone"
                  type="phone"
                  touched={touched}
                />
                <InputField
                  className="field s last-child"
                  errors={errors}
                  label="BUSINESS PHONE"
                  name="businessPhone"
                  type="phone"
                  touched={touched}
                />
              </div>
              <div className="block">
                <SelectField
                  className="field l last-child"
                  disabled={isProfile}
                  errors={errors}
                  label="PUBLICATION PREFERENCE"
                  loading={loadingRegions}
                  name="publicationPreferences"
                  onChange={setFieldValue}
                  options={PUBLICATION_PREFERENCES_OPTIONS}
                  touched={touched}
                  values={values}
                />
              </div>
              {
                isJoin ? (
                  <div className="checkbox" >
                    <CheckboxField
                      label="I was referred by a CCCA Member"
                      name="wasReferred"
                      styleType="white"
                    />
                  </div>
                ) : (
                  null
                )
              }
              {values.wasReferred && isJoin && (
              <div className="block">
                <InputField
                  className="field l last-child"
                  errors={errors}
                  label="REFERRER"
                  maxLength="50"
                  name="referrerName"
                  required
                  touched={touched}
                />
              </div>
              )}
              {children}
              <div className="errors">
                {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
                {formErrorParser(requestErrors).map(e => <p key={e} className="error">{e}</p>)}
              </div>
              <div className="buttons">
                {
                  isProfile ? (
                    <Button
                      disabled={!dirty}
                      title="UPDATE MEMBER INFO"
                      type="submit"
                    />
                  ) : (
                    <div className="step-button">
                      <div className="button">
                        <Button
                          name="save & continue"
                          type="submit"
                          title="SAVE & CONTINUE TO STEP 2"
                        />
                      </div>
                      {/* <Button
                        onClick={() => {}}
                        title="SAVE"
                      /> */}
                      {
                        isCancelButton
                          ? (
                            <Button
                              onClick={onCancel || history.goBack}
                              styleType="border-less"
                              title="CANCEL"
                            />
                          ) : (
                            <Button
                              onClick={prevStep}
                              styleType="border-less"
                              title="BACK"
                            />
                          )
                      }
                    </div>
                  )
                }
              </div>
              {isRenderModal && (
                <div>
                  <Prompt
                    when={dirty}
                    message={location => handleModalOpen(location.pathname)}
                  />
                  <ConfirmationModal
                    cancelButton="Cancel"
                    confirmButton="Continue"
                    isOpen={isModalOpen}
                    message={CONFIRM_MS}
                    onSubmit={() => window.location.replace(url)}
                    onRequestClose={() => setModalOpen(false)}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </MembersInfoForm>
  );
};

MembersInfoFormComponent.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  countries: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  getRegion: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isCancelButton: PropTypes.bool,
  isRenderModal: PropTypes.bool,
  isJoin: PropTypes.bool,
  isProfile: PropTypes.bool,
  loadingCountries: PropTypes.bool,
  loadingRegions: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  prevStep: PropTypes.func,
  regions: PropTypes.array.isRequired,
  requestErrors: PropTypes.object,
};

MembersInfoFormComponent.defaultProps = {
  children: null,
  id: '',
  isCancelButton: false,
  isJoin: false,
  isProfile: false,
  isRenderModal: false,
  loadingCountries: false,
  loadingRegions: false,
  onCancel: null,
  prevStep: null,
  requestErrors: {},
};

const mapStateToProps = state => ({
  countries: locationSelectors.selectCountries(state),
  loadingCountries: locationSelectors.selectLoadingCountries(state),
  loadingRegions: locationSelectors.selectLoadingRegions(state),
  regions: locationSelectors.selectRegions(state),
});

const mapDispatchToProps = {
  getCountries: locationActions.getCountries,
  getRegion: locationActions.getRegions,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembersInfoFormComponent));
