import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrowDownRed, arrowUpRed } from '../../../../assets/images';
import MemberSearch from './styles';

import {
  H4,
  Link,
  Paragraph,
} from '../../../../components/common/Text';
import MemberSearchForm from '../../../../components/Forms/MemberSearchForm';
import SearchResults from '../../../../components/SearchResults';
import TextButton from '../../../../components/common/TextButton';

import {
  searchMembersActions,
  searchMembersSelectors,
} from '../../../../store/ducks/searchMembers';

const SEARCH_FORM_DESCRIPTION = 'Use this form to search for members';
const SEARCH_RESULTS_DESCRIPTION = 'Click on a member number in the left-hand column to see full member and car info. If there is more than one page of results, use the numbered links at the bottom of the list to page through results.';

const DEFAULT_SORT_PARAMETER = {
  order: 'asc',
  sorting: 'last_name',
};

const SORT_OPTIONS = [
  { name: 'last_name', title: 'Last Name' },
  { name: 'member_no', title: 'Member Number' },
];

const MemberSearchPage = ({
  clearMembersListResults,
  getMembersList,
  loading,
  membersSearchResults,
  requestErrors,
}) => {
  const {
    order,
    page,
    sorting,
    ...prevSearchParams
  } = JSON.parse(sessionStorage.getItem('memberSearchParams')) || {};
  sessionStorage.removeItem('memberSearchParams');

  const [resultPage, setResultPage] = useState(page || 1);
  const [searchParams, setSearchParams] = useState(
    Object.keys(prevSearchParams || {}).length ? prevSearchParams : {},
  );
  const [sortParameter, setSortParameter] = useState(
    (order && sorting) ? { order, sorting } : DEFAULT_SORT_PARAMETER,
  );

  useEffect(() => {
    if (Object.keys(searchParams).length) {
      getMembersList({
        ...searchParams,
        ...(Object.keys(sortParameter).length ? sortParameter : DEFAULT_SORT_PARAMETER),
        page: resultPage,
      });
    }

    return clearMembersListResults();
  }, [
    clearMembersListResults,
    getMembersList,
    resultPage,
    searchParams,
    sortParameter,
  ]);

  const changeSortParameter = useCallback((propName) => {
    setSortParameter({
      sorting: propName,
      order: (sortParameter.sorting === propName && sortParameter.order === 'desc') ? 'asc' : 'desc',
    });
  }, [sortParameter]);

  const renderSortButtons = useCallback(() => (
    <div className="sort-buttons">
      {SORT_OPTIONS.map(e => (
        <div
          key={e.name}
          className={`sort-btn ${sortParameter.sorting === e.name && 'active-sort'}`}
        >
          <TextButton
            onClick={() => changeSortParameter(e.name)}
            title={`Sort by ${e.title}`}
          />
          <img src={sortParameter.order === 'asc' ? arrowUpRed : arrowDownRed} alt="" />
        </div>
      ))}
    </div>
  ), [changeSortParameter, sortParameter]);

  const renderRow = useCallback((value, className = '') => (
    value && <Paragraph className={className}>{value}</Paragraph>
  ), []);

  const renderItem = useCallback(({
    addressLine1,
    addressLine2,
    associate,
    cellPhone,
    city,
    email,
    nationalExpirationDate,
    firstName,
    lastName,
    memberNo,
    regionalMemberships,
    residentialPhone,
    state,
    vehiclesCount,
    zipCode,
  }) => (
    <li key={memberNo} className="item">
      <div className="column">
        <Link
          href={`/my-profile/search/member/${memberNo}`}
          onClick={() => {
            const memberSearchParams = {
              ...searchParams,
              ...(Object.keys(sortParameter).length ? sortParameter : {}),
              page: resultPage,
            };
            sessionStorage.setItem('memberSearchParams', JSON.stringify(memberSearchParams));
          }}
        >
          {`No. ${memberNo}`}
        </Link>
        {renderRow(`${firstName} ${lastName}`)}
        {renderRow(nationalExpirationDate)}
        {renderRow(email)}
      </div>
      <div className="column">
        {renderRow(addressLine1)}
        {renderRow(addressLine2)}
        {renderRow(zipCode)}
        {renderRow(city)}
        {renderRow(state)}
        {renderRow(`Home: ${residentialPhone}`, 'mt-10')}
        {renderRow(`Cell: ${cellPhone}`)}
      </div>
      <div className="column">
        {renderRow(`Cars: ${vehiclesCount || 0}`)}
        {renderRow(`Assoc: ${associate}`)}
        {renderRow('Regions', 'underlined mt-10')}
        {renderRow(regionalMemberships.reduce((acc, elem) => (!acc ? elem : `${acc}, ${elem}`), '') || '-')}
      </div>
    </li>
  ), [
    renderRow,
    resultPage,
    searchParams,
    sortParameter,
  ]);

  const renderList = useCallback(data => (
    <ul className="list">
      {(data || []).map(renderItem)}
    </ul>
  ), [renderItem]);

  return (
    <MemberSearch>
      <div className="member-search-container">
        <H4 className="title">Member Search</H4>
        <Paragraph className="msg-text">{SEARCH_FORM_DESCRIPTION}</Paragraph>
        <MemberSearchForm
          initData={searchParams}
          onReset={() => {
            clearMembersListResults();
            setSearchParams({});
            setSortParameter({});
            setResultPage(1);
          }}
          onSubmit={(params) => {
            setSearchParams(params);
            setSortParameter(DEFAULT_SORT_PARAMETER);
            setResultPage(1);
          }}
          requestErrors={requestErrors}
        />
        {membersSearchResults.results && (
          <SearchResults
            initPage={resultPage}
            listDescription={SEARCH_RESULTS_DESCRIPTION}
            loading={loading}
            onChangePage={newPage => setResultPage(newPage)}
            totalResults={membersSearchResults.total}
            recordCount={membersSearchResults.totalRecords}
          >
            {(membersSearchResults.total === 0)
              ? (
                <Paragraph className="no-results">No results for your search. Please try again.</Paragraph>
              ) : (
                <>
                  {renderSortButtons()}
                  {renderList(membersSearchResults.results)}

                  {(resultPage === 1)
                    ? (
                      <h3>{membersSearchResults.total} Result(s) Found</h3>
                    ):('')}
                </>
              )}
            </SearchResults>
        )}
      </div>
    </MemberSearch>
  );
};

MemberSearchPage.propTypes = {
  clearMembersListResults: PropTypes.func.isRequired,
  getMembersList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  membersSearchResults: PropTypes.object,
  requestErrors: PropTypes.object,
};

MemberSearchPage.defaultProps = {
  loading: false,
  membersSearchResults: {},
  requestErrors: {},
};

const mapStateToProps = state => ({
  loading: searchMembersSelectors.selectLoadingMembersList(state),
  membersSearchResults: searchMembersSelectors.selectMembersSearchResults(state),
  requestErrors: searchMembersSelectors.selectMembersListErrors(state),
});

const mapDispatchToProps = {
  clearMembersListResults: searchMembersActions.clearMembersListResults,
  getMembersList: searchMembersActions.getMembersList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberSearchPage);
