import styled from 'styled-components';

const RadioCarts = styled.div`
  display: grid;
  margin: 36px 0 40px 0;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 20px;
`;

export default RadioCarts;
