import styled from 'styled-components';
import {
} from '../../../assets/theme/themeVariables';

const RegionalAdministratorMemberSearchForm = styled.div`
.form-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  grid-column-gap: 20px;

  div {
    margin-top: 0;
  }

  label {
    margin-bottom: 0;

    .select-control {
      margin-top: 5px;
    }
  }
}

.btn-wrapper {
  margin-top: 30px;
}

@media screen and (max-width: 1600px) {
  .form-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  }
}

@media screen and (max-width: 1400px) {
  .form-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  }
}

@media screen and (max-width: 991px) {
  .form-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  }
}
`;

export default RegionalAdministratorMemberSearchForm;
