import styled from 'styled-components';
import {
  gray5,
  white,
} from '../../../assets/theme/themeVariables';

const RegisteredEventCard = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  .info-block {
    width: 100%;
    background-color: ${white};
    margin-right: 5px;
    padding: 20px;
    box-sizing: border-box;
  }

  .additional-info {
    color: ${gray5};
    font-weight: bold;
  }

  .title {
    font-weight: normal;
  }

  .btns-block {
    width: 150px;
    background-color: ${white};
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      white-space: nowrap;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;

    .info-block {
      margin-right: 0;
      padding: 15px;
      padding-bottom: 0;
    }

    .btns-block {
      width: 100%;
      padding: 15px;
      padding-top: 0;
      justify-content: flex-start;
    }
  }
`;

export default RegisteredEventCard;
