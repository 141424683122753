const GET_REGIONAL_REPORT_REQUEST = 'GET_REGIONAL_REPORT_REQUEST';
const GET_REGIONAL_REPORT_SUCCESS = 'GET_REGIONAL_REPORT_SUCCESS';
const GET_REGIONAL_REPORT_FAIL = 'GET_REGIONAL_REPORT_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  GET_REGIONAL_REPORT_REQUEST,
  GET_REGIONAL_REPORT_SUCCESS,
  GET_REGIONAL_REPORT_FAIL,

  RESET_STATE,
};
