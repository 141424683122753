import types from './actionTypes';

const initialState = {};

const common = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default common;
