import React from 'react';
import PropTypes from 'prop-types';
import BeatLoader from 'react-spinners/BeatLoader';
import { gray7 } from '../../../assets/theme/themeVariables';

const Loader = props => (
  <BeatLoader
    {...props}
  />
);

Loader.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.number,
};

Loader.defaultProps = {
  color: gray7,
  loading: true,
  size: 15,
};

export default Loader;
