import fileDownload from 'js-file-download';
import types from './actionTypes';

const initialState = {
  loadingRegionalReport: false,
  regionalReportErrors: {},
};

const administration = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_REGIONAL_REPORT_REQUEST: {
      return {
        ...state,
        loadingRegionalReport: true,
        regionalReportErrors: {},
      };
    }
    case types.GET_REGIONAL_REPORT_SUCCESS: {
      const { data, headers } = payload;
      const contentDisposition = headers['content-disposition'];
      const fileName = contentDisposition.slice((contentDisposition.lastIndexOf('=') + 1)) || '';

      fileDownload(data, fileName);

      return {
        ...state,
        loadingRegionalReport: false,
      };
    }
    case types.GET_REGIONAL_REPORT_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        loadingRegionalReport: false,
        regionalReportErrors: data || { error: 'Internet connection error' },
      };
    }

    default:
      return state;
  }
};

export default administration;
