import styled from 'styled-components';

const MyVehicles = styled.div`
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  .title {
    margin-bottom: 20px;
  }

  .add-btn {
    display: none;
    width: 200px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;

    .full-width-form {
      display: none;
    }

    .add-btn {
      display: initial;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 0 30px;

    .add-btn {
      margin-bottom: 30px;
    }
  }
`;

export default MyVehicles;
