const GET_RENEWAL_MEMBER_REQUEST = 'GET_RENEWAL_MEMBER_REQUEST';
const GET_RENEWAL_MEMBER_SUCCESS = 'GET_RENEWAL_MEMBER_SUCCESS';
const GET_RENEWAL_MEMBER_FAIL = 'GET_RENEWAL_MEMBER_FAIL';

const RESET_STATE = 'RESET_STATE';

const SET_RENEWAL_MODAL_VISIBILITY = 'SET_RENEWAL_MODAL_VISIBILITY ';
const SET_RENEWAL_CONFIRMATION_MODAL_VISIBILITY = 'SET_RENEWAL_CONFIRMATION_MODAL_VISIBILITY ';

const UPDATE_RENEWAL_MEMBER_REQUEST = 'UPDATE_RENEWAL_MEMBER_REQUEST';
const UPDATE_RENEWAL_MEMBER_SUCCESS = 'UPDATE_RENEWAL_MEMBER_SUCCESS';
const UPDATE_RENEWAL_MEMBER_FAIL = 'UPDATE_RENEWAL_MEMBER_FAIL';

export default {
  GET_RENEWAL_MEMBER_REQUEST,
  GET_RENEWAL_MEMBER_SUCCESS,
  GET_RENEWAL_MEMBER_FAIL,

  RESET_STATE,

  SET_RENEWAL_MODAL_VISIBILITY,
  SET_RENEWAL_CONFIRMATION_MODAL_VISIBILITY,

  UPDATE_RENEWAL_MEMBER_REQUEST,
  UPDATE_RENEWAL_MEMBER_SUCCESS,
  UPDATE_RENEWAL_MEMBER_FAIL,
};
