import {
  applyMiddleware,
  createStore,
  compose,
  combineReducers,
} from 'redux';
import {
  routerMiddleware,
  routerReducer,
} from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import history from '../history';

import allReducers from './ducks';

const { NODE_ENV } = process.env;
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
  thunk,
  routerMiddleware(history),
];

if (NODE_ENV === 'development') {
  middleware.push(logger);
}

export default createStore(
  combineReducers({
    ...allReducers,
    routing: routerReducer,
  }),
  reduxDevTools(applyMiddleware(...middleware)),
);
