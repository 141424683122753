const CLEAR_MEMBER_LIST_RESULTS = 'CLEAR_MEMBER_LIST_RESULTS';

const GET_MEMBERS_SEARCH_RESULTS_REQUEST = 'GET_MEMBERS_SEARCH_RESULTS_REQUEST';
const GET_MEMBERS_SEARCH_RESULTS_SUCCESS = 'GET_MEMBERS_SEARCH_RESULTS_SUCCESS';
const GET_MEMBERS_SEARCH_RESULTS_FAIL = 'GET_MEMBERS_SEARCH_RESULTS_FAIL';

const GET_MEMBER_SEARCH_PROFILE_REQUEST = 'GET_MEMBER_SEARCH_PROFILE_REQUEST';
const GET_MEMBER_SEARCH_PROFILE_SUCCESS = 'GET_MEMBER_SEARCH_PROFILE_SUCCESS';
const GET_MEMBER_SEARCH_PROFILE_FAIL = 'GET_MEMBER_SEARCH_PROFILE_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  CLEAR_MEMBER_LIST_RESULTS,

  GET_MEMBERS_SEARCH_RESULTS_REQUEST,
  GET_MEMBERS_SEARCH_RESULTS_SUCCESS,
  GET_MEMBERS_SEARCH_RESULTS_FAIL,

  GET_MEMBER_SEARCH_PROFILE_REQUEST,
  GET_MEMBER_SEARCH_PROFILE_SUCCESS,
  GET_MEMBER_SEARCH_PROFILE_FAIL,

  RESET_STATE,
};
