import React from 'react';
import PropTypes from 'prop-types';
import Checkout, { ModalContent } from './styles';

import {
  H3, Paragraph,
} from '../common/Text';
import Modal from '../common/Modal';
import PaypalButton from '../PaypalButton';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    width: '90%',
    height: 'max-content',
    maxWidth: '650px',
    minHeight: '175px',
    maxHeight: '70vw',
    padding: '32px',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};

const CheckoutComponent = ({
  callback,
  description,
  isOpen,
  onRequestClose,
  title,
  ...props
}) => (
  <Checkout>
    <Modal
      customStyles={customStyles}
      isOpen={isOpen}
      onRequestClose={() => {}}
    >
      <ModalContent>
        {title && <H3 className="title">{title}</H3>}
        {description && <Paragraph>{description}</Paragraph>}
        <PaypalButton
          className="payment-btns"
          callback={(params) => {
            callback(params);
            onRequestClose();
          }}
          {...props}
        />
      </ModalContent>
    </Modal>
  </Checkout>
);

CheckoutComponent.propTypes = {
  additionalData: PropTypes.object,
  callback: PropTypes.func,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  paymentData: PropTypes.array.isRequired,
  successMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.string,
  ]),
  title: PropTypes.string,
};

CheckoutComponent.defaultProps = {
  additionalData: {},
  callback: () => {},
  description: '',
  onSuccess: () => {},
  successMessage: 'Your payment was successful!',
  title: '',
};

export default CheckoutComponent;
