import React from 'react';
import PropTypes from 'prop-types';
import Button from './styles';

const ButtonComponent = ({
  className,
  disabled,
  loading,
  styleType,
  hoverTitle,
  title,
  ...props
}) => (
  <Button
    className={`${styleType} ${disabled && 'gray'} ${className}`}
    disabled={disabled}
    loading={loading.toString()}
    title={hoverTitle}
    {...props}
  >
    {title.toUpperCase()}
  </Button>
);

ButtonComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hoverTitle: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  styleType: PropTypes.oneOf(['primary', 'secondary', 'secondary-gray', 'border-less', 'gray']),
  title: PropTypes.string,
  type: PropTypes.string,
};

ButtonComponent.defaultProps = {
  className: '',
  disabled: false,
  hoverTitle: '',
  loading: false,
  onClick: null,
  styleType: 'primary',
  title: '',
  type: 'button',
};

export default ButtonComponent;
