import { action } from '../../../utils/serializer';
import types from './actionTypes';
import { plans } from '../../../services/api';

const getNationalPlans = () => (dispatch) => {
  dispatch(action(types.NATIONAL_PLANS_REQUEST));

  plans.getNationalPlans()
    .then(res => dispatch(action(types.NATIONAL_PLANS_SUCCESS, res)))
    .catch(err => dispatch(action(types.NATIONAL_PLANS_FAIL, err)));
};

const getRegionPlans = () => (dispatch) => {
  dispatch(action(types.REGIONAL_PLANS_REQUEST));

  plans.getRegionalPlans()
    .then(res => dispatch(action(types.REGIONAL_PLANS_SUCCESS, res)))
    .catch(err => dispatch(action(types.REGIONAL_PLANS_FAIL, err)));
};

export default {
  getNationalPlans,
  getRegionPlans,
};
