import styled from 'styled-components';
import {
  gray0,
  gray5,
  white,
} from '../../../assets/theme/themeVariables';

const VehicleCard = styled.div`
  min-height: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  .image {
    min-width: 200px;
    min-height: 200px;
    background-color: ${gray0};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  .vehicle-description {
    width: 100%;
    margin-left: 5px;
    padding: 30px;
    background-color: ${white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .first-row {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .second-row {
    font-weight: normal;
    letter-spacing: -0.72px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: normal;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .details {
    .details-text {
      margin-bottom: 10px;
      line-height: normal;

      &:first-child {
        margin-top: 15px;
      }

      &:last-child {
        margin-bottom: 15px;
      }
    }
  }

  .button-block {
    display: flex;

    .separator {
      width: 1px;
      height: 16px;
      background-color: ${gray5};
      margin: 0 15px;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    .image {
      img {
        max-width: 100%;
      }
    }

    .vehicle-description {
      width: initial;
      min-height: 150px;
      margin-left: 0;
      margin-top: 5px;
    }

    .button-block {
      flex-direction: column;

      .separator {
        width: 100%;
        height: 1px;
        margin: 15px 0;
      }
    }
  }
`;

export default VehicleCard;
