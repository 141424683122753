import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import range from 'lodash.range';
import VehicleSearchForm from './styles';

import InputField from '../../common/InputField';
import Button from '../../common/Button';
import SelectField from '../../common/Select';

import {
  vehiclesActions,
  vehiclesSelectors,
} from '../../../store/ducks/vehicles';

const cylinderOptions = [
  { label: 'Any', value: '' },
  ...[2,4,6,8,12,16].map(e => ({ label: e, value: e })),
];

const yearOptions = [
  { label: 'Any', value: '' },
  ...range(1914, 1949).map(e => ({ label: e, value: e })),
];

const VehicleSearchFormComponent = ({
  getMakes,
  initData,
  loadingMakes,
  makes,
  onReset,
  onSubmit,
}) => {
  useEffect(() => {
    if (!makes) {
      getMakes();
    }
  }, [getMakes, makes]);

  const makesOptions = useMemo(() => (
    [
      { label: 'Any Make', value: '' },
      ...(makes || []).map(e => ({ label: e.name, value: e.id })),
    ]
  ), [makes]);

  const handleSubmit = useCallback((fields) => {
    const filteredValues = Object.keys(fields)
      .filter(e => fields[e])
      .reduce((obj, curr) => ({
        ...obj,
        [curr]: fields[curr],
      }), {});
    onSubmit(filteredValues);
  }, [onSubmit]);

  return (
    <VehicleSearchForm>
      <Formik
        key={JSON.stringify(initData)}
        initialValues={{
          make: initData.make || '',
          model: initData.model || '',
          year: initData.year || '',
          cylinders: initData.cylinders || '',
          bodyStyle: initData.bodyStyle || '',
          bodyMaker: initData.bodyMaker || '',
          bodyNumber: initData.bodyNumber || '',
          engineNumber: initData.engineNumber || '',
          serialNumber: initData.serialNumber || '',
          seniorNumber: initData.seniorNumber || '',
        }}
        validationSchema={
          Yup.object()
            .shape({
              make: Yup.string(),
              model: Yup.string(),
              year: Yup.string(),
              cylinders: Yup.string(),
              bodyStyle: Yup.string(),
              bodyMaker: Yup.string(),
              bodyNumber: Yup.string(),
              engineNumber: Yup.string(),
              serialNumber: Yup.string(),
              seniorNumber: Yup.string(),
            })
        }
        onSubmit={handleSubmit}
      >
        {({
          errors,
          resetForm,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <div className="form-container">
              <SelectField
                errors={errors}
                label="MAKE"
                loading={loadingMakes}
                name="make"
                onChange={setFieldValue}
                options={makesOptions}
                touched={touched}
                values={values}
              />
              <InputField
                errors={errors}
                label="MODEL"
                name="model"
                touched={touched}
              />
              <SelectField
                errors={errors}
                label="YEAR"
                name="year"
                options={yearOptions}
                onChange={setFieldValue}
                touched={touched}
                values={values}
              />
              <SelectField
                errors={errors}
                label="CYLINDERS"
                name="cylinders"
                options={cylinderOptions}
                onChange={setFieldValue}
                touched={touched}
                values={values}
              />
              <InputField
                errors={errors}
                label="BODY STYLE"
                name="bodyStyle"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="Coachbuilder"
                name="bodyMaker"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="BODY NUMBER"
                name="bodyNumber"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="ENGINE NUMBER"
                name="engineNumber"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="SERIAL NUMBER"
                name="serialNumber"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="SENIOR NUMBER"
                name="seniorNumber"
                touched={touched}
              />
            </div>
            <div className="errors">
              {Object.keys(errors)
                .map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
            </div>
            <div className="btn-wrapper">
              <Button
                disabled={!Object.values(values).find(e => Boolean(e))}
                title="SEARCH"
                type="submit"
              />
              <Button
                onClick={() => {
                  resetForm();
                  onReset();
                }}
                styleType="border-less"
                title="CLEAR SEARCH FORM"
              />
            </div>
          </Form>
        )}
      </Formik>
    </VehicleSearchForm>
  );
};

VehicleSearchFormComponent.propTypes = {
  getMakes: PropTypes.func.isRequired,
  initData: PropTypes.object,
  loadingMakes: PropTypes.bool,
  makes: PropTypes.array,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

VehicleSearchFormComponent.defaultProps = {
  initData: {},
  loadingMakes: false,
  makes: null,
};

const mapStateToProps = state => ({
  loadingMakes: vehiclesSelectors.selectLoadingMakes(state),
  makes: vehiclesSelectors.selectMakes(state),
});

const mapDispatchToProps = {
  getMakes: vehiclesActions.getMakes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleSearchFormComponent);
