const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
const COUNTRIES_FAIL = 'COUNTRIES_FAIL';

const REGIONS_REQUEST = 'REGIONS_REQUEST';
const REGIONS_SUCCESS = 'REGIONS_SUCCESS';
const REGIONS_FAIL = 'REGIONS_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,

  REGIONS_REQUEST,
  REGIONS_SUCCESS,
  REGIONS_FAIL,

  RESET_STATE,
};
