import styled from 'styled-components';
import { white } from '../../assets/theme/themeVariables';

const Table = styled.div`
.table {
  .rt-tr {
    display: flex;
  }

  .rt-td {
    background: ${white};
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 20px;
  }

  .rt-th {
    margin-left: 20px;
    text-align: left;
  }
}
`;

export default Table;
