import styled from 'styled-components';
import { backgroundColor } from '../../../assets/theme/themeVariables';

const ChangePassword = styled.div`
  margin-top: 10px;
  background-color: ${backgroundColor};
  display: flex;

  .form-wrapper {
    padding-left: 60px;
    padding-right: 98px;
    margin-bottom: 60px;

    .form {
      max-width: 570px;
      display: flex;
      flex-direction: column;

      .current-password {
        max-width: 570px;
      }

      .new-password-container {
        display: flex;

        >div:last-child {
          margin-left: 15px;
        }

        input {
          min-width: 225px;
        }
      }
    }

    .btn-wrapper {
      margin-top: 20px;

      & > button {
        width: 225px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    form {
      width: inherit;
    }

    .form-wrapper {
      padding-left: 30px;
      padding-right: 30px;

      .form {
        .new-password-container {
          display: flex;
          flex-direction: column;

          div:last-child {
            margin-left: 0;
          }

          input {
            min-width: 225px;
          }
        }
      }
    }
  }
`;

export default ChangePassword;
