import styled from 'styled-components';
import {
  gray3,
  gray5,
  primaryColor,
  white,
} from '../../../../assets/theme/themeVariables';

const Cart = styled.div`
  width: 240px;
  height: 250px;
  padding: 25px 30px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .radio-container {
    position: relative;
    height: 25px;
    width: 25px;
    margin-bottom: 20px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      height: 25px;
      width: 25px;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: ${white};
      border-radius: 50%;
      border: 1px solid ${gray3};
    }

    /* &:hover input ~ .checkmark {
      background-color: ${gray5};
    } */

    /* input:checked ~ .checkmark {
    } */

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 4px;
      left: 4px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: ${primaryColor};
    }
  }

  .cart-title {
    font-weight: normal;
  }

  h5 {
    margin: 16px 0;
    font-weight: normal;
    color: ${gray5};
  }

  @media screen and (max-width: 375px) {
    width: 200px;
  }
`;

export default Cart;
