import styled from 'styled-components';

const RegionalBoxes = styled.div`
  display: grid;
  margin: 36px 0 40px 0;
  grid-template-columns: repeat(auto-fit, minmax(167px, auto));
  grid-gap: 20px;
`;

export default RegionalBoxes;
