import styled from 'styled-components';
import {
  backgroundColor,
  gray3,
  white,
} from '../../assets/theme/themeVariables';

const ResetPassword = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${backgroundColor};
  padding-bottom: 278px;
  box-sizing: border-box;
  position: relative;

  .reset-password-container {
    width: 100%;
    max-width: 800px;
    margin: 120px auto;
    padding: 40px 45px;
    box-sizing: border-box;
    background-color: ${white};
  }

  .title {
    margin-bottom: 20px;
  }

  .hr {
    width: 100%;
    height: 1px;
    margin-bottom: 35px;
    background-color: ${gray3};
  }

  .reset-password-content {
    margin-top: 35px;
  }

  .reset-password-message {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 300px;
  }
`;

export default ResetPassword;
