import { toast } from 'react-toastify';
import { action } from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import { member } from '../../../services/api';
import types from './actionTypes';

const getMemberInfo = () => (dispatch) => {
  dispatch(action(types.GET_MEMBER_REQUEST));

  member.getMemberInfo()
    .then(res => dispatch(action(types.GET_MEMBER_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_MEMBER_FAIL, err));
    });
};

const getMembershipDetails = () => (dispatch) => {
  dispatch(action(types.MEMBERSHIP_DETAILS_REQUEST));

  member.getMembershipDetails()
    .then(res => dispatch(action(types.MEMBERSHIP_DETAILS_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.MEMBERSHIP_DETAILS_FAIL, err));
    });
};

const updateMemberInfo = (id, data) => (dispatch) => {
  dispatch(action(types.UPDATE_MEMBER_REQUEST));

  member.updateMemberInfo(id, data)
    .then(() => {
      dispatch(action(types.UPDATE_MEMBER_SUCCESS, { ...data, id }));
      toast.success('Member Info was successfully updated');
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.UPDATE_MEMBER_FAIL, err));
    });
};

export default {
  getMemberInfo,
  getMembershipDetails,
  updateMemberInfo,
};
