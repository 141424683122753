import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import { formErrorParser } from '../../utils/errorHandle';
import { tick } from '../../assets/images';
import PaypalButton from './styles';

import { H3 } from '../common/Text';
import Button from '../common/Button';
import Loader from '../common/Loader';

import {
  paymentActions,
  paymentSelectors,
} from '../../store/ducks/payment';

const {
  NODE_ENV,
  REACT_APP_PAYPAL_CLIENT_ID_DEVELOPMENT,
  REACT_APP_BASE_CLIENT_ID,
} = process.env;

const CLIENT_ID = NODE_ENV === 'production'
  ? REACT_APP_BASE_CLIENT_ID
  : REACT_APP_PAYPAL_CLIENT_ID_DEVELOPMENT;

let PayPalButton = null;

window.React = React;
window.ReactDOM = ReactDOM;

const PaypalButtonComponent = ({
  additionalData,
  approveErrors,
  approvePayment,
  callback,
  isScriptLoaded,
  isScriptLoadSucceed,
  onSuccess,
  paymentData,
  successMessage,
}) => {
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
      setLoading(false);
      setShowButtons(true);
    }
  });

  useEffect(() => {
    const scriptJustLoaded = !showButtons && !isScriptLoaded && !paid;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
        setLoading(false);
        setShowButtons(true);
      }
    }
  }, [
    isScriptLoadSucceed,
    isScriptLoaded,
    paid,
    showButtons,
  ]);

  const createOrder = useCallback((data, actions) => (
    actions.order.create({ purchase_units: paymentData })
  ), [paymentData]);

  const onApprove = useCallback((data, actions) => (
    actions.order.capture().then((details) => {
      const {
        id: paymentId,
        invoice_id: invoiceId,
      } = details.purchase_units[0].payments.captures[0];
      approvePayment({
        ...additionalData,
        invoiceId,
        paymentId,
      })
        .then(() => {
          onSuccess(details);
          setShowButtons(false);
          setPaid(true);
        });
    })
  ), [
    additionalData,
    approvePayment,
    onSuccess,
  ]);

  return (
    <PaypalButton>
      <div className="spinner">
        <Loader loading={loading} />
      </div>

      {
        showButtons
        && !loading
        && !paid
        && (
          <div className="payment-area">
            <H3>Choose payment method</H3>
            <PayPalButton
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </div>
        )
      }

      {paid && (
        <div className="container">
          {Object.keys(approveErrors).length
            ? (
              <div className="errors">
                {formErrorParser(approveErrors).map(e => <p key={e} className="error">{e}</p>)}
              </div>
            ) : (
              <div className="message">
                <img src={tick} alt="" />
                <div className="success-message">
                  {successMessage}
                </div>
              </div>
            )}
          <Button
            className="btn"
            onClick={callback}
            styleType="secondary-gray"
            title="OK"
          />
        </div>
      )}
    </PaypalButton>
  );
};

PaypalButtonComponent.propTypes = {
  additionalData: PropTypes.object,
  approveErrors: PropTypes.object,
  approvePayment: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  paymentData: PropTypes.array.isRequired,
  successMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.string,
  ]).isRequired,
};

PaypalButtonComponent.defaultProps = {
  additionalData: {},
  approveErrors: {},
  isScriptLoaded: false,
  isScriptLoadSucceed: false,
};

const mapStateToProps = state => ({
  approveErrors: paymentSelectors.selectApproveErrors(state),
  loadingApprovePayment: paymentSelectors.selectLoadingApprovePayment(state),
});

const mapDispatchToProps = {
  approvePayment: paymentActions.approvePayment,
};

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PaypalButtonComponent),
);
