import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Please note!
 * This component is only used once in the root component.
 * If you need to use notifications popup,
 * you have to call the method 'toast' from 'react-toastify'
*/

const NotificationPopup = () => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    pauseOnHover
  />
);

export default NotificationPopup;
