import styled from 'styled-components';

import { gray5 } from '../../assets/theme/themeVariables';

const AvailableEventsList = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-left: 40px;
  border-left: 1px solid ${gray5};
  box-sizing: border-box;

  .no-items,
  .loader {
    min-height: 200px;
    color: ${gray5};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .note {
    font-family: italic;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    grid-gap: 20px;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0;
    padding-top: 40px;
    border-left: none;
    border-top: 1px solid ${gray5};
  }
`;

export default AvailableEventsList;
