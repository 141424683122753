import styled from 'styled-components';
import {
  gray0,
  gray5,
  gray7,
  primaryColor,
  white,
} from '../../../assets/theme/themeVariables';

const InputField = styled.div`
  width: 100%;
  margin: 10px 0;
  display: ${props => props.isTooltip && 'flex'};
  position: relative;

  .tooltip-wrapper {
    position: absolute;
    top: 44px;
    right: 10px;

    .tooltip-icon {
      width: 20px;
    }
  }

  label {
    color: ${gray7};
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }

  input {
    max-width: 570px;
    height: 45px;
    margin-top: 5px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: ${white};
    border: none;
    color: ${gray5};
    font-size: 16px;

    &:disabled {
      background-color: ${gray0};
    }
  }

  .required-mark {
    color: ${primaryColor};
  }

  .field-error {
    border: 1px solid ${primaryColor};
  }
`;

export default InputField;
