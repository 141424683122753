import styled from 'styled-components';
import {
  primaryColor,
  gray5,
  black,
  backgroundColor,
  gray4,
} from '../../../assets/theme/themeVariables';


const DesktopHeader = styled.div`
  background-color: ${backgroundColor};
  display: flex;
  justify-content: space-between;
  padding: 60px;
  padding-bottom: 40px;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${gray4};

    h1 {
      white-space: nowrap;
    }

    .tabs-wrapper {
      width: 928px;
  
      .tabs {
        width: 100%;
        height: 78px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .tab {
          width: 100%;
          height: inherit;
          font-family: 'Komet';
          font-weight: bold;
          font-size: 16px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 0;
          margin-bottom: 0;
          text-align: center;

          &.hide {
            display: none;
          }

          .active-tab {
            color: ${black};
          }

          a {
            width: 100%;
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${gray5};
          }

          &:hover {
            a {
              color: 'red';
            }
          }

          &.tab-icon {
            &:hover {
              background-color: unset;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .wrapper {
      display: block;
      width: 907px;

      .text {
        padding-left: 0;
      }

      .tabs-wrapper {
        width: 100%;

        .tabs {
          border-bottom: none;
        }

        .active-tab {
          border-bottom: 2px solid ${primaryColor};
          color: ${black};
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export default DesktopHeader;
