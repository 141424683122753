import types from './actionTypes';

const initialState = {
  approveErrors: {},
  loadingApprovePayment: false,
};

const payment = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.APPROVE_PAYMENT_REQUEST: {
      return {
        ...state,
        approveErrors: {},
        loadingApprovePayment: true,
      };
    }
    case types.APPROVE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loadingApprovePayment: false,
      };
    }
    case types.APPROVE_PAYMENT_FAIL: {
      const { data } = payload.response;

      return {
        ...state,
        approveErrors: data,
        loadingApprovePayment: false,
      };
    }

    default:
      return state;
  }
};

export default payment;
