import styled from 'styled-components';
import {
  gray4,
  primaryColor,
  primaryFont,
} from '../../../assets/theme/themeVariables';

const TextButton = styled.button`
  color: ${primaryColor};
  font-family: ${primaryFont};
  font-weight: bold;
  font-size: 16px;

  &:disabled {
    color: ${gray4};
    cursor: default;
  }
`;

export default TextButton;
