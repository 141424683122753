import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import PasswordField from './style';

import { passwordVisible, passwordInvisible } from '../../../assets/images';

const PasswordFieldComponent = ({
  autoComplete,
  className,
  disabled,
  errors,
  label,
  maxLength,
  name,
  placeholder,
  touched,
  onChange,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <PasswordField className={className} onChange={onChange}>
      <label htmlFor={name}>
        <span className="label">
          {label.toUpperCase()}
          <span className="required-mark">*</span>
        </span>
        <div className="input-wrapper">
          <Field
            autoComplete={autoComplete}
            className={`${errors[name] && touched[name] && 'field-error'}`}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            placeholder={placeholder}
            type={passwordShown ? 'text' : 'password'}
          />
          <button
            className="password-shown"
            onClick={() => {
              setPasswordShown(!passwordShown);
            }}
            type="button"
          >
            <img src={passwordShown ? passwordVisible : passwordInvisible} alt=""/>
          </button>
        </div>
      </label>
    </PasswordField>
  );
};

PasswordFieldComponent.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  touched: PropTypes.object.isRequired,
};

PasswordFieldComponent.defaultProps = {
  autoComplete: 'off',
  className: '',
  disabled: false,
  label: '',
  maxLength: '',
  onChange: null,
  placeholder: '',
};

export default PasswordFieldComponent;
