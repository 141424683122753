import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RegisteredEventsList from './styles';

import { HighlightedText, Paragraph, Link } from '../common/Text';
import Loader from '../common/Loader';
import RegisteredEventCard from './RegisteredEventCard';

import {
  eventsActions,
  eventsSelectors,
} from '../../store/ducks/events';

const EMPTY_LIST_MSG = 'No registered events found';

const RegisteredEventsListComponent = ({
  getRegisteredEvents,
  loading,
  registeredEvents,
}) => {
  useEffect(() => {
    if (!registeredEvents) {
      getRegisteredEvents();
    }
  }, [
    getRegisteredEvents,
    registeredEvents,
  ]);

  const renderList = useCallback(() => (
    (registeredEvents || []).length ? (
      registeredEvents.map(submission => (
        <RegisteredEventCard
          key={submission.submissionId}
          submissionId={submission.submissionId}
          {...submission.event}
        />
      ))
    ) : (
      <Paragraph className="no-items">{EMPTY_LIST_MSG}</Paragraph>
    )
  ), [
    registeredEvents,
  ]);

  return (
    <RegisteredEventsList>
    <HighlightedText className="title">REGISTERED EVENTS</HighlightedText>
    <Paragraph className="description">
      To request a change to your event registration, please contact the CCCA office at <Link externalLink href="mailto:classiccarclub@aol.com">classiccarclub@aol.com</Link>.
    </Paragraph>
    <Paragraph className="description note">
      If you have registered multiple vehicles for an event, you will see multiple registrations below.
    </Paragraph>

      {
        loading
          ? <div className="loader"><Loader /></div>
          : renderList()
      }
    </RegisteredEventsList>
  );
};

RegisteredEventsListComponent.propTypes = {
  getRegisteredEvents: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  registeredEvents: PropTypes.array,
};

RegisteredEventsListComponent.defaultProps = {
  loading: false,
  registeredEvents: null,
};

const mapStateToProps = state => ({
  loading: eventsSelectors.selectLoadingRegisteredEvents(state),
  registeredEvents: eventsSelectors.selectRegisteredEvents(state),
});

const mapDispatchToProps = {
  getRegisteredEvents: eventsActions.getRegisteredEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisteredEventsListComponent);
