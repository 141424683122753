import styled from 'styled-components';

const MyVehicleList = styled.div`
  width: 100%;
  margin-right: 40px;
  display: flex;
  flex-direction: column;

  .no-items {
    margin: auto;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 0;
  }
`;

export default MyVehicleList;
