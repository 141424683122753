import styled from 'styled-components';

const GeneralFormWrapper = styled.div`
  .block {
    display: flex;
    flex-direction: row;
  }

  .half-block {
    width: 100%;
    display: flex;
  }

  .field {
    width: fit-content;
    margin-right: 10px;

    &.last-child {
      margin-right: 0;
    }

    input {
      max-width: unset;
    }
  }

  .xs {
    flex: 1;
    max-width: 110px;
  }

  .s {
    flex: 2;
    min-width: 200px;
  }

  .m {
    flex: 3;
    min-width: 320px;
  }

  .l {
    flex: 4;
    min-width: 400px;
  }

  .xl {
    flex: 5;
    min-width: 500px;
  }

  .checkbox {
    margin: 20px 0;
  }

  .buttons {
    margin-top: 25px;

    .step-button {
      display: flex;

      .button {
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .form {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 860px) {
    .block {
      flex-direction: column;
    }

    .field {
      width: 100%;
      min-width: unset;
      max-width: unset;

      &:last-child {
        margin-right: 0;
      }
    }

    .xs {
      max-width: 110px;
    }
  }

  @media screen and (max-width: 475px) {
    .buttons {
      .step-button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .xs {
      max-width: unset;
    }

    .half-block {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 325px) {
    .buttons {
      .step-button {
        align-items: center;
      }
    }
  }
`;

export default GeneralFormWrapper;
