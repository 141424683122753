import styled from 'styled-components';
import * as theme from '../../../assets/theme/themeVariables';

export const H1 = styled.h1`
  color: ${theme.black};
  font-size: 68px;
  font-family: ${theme.titleFont};
  font-weight: bold;
  letter-spacing: normal;
  line-height: initial;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 475px) {
    font-size: 36px;
  }
`;

export const H2 = styled.h2`
  color: ${theme.gray7};
  font-size: 48px;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }

  @media screen and (max-width: 475px) {
    font-size: 24px;
  }
`;

export const H3 = styled.h3`
  color: ${theme.gray7};
  font-size: 36px;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 475px) {
    font-size: 20px;
  }
`;

export const H4 = styled.h4`
  color: ${theme.gray7};
  font-size: 24px;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 475px) {
    font-size: 18px;
  }
`;

export const H5 = styled.h5`
  color: ${theme.gray7};
  font-size: 16px;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;
`;

export const HighlightedText = styled.p`
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: bold;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;
`;

export const LinkStyled = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: ${theme.primaryFont};
  letter-spacing: normal;
  line-height: initial;

  a {
    color: ${theme.primaryColor};
  }
`;

export const Paragraph = styled.p`
  position: relative;
  color: ${theme.gray7};
  font-size: 16px;
  font-family: ${theme.primaryFont};
  line-height: 1.5;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
