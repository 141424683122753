import styled from 'styled-components';
import {
  backgroundColor,
  gray4,
} from '../../../assets/theme/themeVariables';

const MemberInfo = styled.div`
  background: ${backgroundColor};
  display: flex;
  padding-left: 60px;

  .form-wrapper {
    border-right: 1px solid ${gray4};
    margin-bottom: 60px;
    padding-right: 40px;
  }

  .renew-my-membership {
    width: 100%;
    padding-left: 40px;
    padding-right: 60px;
    box-sizing: border-box;

    button {
      margin-bottom: 40px;
    }
  }

  .text-wrapper {
    .first-paragraph {
      margin-bottom: 20px;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1200px) {
    display: block;

    .form-wrapper {
      border-right: none;
      border-bottom: 1px solid ${gray4};
      margin-right: 60px;
      margin-bottom: 20px;
      padding-right: 0;
    }

    .renew-my-membership {
      padding-left: 0;
      padding-bottom: 20px;
    }

    .text-wrapper {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 576px) {
    padding-left: 30px;

    .form-wrapper {
      margin-right: 30px;
    }

    .renew-my-membership {
      padding-right: 30px;
    }
  }
`;

export default MemberInfo;
