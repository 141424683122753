import { toast } from 'react-toastify';
import {
  action,
  camelToSnake,
  objectToFormData,
} from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import { vehicle } from '../../../services/api';
import types from './actionTypes';

const createVehicle = ({ images, ...data }) => (dispatch) => {
  dispatch(action(types.CREATE_VEHICLE_REQUEST));
  const newData = images ? { ...camelToSnake(data), images } : camelToSnake(data);

  return vehicle.createVehicle(objectToFormData(newData, 'vehicle'))
    .then((res) => {
      dispatch(action(types.CREATE_VEHICLE_SUCCESS, res));
      dispatch(getVehicles());
      toast.success('Vehicle was successfully created');

      return 'success';
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.CREATE_VEHICLE_FAIL, err));
      return 'fail';
    });
};

const deleteVehicle = id => (dispatch) => {
  dispatch(action(types.DELETE_VEHICLE_REQUEST));

  vehicle.deleteVehicle(id)
    .then(() => {
      dispatch(action(types.DELETE_VEHICLE_SUCCESS, id));
      toast.success('Vehicle was successfully deleted');
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.DELETE_VEHICLE_FAIL, err));
    });
};

const getMakes = () => (dispatch) => {
  dispatch(action(types.GET_MAKES_REQUEST));

  vehicle.getMakes()
    .then(res => dispatch(action(types.GET_MAKES_SUCCESS, res)))
    .catch(err => dispatch(action(types.GET_MAKES_FAIL, err)));
};

const getVehicles = () => (dispatch) => {
  dispatch(action(types.GET_VEHICLES_REQUEST));

  vehicle.getVehicles()
    .then(res => dispatch(action(types.GET_VEHICLES_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_VEHICLES_FAIL, err));
    });
};

const resetErrors = () => (dispatch) => {
  dispatch(action(types.RESET_VEHICLES_FORM_ERRORS));
};

const updateVehicle = (id, { images, ...data }) => (dispatch) => {
  dispatch(action(types.UPDATE_VEHICLE_REQUEST));
  const newData = images ? { ...camelToSnake(data), images } : camelToSnake(data);

  return vehicle.updateVehicle(id, objectToFormData(newData, 'vehicle'))
    .then((res) => {
      dispatch(action(types.UPDATE_VEHICLE_SUCCESS, res));
      dispatch(getVehicles());
      toast.success('Vehicle was successfully updated');

      return 'success';
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.UPDATE_VEHICLE_FAIL, err));
      return 'fail';
    });
};

export default {
  createVehicle,
  deleteVehicle,
  getMakes,
  getVehicles,
  resetErrors,
  updateVehicle,
};
