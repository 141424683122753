import types from './actionTypes';

const initialState = {
  currentMember: null,
  currentMemberErrors: {},
  loadingMembersList: false,
  loadingMember: false,
  membersSearchResults: {},
  membersListErrors: {},
};

const searchMembers = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CLEAR_MEMBER_LIST_RESULTS: {
      return {
        ...state,
        membersSearchResults: {},
      };
    }
    case types.GET_MEMBER_SEARCH_PROFILE_REQUEST: {
      return {
        ...state,
        currentMember: null,
        currentMemberErrors: {},
        loadingMember: true,
      };
    }
    case types.GET_MEMBER_SEARCH_PROFILE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        currentMember: data,
        loadingMember: false,
      };
    }
    case types.GET_MEMBER_SEARCH_PROFILE_FAIL: {
      const { data } = (payload.response || {});
      return {
        ...state,
        currentMemberErrors: data,
        loadingMember: false,
      };
    }
    case types.GET_MEMBERS_SEARCH_RESULTS_REQUEST: {
      return {
        ...state,
        loadingMembersList: true,
        membersListErrors: {},
      };
    }
    case types.GET_MEMBERS_SEARCH_RESULTS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingMembersList: false,
        membersSearchResults: data,
      };
    }
    case types.GET_MEMBERS_SEARCH_RESULTS_FAIL: {
      const { data } = (payload.response || {});
      return {
        ...state,
        loadingMembersList: false,
        membersListErrors: data,
        membersSearchResults: {},
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default searchMembers;
