import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyVehicleList from './styles';

import VehicleCard from './VehicleCard';
import { Paragraph } from '../common/Text';

import {
  vehiclesSelectors,
} from '../../store/ducks/vehicles';

const EMPTY_LIST_MSG = 'No vehicle found';

const MyVehicleListComponent = ({
  onModifyVehicle,
  vehicles,
}) => {
  const renderList = useCallback(() => (
    vehicles.map(vehicle => (
      <VehicleCard
        key={vehicle.id}
        onModifyVehicle={onModifyVehicle}
        vehicle={vehicle}
      />
    ))
  ), [
    onModifyVehicle,
    vehicles,
  ]);

  return (
    <MyVehicleList>
      {
        vehicles
          ? renderList()
          : <Paragraph className="no-items">{EMPTY_LIST_MSG}</Paragraph>
      }
    </MyVehicleList>
  );
};

MyVehicleListComponent.propTypes = {
  onModifyVehicle: PropTypes.func.isRequired,
  vehicles: PropTypes.array,
};

MyVehicleListComponent.defaultProps = {
  vehicles: [],
};

const mapStateToProps = state => ({
  vehicles: vehiclesSelectors.selectVehicles(state),
});

export default connect(
  mapStateToProps,
  null,
)(MyVehicleListComponent);
