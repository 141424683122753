import React from 'react';
import PropTypes from 'prop-types';
import Step5 from './styles';

import {
  HighlightedText,
} from '../../../components/common/Text';
import GivingOpportunities from '../../../components/Forms/GivingOpportunities';

const Step5Component = ({
  data,
  handleNextStep,
  prevStep,
}) => (
  <Step5>
    <HighlightedText className="title">STEP 5: GIVING OPPORTUNITIES</HighlightedText>
    <GivingOpportunities
      data={data}
      handleNextStep={handleNextStep}
      prevStep={prevStep}
      renewalProcess
    />
  </Step5>
);

Step5Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default Step5Component;
