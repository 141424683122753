import styled from 'styled-components';
import {
  black,
  gray5,
  primaryFont,
} from '../../../assets/theme/themeVariables';

const SearchWrapper = styled.div`
  width: 100%;

  .tabs {
    margin: 0 60px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tab {
      height: inherit;
      margin-right: 20px;
      font-family: ${primaryFont};
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;

      .active-tab {
        color: ${black};
      }

      a {
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${gray5};
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .tabs {
      width: 100%;
      margin: 0;
      margin-bottom: 20px;
      padding: 0 30px;
      box-sizing: border-box;
    }
  }
`;

export default SearchWrapper;
