import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { imagePlaceholder } from '../../../assets/images';
import UploadImage from './styles';

import { Paragraph } from '../Text';

const UploadImageComponent = ({
  acceptTypes,
  className,
  description,
  disabled,
  editTitle,
  error,
  loading,
  name,
  onChange,
  title,
  touched,
  value,
  ...props
}) => {
  const onChangeImage = useCallback((e) => {
    const { files } = e.target;

    onChange(name, files);
  }, [name, onChange]);

  const renderImage = useCallback(() => {
    let selectedImage = null;
    if ((value || {}).length) {
      selectedImage = (typeof value[0] === 'string') ? value[0] : window.URL.createObjectURL(value[0]);
    }
    const src = (value || {}).length ? selectedImage : imagePlaceholder;

    return (
      <div className="preview-image">
        <img
          alt=""
          src={src}
        />
      </div>
    );
  }, [value]);

  return (
    <UploadImage className={`${touched && error && 'error'}`}>
      <div className="upload-area">
        {renderImage()}
        <label htmlFor={name}>
          {((value || {}).length && editTitle) ? editTitle : title}
          <input
            accept={acceptTypes}
            id={name}
            onChange={onChangeImage}
            type="file"
            {...props}
          />
        </label>
      </div>
      <Paragraph className="description">
        {description}
      </Paragraph>
    </UploadImage>
  );
};

UploadImageComponent.propTypes = {
  acceptTypes: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  editTitle: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

UploadImageComponent.defaultProps = {
  acceptTypes: null,
  className: '',
  description: '',
  disabled: false,
  editTitle: '',
  error: false,
  loading: false,
  multiple: false,
  title: 'UPLOAD FILE',
  value: null,
};

export default UploadImageComponent;
