import React, { useEffect } from 'react';
import MyEvents from './styles';
import { connect } from 'react-redux';
import {
  vehiclesActions,
} from '../../../store/ducks/vehicles';
import AvailableEventsList from '../../../components/AvailableEventsList';
import RegisteredEventsList from '../../../components/RegisteredEventsList';

const MyEventsComponent = ({ getMakes, getVehicles }) => {

  useEffect(() => {
    getMakes();
    getVehicles();
  }, [])

  return (
    <MyEvents>
      <RegisteredEventsList />
      <AvailableEventsList />
    </MyEvents>
  );
}

const mapDispatchToProps = {
  getMakes: vehiclesActions.getMakes,
  getVehicles: vehiclesActions.getVehicles,
};

export default connect(
  null,
  mapDispatchToProps
)(MyEventsComponent);
