import styled from 'styled-components';
import {
  backgroundColor,
  gray5,
  primaryColor,
  titleFont,
  white,
} from '../../../../assets/theme/themeVariables';

const DesktopHeader = styled.div`
  width: 100%;
  background-color: ${white};

  .tabs {
    width: 100%;
    height: 78px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .left-side {
      width: 18%;
    }

    .right-side {
      width: 14.7%;
    }

    .tab {
      height: inherit;
      font-family: ${titleFont};
      font-size: 24px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &.primary {
        background-color: ${primaryColor};

        a {
          color: ${white};
        }

        .active-tab {
          background-color: ${white};
          color: ${primaryColor};
        }
      }

      .active-tab {
        color: ${primaryColor};
      }

      &:hover {
        background-color: ${backgroundColor};

        a {
          color: ${primaryColor};
        }
      }
    }

    .tab-icon {
      &.left-icon {
        display: none;
      }

      &.central-icon {
        width: 200px;

        img {
          max-height: 57px;
        }
      }

      &:hover {
        background-color: unset;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .tabs {
      border-bottom: 1px solid ${gray5};

      .left-side {
        width: 100%;
      }

      .right-side {
        width: 100%;
      }

      .tab {
        font-size: 18px;
      }

      .tab-icon {
        &.left-icon {
          display: initial;
          margin-left: 24px;
          margin-right: 10px;

          img {
            width: 55px;
          }
        }

        &.central-icon {
          display: none;
        }

        &:hover {
          background-color: unset;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export default DesktopHeader;
