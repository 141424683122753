import { createGlobalStyle } from 'styled-components';
import { fontWeight } from './themeVariables';

import acuminProExtraCondensedBoldEot from '../fonts/acumin-pro-extra-condensed-bold.eot';
import acuminProExtraCondensedBoldTtf from '../fonts/acumin-pro-extra-condensed-bold.ttf';
import acuminProExtraCondensedBoldWoff from '../fonts/acumin-pro-extra-condensed-bold.woff';
import acuminProExtraCondensedBoldWoff2 from '../fonts/acumin-pro-extra-condensed-bold.woff2';

import kometBoldEot from '../fonts/komet-bold.eot';
import kometBoldTtf from '../fonts/komet-bold.ttf';
import kometBoldWoff from '../fonts/komet-bold.woff';
import kometBoldWoff2 from '../fonts/komet-bold.woff2';

import kometRegularEot from '../fonts/komet-regular.eot';
import kometRegularTtf from '../fonts/komet-regular.ttf';
import kometRegularWoff from '../fonts/komet-regular.woff';
import kometRegularWoff2 from '../fonts/komet-regular.woff2';

import kometRegularitalicEot from '../fonts/komet-regularitalic.eot';
import kometRegularitalicTtf from '../fonts/komet-regularitalic.ttf';
import kometRegularitalicWoff from '../fonts/komet-regularitalic.woff';
import kometRegularitalicWoff2 from '../fonts/komet-regularitalic.woff2';

import majestiBannerHeavyEot from '../fonts/majesti-banner-heavy.eot';
import majestiBannerHeavyTtf from '../fonts/majesti-banner-heavy.ttf';
import majestiBannerHeavyWoff from '../fonts/majesti-banner-heavy.woff';
import majestiBannerHeavyWoff2 from '../fonts/majesti-banner-heavy.woff2';

import majestiBannerLightEot from '../fonts/majesti-banner-light.eot';
import majestiBannerLightTtf from '../fonts/majesti-banner-light.ttf';
import majestiBannerLightWoff from '../fonts/majesti-banner-light.woff';
import majestiBannerLightWoff2 from '../fonts/majesti-banner-light.woff2';

const fontFaces = createGlobalStyle`
  @font-face {
    font-family: 'acumin pro extra condensed';
    src:
      url(${acuminProExtraCondensedBoldEot}) format('eot'),
      url(${acuminProExtraCondensedBoldTtf}) format('ttf'),
      url(${acuminProExtraCondensedBoldWoff}) format('woff'),
      url(${acuminProExtraCondensedBoldWoff2}) format('woff2');
    font-weight: ${fontWeight.bold};
    font-style: normal;
  }

  @font-face {
    font-family: 'komet';
    src:
      url(${kometBoldEot}) format('eot'),
      url(${kometBoldTtf}) format('ttf'),
      url(${kometBoldWoff}) format('woff'),
      url(${kometBoldWoff2}) format('woff2');
    font-weight: ${fontWeight.bold};
    font-style: normal;
  }

  @font-face {
    font-family: 'komet';
    src:
      url(${kometRegularEot}) format('eot'),
      url(${kometRegularTtf}) format('ttf'),
      url(${kometRegularWoff}) format('woff'),
      url(${kometRegularWoff2}) format('woff2');
    font-weight: ${fontWeight.normal};
    font-style: normal;
  }

  @font-face {
    font-family: 'komet';
    src:
      url(${kometRegularitalicEot}) format('eot'),
      url(${kometRegularitalicTtf}) format('ttf'),
      url(${kometRegularitalicWoff}) format('woff'),
      url(${kometRegularitalicWoff2}) format('woff2');
    font-weight: ${fontWeight.normal};
    font-style: italic;
  }

  @font-face {
    font-family: 'majesti banner';
    src:
      url(${majestiBannerHeavyEot}) format('eot'),
      url(${majestiBannerHeavyTtf}) format('ttf'),
      url(${majestiBannerHeavyWoff}) format('woff'),
      url(${majestiBannerHeavyWoff2}) format('woff2');
    font-weight: ${fontWeight.heavy};
    font-style: normal;
  }

  @font-face {
    font-family: 'majesti banner';
    src:
      url(${majestiBannerLightEot}) format('eot'),
      url(${majestiBannerLightTtf}) format('ttf'),
      url(${majestiBannerLightWoff}) format('woff'),
      url(${majestiBannerLightWoff2}) format('woff2');
    font-weight: ${fontWeight.light};
    font-style: normal;
  }
`;

export default fontFaces;
