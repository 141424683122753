import { action } from '../../../utils/serializer';
import types from './actionTypes';
import { administration } from '../../../services/api';
import { authError } from '../../../utils/errorHandle';

const getRegionalReport = params => (dispatch) => {
  dispatch(action(types.GET_REGIONAL_REPORT_REQUEST));

  administration.getRegionalReport(params)
    .then(res => dispatch(action(types.GET_REGIONAL_REPORT_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_REGIONAL_REPORT_FAIL, err));
    });
};

export default {
  getRegionalReport,
};
