import styled from 'styled-components';
import {
  gray0,
  gray5,
} from '../../../assets/theme/themeVariables';

const EventCard = styled.div`
  display: flex;
  flex-direction: column;

  .info-block {
    width: 100%;
    height: 100%;
    background-color: ${gray0};
    margin-right: 5px;
    padding: 20px;
    box-sizing: border-box;
  }

  .additional-info {
    color: ${gray5};
  }

  .title {
    font-weight: normal;
  }

  .btns-block {
    background-color: ${gray0};
    margin-top: 5px;
    padding: 10px 20px;
    box-sizing: border-box;

    a {
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 576px) {
  }
`;

export default EventCard;
