import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { formErrorParser } from '../../../utils/errorHandle';
import { generateId } from '../../../utils/serializer';
import RegionalAdministratorForm from './style';

import Button from '../../common/Button';
import SelectField from '../../common/Select';
import {
} from '../../common/Text';

import {
  administrationActions,
  administrationSelectors,
} from '../../../store/ducks/administration';
import {
  memberSelectors,
} from '../../../store/ducks/member';

const exportSectionOptions = [
  { label: 'All Member Data', value: 'all_member_data' },
  { label: 'Postal Mailing & Email Addresses', value: 'mailing_list' },
  { label: 'Cars', value: 'vehicles' },
];

const RegionalAdministratorFormComponent = ({
  availableRegions,
  getRegionalReport,
  loadingExport,
  requestErrors,
  renewalWindow,
}) => {
  const [fromKey, setFormKey] = useState(generateId());

  useEffect(() => {
    setFormKey(generateId());
  }, [availableRegions]);

  const regionalIdOptions = useMemo(() => (
    Object.keys(availableRegions || {})
      .filter(e => availableRegions[e].administrator)
      .map(e => ({ label: e, value: availableRegions[e].regionCode }))
  ), [availableRegions]);

  const afterJune = new Date().getMonth() >= 5;
  const dateAdder = afterJune ? 1 : 0

  const filterOptions = [
    { label: 'All', value: 'all_members'},
    { label: 'Current', value: 'current_members'},
    { label: `Renewed ${new Date(renewalWindow?.end).getFullYear() + dateAdder || ""}`, value: 'renewed_members'},
    { label: 'Not Renewed', value: 'not_renewed_members'}
  ];

  const onSubmit = useCallback(getRegionalReport, []);

  return (
    <RegionalAdministratorForm>
      <Formik
        key={fromKey}
        initialValues={{
          exportSelection: '',
          filterOption: '',
          region: (regionalIdOptions || []).length === 1 ? regionalIdOptions[0].value : '',
        }}
        validationSchema={
          Yup.object()
            .shape({
              exportSelection: Yup.string()
                .required('Export Sections is required'),
              filterOption: Yup.string()
                .required('Filter Options is required'),
              region: Yup.string()
                .required('Regional Select is required'),
            })
        }
        onSubmit={onSubmit}
      >
        {({
          errors,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <div>
              <div className="row">
                <SelectField
                  className="select-input"
                  errors={errors}
                  label="REGION SELECT"
                  name="region"
                  options={regionalIdOptions}
                  onChange={setFieldValue}
                  required
                  touched={touched}
                  values={values}
                />
              </div>
              <div className="row">
                <SelectField
                  className="select-input"
                  errors={errors}
                  label="FILTER OPTIONS"
                  name="filterOption"
                  options={filterOptions}
                  onChange={setFieldValue}
                  required
                  touched={touched}
                  values={values}
                />
              </div>
              <div className="row">
                <SelectField
                  className="select-input"
                  errors={errors}
                  label="EXPORT SECTIONS"
                  name="exportSelection"
                  options={exportSectionOptions}
                  onChange={setFieldValue}
                  required
                  touched={touched}
                  values={values}
                />
              </div>
              <div className="errors">
                {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
                {formErrorParser(requestErrors).map(e => <p key={e} className="error">{e}</p>)}
              </div>
            </div>
            <div className="btn">
              <Button
                disabled={loadingExport}
                title="Export"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </RegionalAdministratorForm>
  );
};

RegionalAdministratorFormComponent.propTypes = {
  availableRegions: PropTypes.object,
  getRegionalReport: PropTypes.func.isRequired,
  loadingExport: PropTypes.bool,
  requestErrors: PropTypes.object,
  renewalWindow: PropTypes.object,
};

RegionalAdministratorFormComponent.defaultProps = {
  availableRegions: null,
  loadingExport: false,
  requestErrors: {},
};

const mapStateToProps = state => ({
  availableRegions: memberSelectors.selectRegionalMemberships(state),
  loadingExport: administrationSelectors.selectLoadingRegionalReport(state),
  requestErrors: administrationSelectors.selectRegionalReportErrors(state),
  renewalWindow: memberSelectors.selectRenewalWindow(state),
});

const mapDispatchToProps = {
  getRegionalReport: administrationActions.getRegionalReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegionalAdministratorFormComponent);
