import { action } from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import { payment } from '../../../services/api';
import types from './actionTypes';

const approvePayment = data => (dispatch) => {
  dispatch(action(types.APPROVE_PAYMENT_REQUEST));

  return payment.approve(data)
    .then((res) => {
      dispatch(action(types.APPROVE_PAYMENT_SUCCESS, res));

      return { status: 'success' };
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.APPROVE_PAYMENT_FAIL, err));

      return { status: 'fail' };
    });
};

export default {
  approvePayment,
};
