import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import SearchResults from './styles';

import {
  H4,
  Paragraph,
} from '../common/Text';
import Loader from '../common/Loader';

const MSG_TEXT = 'If there is more than one page of results, use the numbered links at the bottom of the list to page through results.';

const DEFAULT_ITEMS_PER_PAGE = 500;
const PAGE_RANGE_DISPLAYED = 5;

const SearchResultsComponent = ({
  children,
  initPage,
  listDescription,
  loading,
  onChangePage,
  totalResults,
  recordCount,
}) => {
  const [activePage, setActivePage] = useState(initPage);

  const handlePageChange = useCallback((newPage) => {
    setActivePage(newPage);
    onChangePage(newPage);
  }, [onChangePage]);

  return (
    <SearchResults>
      <H4 className="title">Search Results</H4>

      <Paragraph className="msg-text">{listDescription}</Paragraph>
      {loading
        ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="results-list">
            {children}
            {(totalResults > DEFAULT_ITEMS_PER_PAGE)
              && (
                <Pagination
                  hideNavigation
                  pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                  activePage={activePage}
                  itemsCountPerPage={DEFAULT_ITEMS_PER_PAGE}
                  totalItemsCount={totalResults}
                  onChange={handlePageChange}
                />
              )}
          </div>
        )}
    </SearchResults>
  );
};

SearchResultsComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  initPage: PropTypes.number.isRequired,
  listDescription: PropTypes.string,
  loading: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  totalResults: PropTypes.number,
  recordCount: PropTypes.number,
};

SearchResultsComponent.defaultProps = {
  listDescription: MSG_TEXT,
  loading: false,
  totalResults: 0,
  recordCount: 0,
};

export default SearchResultsComponent;
