import styled from 'styled-components';
import {
  gray5,
  gray7,
  primaryColor,
  white,
} from '../../../assets/theme/themeVariables';

const PasswordField = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    color: ${gray7};
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }

  .input-wrapper {
    position: relative;
  }

  input {
    width: 100%;
    max-width: 570px;
    max-width: 100%;
    height: 45px;
    margin-top: 5px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: ${white};
    border: none;
    color: ${gray5};
    font-size: 16px;
  }

  .required-mark {
    color: ${primaryColor};
  }

  .field-error {
    border: 1px solid ${primaryColor};
  }

  .password-shown {
    width: 25px;
    max-height: 25px;
    margin: 0;
    position: absolute;
    right: 8px;
    bottom: 10px;

    img {
      width: inherit;
    }
  }
`;

export default PasswordField;
