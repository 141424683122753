import styled from 'styled-components';
import {
  backgroundColor,
  primaryColor,
  titleFont,
  white,
} from '../../../../../assets/theme/themeVariables';

const SubMenu = styled.li`
  height: 78px;
  position: relative;

  .tab {
    height: 78px;
    font-family: ${titleFont};
    font-size: 24px;
    line-height: 1;
  }

  .submenu {
    display: none;
    width: max-content;
    padding: 5px;
    background-color: ${white};
    text-align: center;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    border-radius: 4px;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;

    a {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
      font-family: ${titleFont};
      font-size: 20px;
      font-weight: bold;

      &:hover {
        background-color: ${backgroundColor};
        color: ${primaryColor};
      }
    }
  }

  &.first-tab {
    .submenu {
      left: 5px;
      transform: unset;
    }
  }

  &:hover {
    background-color: ${backgroundColor};

    .tab {
      color: ${primaryColor};
    }

    .submenu {
      display: flex;
    }
  }
`;

export default SubMenu;
