import styled from 'styled-components';
import {
  secondaryColor,
  white,
} from '../../assets/theme/themeVariables';

const Header = styled.div`
  .notification-row {
    width: 100%;
    height: 40px;
    background-color: ${secondaryColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 2;

    .notification-text {
      width: 95%;
      color: ${white};
      text-align: center;
      font-size: 14px;
    }

    .close-btn {
      color: ${white};
      font-size: 28px;
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
`;

export default Header;
