export const imageFileExtensions = '.png, .jpeg, .jpg';

export const fieldNamesByKeys = {
  addressLine1: 'Street Address',
  addressLine2: 'Apt. or Suite',
  approvedByCommittee: 'Previously Approved',
  authentic: 'Authentic',
  bodyMaker: 'Body Maker',
  bodyNumber: 'Body Number',
  bodyStyle: 'Body Style',
  businessPhone: 'Business Phone',
  cellPhone: 'Cell Phone',
  city: 'City',
  company: 'Company',
  country: 'Country',
  email: 'Email',
  enginNumber: 'Engin Number',
  firstName: 'First Name',
  images: 'Upload Image',
  lastName: 'Last Name',
  makeId: 'Make',
  model: 'Model',
  newCoachWork: 'New Coach Work',
  numberOfCylinders: 'Cylinders',
  ownedByAssociate: 'Owned By',
  prefix: 'Prefix',
  seniorCategory: 'Category',
  seniorNumber: 'Senior Number',
  serialNumber: 'Serial Number',
  state: 'State',
  suffix: 'Suffix',
  referrerName: 'Referrer',
  residentialPhone: 'Home Phone',
  trackAcquiredFrom: 'Acquired From',
  trackDateAcquired: 'Acquired On',
  trackSoldTo: 'Sold To',
  trackDateSold: 'Sold On',
  trackOtherInfo: 'Other Info',
  year: 'Year',
  zipCode: 'Postal Code',
};
