const selectLoadingNationalPlans = state => state.plans.loadingNationalPlans;
const selectLoadingRegionalPlans = state => state.plans.loadingRegionalPlans;
const selectNationalPlans = state => state.plans.nationalPlans;
const selectRegionalPlans = state => state.plans.regionalPlans;

export default {
  selectLoadingNationalPlans,
  selectLoadingRegionalPlans,
  selectNationalPlans,
  selectRegionalPlans,
};
