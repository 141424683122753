import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ResetPassword from './style';

import EmailVerification from './EmailVerification';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import NewPassword from './NewPassword';
import {
  H1,
} from '../../components/common/Text';

const ResetPasswordComponent = ({
  match: {
    params,
  },
}) => {
  const renderComponent = useCallback(() => {
    const { token } = params;
    if (token) {
      return <NewPassword resetPasswordToken={token} />;
    }
    return <EmailVerification />;
  }, [params]);

  return (
    <ResetPassword>
      <Header />
      <div className="reset-password-container">
        <H1 className="title">Forgot Your Password?</H1>
        <div className="hr" />
        {renderComponent()}
      </div>
      <Footer/>
    </ResetPassword>
  );
};

ResetPasswordComponent.propTypes = {
  match: PropTypes.object.isRequired,
};

ResetPasswordComponent.defaultProps = {
};

export default ResetPasswordComponent;
