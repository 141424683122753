import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { formErrorParser } from '../../../utils/errorHandle';
import { passwordValidation } from '../../../utils/regExp';
import NewPassword from './style';

import Button from '../../../components/common/Button';
import NotificationModal from '../../../components/common/NotificationModal';
import PasswordField from '../../../components/common/PasswordField';
import {
  Link,
  Paragraph,
} from '../../../components/common/Text';

import {
  authActions,
  authSelectors,
} from '../../../store/ducks/auth';

const NOTIFICATION_MESSAGE = (
  <>
    {'Thank you for resetting your password. Please click '}
    <Link href="/login">here</Link>
    {' to login with your new password.'}
  </>
);

const NewPasswordComponent = ({
  loading,
  onSubmit,
  resetPasswordToken,
  requestErrors,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = useCallback((fields, { resetForm }) => {
    onSubmit({ ...fields, resetPasswordToken })
      .then((status) => {
        if (status === 'success') {
          setIsModalOpen(!isModalOpen);
          resetForm();
        }
      });
  }, [
    isModalOpen,
    onSubmit,
    resetPasswordToken,
  ]);

  return (
    <NewPassword>
      <Paragraph className="reset-password-message">
        Enter your new password and submit.
      </Paragraph>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={
        Yup.object()
          .shape({
            password: Yup.string()
              .required('New Password is required')
              .matches(passwordValidation, 'New Password does not meet security requirements. Password must be at least ten characters with at least one number, upper-case letter and lower-case letter.'),
            passwordConfirmation: Yup.string()
              .required('Confirm Password is required')
              .oneOf([Yup.ref('password'), null], 'Input into "password" and "confirm password" fields does not match. Please correct and re-submit.'),
          })
      }
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-wrapper">
              <div className="form">
                <div className="new-password-container">
                  <PasswordField
                    autoComplete="new-password"
                    className="password-input"
                    errors={errors}
                    label="New Password"
                    name="password"
                    touched={touched}
                  />
                  <PasswordField
                    autoComplete="new-password"
                    className="password-input"
                    errors={errors}
                    label="Confirm Password"
                    name="passwordConfirmation"
                    touched={touched}
                  />
                </div>
              </div>
              <Link href="/login">
                &gt; Back To Login
              </Link>
              <div className="errors">
                {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
                {formErrorParser(requestErrors).map(e => <p key={e} className="error">{e}</p>)}
              </div>
              <div className="btn-wrapper">
                <Button
                  loading={loading}
                  title="Submit"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <NotificationModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
      >
        {NOTIFICATION_MESSAGE}
      </NotificationModal>
    </NewPassword>
  );
};

NewPasswordComponent.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  resetPasswordToken: PropTypes.string.isRequired,
  requestErrors: PropTypes.object,
};

NewPasswordComponent.defaultProps = {
  loading: false,
  requestErrors: {},
};

const mapStateToProps = state => ({
  loading: authSelectors.selectLoadingResetPassword(state),
  requestErrors: authSelectors.selectErrorsResetPassword(state),
});

const mapDispatchToProps = {
  onSubmit: authActions.newPasswordOnResetPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPasswordComponent);
