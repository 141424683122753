import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-date-picker';
import DatePicker from './styles';

const DatePickerComponent = ({
  className,
  error,
  format,
  minDate,
  name,
  onChange,
  label,
  touched,
  value,
  ...props
}) => (
  <DatePicker className={className}>
    <label htmlFor={name}>
      {label}
      <ReactDatePicker
        dayPlaceholder="DD"
        format={format}
        id={name}
        monthPlaceholder="MM"
        name={name}
        onChange={date => onChange(name, date)}
        yearPlaceholder="YYYY"
        value={typeof value === 'string' ? new Date(value) : value}
        {...props}
      />
    </label>
  </DatePicker>
);

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DatePickerComponent.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  format: 'MM/dd/yyyy',
  label: '',
  loading: false,
  minDate: null,
  value: null,
};

export default DatePickerComponent;
