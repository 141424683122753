const selectCurrentMember = state => state.searchMembers.currentMember;
const selectCurrentMemberErrors = state => state.searchMembers.currentMemberErrors;
const selectLoadingMembersList = state => state.searchMembers.loadingMembersList;
const selectLoadingMember = state => state.searchMembers.loadingMember;
const selectMembersListErrors = state => state.searchMembers.membersListErrors;
const selectMembersSearchResults = state => state.searchMembers.membersSearchResults;

export default {
  selectCurrentMember,
  selectCurrentMemberErrors,
  selectLoadingMembersList,
  selectLoadingMember,
  selectMembersListErrors,
  selectMembersSearchResults,
};
