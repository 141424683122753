import styled from 'styled-components';
import {
  gray5,
  primaryColor,
  titleFont,
  white,
} from '../../../../assets/theme/themeVariables';

const MobileHeader = styled.div`
  width: 100%;
  height: 78px;
  background-color: ${white};
  display: none;

  .burger-tabs {
    height: 75px;
    background-color: ${white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .burger-tab {
      margin-top: 5px;
      padding: 0 24px;

      img {
        width: 60px;
      }
    }

    .burger-icon {
      width: 34px;
      height: 34px;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    .burger-line {
      width: 22px;
      height: 3px;
      background-color: ${primaryColor};
      border-radius: 2px;
    }
  }

  .full-width-navbar {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - ${({ isNotificationClosed }) => (isNotificationClosed ? '78px' : '118px')});
    position: absolute;
    background-color: ${white};
    border-top: 1px solid ${gray5};
    z-index: 2;
    display: none;

    &.visible {
      display: initial;
    }
  }

  .tabs {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &>li {
      margin-bottom: 20px;
    }

    .tab {
      width: 100%;
      height: 34px;
      font-family: ${titleFont};
      font-size: 24px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .active-tab {
        color: ${primaryColor};
      }

      a {
        width: 100%;
        text-align: center;
        text-decoration: underline;
      }

      &:hover {
        a {
          color: ${primaryColor};
        }
      }
    }

    .unstyled {
      a {
        color: initial;
        text-decoration: none;

        &:hover {
          color: ${primaryColor};
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    display: initial;
  }
`;

export default MobileHeader;
