const selectEvents = state => state.events.events;
const selectLoadingEvents = state => state.events.loadingEvents;
const selectLoadingRegisteredEvents = state => state.events.loadingRegisteredEvents;
const selectRegisteredEvents = state => state.events.registeredEvents;

export default {
  selectEvents,
  selectLoadingEvents,
  selectLoadingRegisteredEvents,
  selectRegisteredEvents,
};
