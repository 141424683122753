import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { closeButton } from '../../../assets/images';

const ModalComponent = ({
  customStyles,
  children,
  isCloseBtn,
  isOpen,
  onCloseCallback,
  onRequestClose,
}) => {
  const onClose = useCallback(() => {
    onCloseCallback();
    onRequestClose();
  }, [
    onCloseCallback,
    onRequestClose,
  ]);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className="content">
        {isCloseBtn && (
          <button
            className="close"
            onClick={onClose}
            type="button"
          >
            <img src={closeButton} alt="" />
          </button>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customStyles: PropTypes.object,
  isCloseBtn: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
};

ModalComponent.defaultProps = {
  customStyles: {},
  isCloseBtn: false,
  isOpen: false,
  onCloseCallback: () => {},
};

export default ModalComponent;
