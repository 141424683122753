import styled from 'styled-components';
import {
  gray1,
  gray3,
  gray7,
  primaryColor,
} from '../../../../assets/theme/themeVariables';

const SearchMemberProfile = styled.div`
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-found-text {
    text-align: center;
    font-size: 24px;
    color: ${gray3};
  }

  .container {
    padding: 0 60px 60px 60px;

    .link {
      width: 100%;
      margin: 20px 0;
      display: flex;
      justify-content: center;
    }
  }

  .member-container {
    margin-bottom: 20px;
    border-bottom: 1px solid ${gray1};
  }

  .title {
    color: ${primaryColor};
  }

  .cars {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    grid-gap: 20px;
  }

  .member-info,
  .car-info {
    .row {
      width: 300px;
      display: flex;

      .title {
        color: ${gray7};
        font-weight: bold;
        white-space: nowrap;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
    }
  }

  .member-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 991px) {
    .member-info {
      grid-template-rows: repeat(5, auto);
    }
  }

  @media screen and (max-width: 768px) {
    .member-info {
      grid-template-rows: repeat(10, auto);
    }
  }

  @media screen and (max-width: 720px) {
    .container {
      padding: 0 30px 30px 30px;
    }
  }

  @media screen and (max-width: 576px) {
    .container {
      padding: 30px;
    }
  }
`;

export default SearchMemberProfile;
