import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './styles';

import DesktopTabs from './Desktop';
import MobileHeaderComponent from './Mobile';

import { authActions } from '../../store/ducks/auth';
import { memberSelectors } from '../../store/ducks/member';

const MyProfileTabsComponent = ({
  handleLogout,
  regionalAdministrator,
}) => (
  <Header>
    <MobileHeaderComponent
      handleLogout={handleLogout}
      regionalAdministrator={regionalAdministrator}
    />
    <DesktopTabs
      handleLogout={handleLogout}
      regionalAdministrator={regionalAdministrator}
    />
  </Header>
);

MyProfileTabsComponent.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  regionalAdministrator: PropTypes.bool,
};

MyProfileTabsComponent.defaultProps = {
  regionalAdministrator: false,
};

const mapStateToProps = state => ({
  regionalAdministrator: memberSelectors.selectRegionalAdministrator(state),
});

const mapDispatchToProps = {
  handleLogout: authActions.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyProfileTabsComponent);
