import styled from 'styled-components';
import {
  gray0,
  gray4,
  gray5,
  white,
} from '../../../assets/theme/themeVariables';

const CheckboxField = styled.div`
  div {
    display: inline-block;
    padding-left: 30px;

    > input {
      width: 30px;
      opacity: 0;
      margin-left: -30px;
    }

    > input + label {
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        left: -28px;
        top: calc(50% - 2px);
        width: 20px;
        height: 20px;
        background: ${({ styleType }) => (styleType === 'white' ? white : gray0)};
        transform: translateY(-50%);
      }

      &:after {
        content: 'Г';
        position: absolute;
        top: calc(50% - 6px);
        left: -29px;
        font-size: 17px;
        font-family: sans-serif;
        font-weight: 500;
        color: ${gray5};
        transform: rotate(225deg) translateY(50%);
      }
    }

    > input:not(:checked) + label {
      &:after {
        opacity: 0;
      }
    }

    > input:disabled:not(:checked) + label {
      &:before {
        box-shadow: none;
        cursor: default;
      }
    }

    > input:checked + label {
      &:after {
        opacity: 1;
      }
    }

    > input:disabled:checked + label {
      &:after {
        color: ${gray4};
        cursor: default;
      }
    }

    > input:disabled + label {
      color: ${gray4};
      cursor: default;
    }
  }
`;

export default CheckboxField;
