import React from 'react';
import PropTypes from 'prop-types';
import Step6 from './styles';

import {
  HighlightedText,
} from '../../../components/common/Text';
import GivingOpportunities from '../../../components/Forms/GivingOpportunities';

const Step6Component = ({
  data,
  handleNextStep,
  prevStep,
}) => (
  <Step6>
    <HighlightedText className="title">STEP 6: GIVING OPPORTUNITIES</HighlightedText>
    <GivingOpportunities
      data={data}
      handleNextStep={handleNextStep}
      prevStep={prevStep}
    />
  </Step6>
);

Step6Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default Step6Component;
