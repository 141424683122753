import styled from 'styled-components';

const Step2 = styled.div`
  button {
    margin-right: 10px;
    margin-top: 5px;
  }

  form {
    margin-top: 30px;
  }

  .form-fields {
    width: 100%;
    max-width: 500px;

    label {
      width: inherit;
    }

    input {
      width: 100%;
    }
  }

  .password-wrapper {
    margin-top: 20px;
  }

  .instructions {
    margin-top: 30px;
    margin-bottom: 15px;

    h5:first-child {
      margin-bottom: 10px;
      text-decoration: underline;
    }
  }

  .button-wrapper {
    margin-top: 20px;
  }

  span {
    margin: 3px;
  }

  @media screen and (max-width: 325px) {
    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    div > button {
      margin-right: 0;
    }
  }
`;

export default Step2;
