import React from 'react';
import PropTypes from 'prop-types';
import Step7 from './styles';

import {
  H5,
  HighlightedText,
} from '../../../components/common/Text';
import ReviewSection from '../../../components/Forms/ReviewSection';

const Step7Component = ({
  data,
  onChange,
  onSubmit,
  prevStep,
  requestErrors,
}) => (
  <Step7>
    <HighlightedText className="title">STEP 7: REVIEW SELECTIONS</HighlightedText>
    <H5 className="text">Please confirm your selections. If everything looks correct, click “Checkout” to proceed to payment.</H5>
    <ReviewSection
      data={data}
      onChange={onChange}
      onSubmit={onSubmit}
      prevStep={prevStep}
      requestErrors={requestErrors}
    />
  </Step7>
);

Step7Component.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  requestErrors: PropTypes.object.isRequired,
};

export default Step7Component;
