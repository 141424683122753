const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
const GET_ALL_EVENTS_FAIL = 'GET_ALL_EVENTS_FAIL';

const GET_REGISTERED_EVENTS_REQUEST = 'GET_REGISTERED_EVENTS_REQUEST';
const GET_REGISTERED_EVENTS_SUCCESS = 'GET_REGISTERED_EVENTS_SUCCESS';
const GET_REGISTERED_EVENTS_FAIL = 'GET_REGISTERED_EVENTS_FAIL';

const RESET_STATE = 'RESET_STATE';

export default {
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_FAIL,

  GET_REGISTERED_EVENTS_REQUEST,
  GET_REGISTERED_EVENTS_SUCCESS,
  GET_REGISTERED_EVENTS_FAIL,

  RESET_STATE,
};
