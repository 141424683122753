import types from './actionTypes';

const initialState = {
  createErrors: {},
  loadingCreateVehicles: false,
  loadingDeleteVehicles: false,
  loadingMakes: false,
  loadingVehicles: false,
  loadingUpdateVehicles: false,
  makes: null,
  updateErrors: {},
  vehicles: null,
};

const vehicles = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CREATE_VEHICLE_REQUEST: {
      return {
        ...state,
        createErrors: {},
        loadingCreateVehicles: true,
      };
    }
    case types.CREATE_VEHICLE_SUCCESS: {
      return {
        ...state,
        loadingCreateVehicles: false,
      };
    }
    case types.CREATE_VEHICLE_FAIL: {
      const { data, statusText } = (payload.response || {});

      return {
        ...state,
        createErrors: data || { statusText },
        loadingCreateVehicles: false,
      };
    }
    case types.DELETE_VEHICLE_REQUEST: {
      return {
        ...state,
        loadingDeleteVehicles: true,
      };
    }
    case types.DELETE_VEHICLE_SUCCESS: {
      const vehiclesList = state.vehicles.filter(e => e.id !== payload);

      return {
        ...state,
        loadingDeleteVehicles: false,
        vehicles: vehiclesList,
      };
    }
    case types.DELETE_VEHICLE_FAIL: {
      return {
        ...state,
        loadingDeleteVehicles: false,
      };
    }
    case types.GET_MAKES_REQUEST: {
      return {
        ...state,
        loadingMakes: true,
      };
    }
    case types.GET_MAKES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingMakes: false,
        makes: data,
      };
    }
    case types.GET_MAKES_FAIL: {
      return {
        ...state,
        loadingMakes: false,
      };
    }
    case types.GET_VEHICLES_REQUEST: {
      return {
        ...state,
        loadingVehicles: true,
      };
    }
    case types.GET_VEHICLES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingVehicles: false,
        vehicles: data,
      };
    }
    case types.GET_VEHICLES_FAIL: {
      return {
        ...state,
        loadingVehicles: false,
      };
    }
    case types.RESET_VEHICLES_FORM_ERRORS: {
      return {
        ...state,
        createErrors: {},
        updateErrors: {},
      };
    }
    case types.UPDATE_VEHICLE_REQUEST: {
      return {
        ...state,
        updateErrors: {},
        loadingUpdateVehicles: true,
      };
    }
    case types.UPDATE_VEHICLE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingUpdateVehicles: false,
        vehicles: data,
      };
    }
    case types.UPDATE_VEHICLE_FAIL: {
      const { data, statusText } = (payload.response || {});

      return {
        ...state,
        updateErrors: data || { statusText },
        loadingUpdateVehicles: false,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default vehicles;
