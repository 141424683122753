import styled from 'styled-components';

const BasicInfoList = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  margin-left: 40px;

  .row {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;

    .image-container {
      width: 100%;
      height: 100%;
      max-width: 65px;
      max-height: 65px;
      margin-right: 15px;

      img {
        margin: auto;
      }
    }
  }
`;

export default BasicInfoList;
