import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  creatDateInterval,
  strippedAfterChar
} from '../../../utils/serializer';
import RegisteredEventCard from './styles';

import {
  H3,
  Link,
  Paragraph,
} from '../../common/Text';

const RegisteredEventCardComponent = ({
  dateEnd,
  dateStart,
  regionName,
  submissionId,
  title,
}) => (
  <RegisteredEventCard>
    <div className="info-block">
      <Paragraph className="additional-info">
        {creatDateInterval(dateStart, dateEnd)}
        {` - ${regionName}`}
      </Paragraph>
      <H3 className="title">{strippedAfterChar(title, '[')}</H3>
    </div>
    <div className="btns-block">
      <Link
        externalLink
        href={`http://www.jotform.com/submission/${submissionId}`}
      >
        &gt; View Details
      </Link>
    </div>
  </RegisteredEventCard>
);

RegisteredEventCardComponent.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  regionName: PropTypes.string,
  submissionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  title: PropTypes.string,
};

RegisteredEventCardComponent.defaultProps = {
  dateEnd: '',
  dateStart: '',
  regionName: '',
  title: '',
};

const mapDispatchToProps = {
};

export default connect(
  null,
  mapDispatchToProps,
)(RegisteredEventCardComponent);
