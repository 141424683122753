import React from 'react';
import MemberResources from './styles';

import {
  H4,
  Link,
} from '../../../components/common/Text';

const RESOURCES_LINKS = [
  {
    externalLink: true,
    href: 'https://reader.mediawiremobile.com/classiccarclubofamerica/issues/208875',
    title: 'View current CCCA Publications',
  }, {
    externalLink: true,
    href: 'https://www.cccamuseum.org/Explore/CCCA-National-Magazine-Database.aspx',
    title: 'Browse Historical CCCA Publications on the CCCA Museum Site',
  },
  {
    externalLink: true,
    href: 'https://reader.mediawiremobile.com/classiccarclubofamerica/issues/208797/viewer',
    title: 'View Winter 2023/2024 Handbook',
  },
  {
    externalLink: true,
    href: 'https://reader.mediawiremobile.com/classiccarclubofamerica/issues/207973/viewer',
    title: 'View the 2022 Membership Handbook',
  },
];

const MemberResourcesComponent = () => {
  // eslint-disable-next-line react/prop-types
  const renderLink = ({ title, ...props }) => (
    <div className="link" key={title}>
      <Link
        {...props}
      >
        {title}
      </Link>
    </div>
  );

  return (
    <MemberResources>
      <div className="member-resources-container">
        <H4 className="title">Member Resources</H4>
        <div className="links">
          {RESOURCES_LINKS.map(renderLink)}
        </div>
      </div>
    </MemberResources>
  );
};

MemberResourcesComponent.propTypes = {
};

MemberResourcesComponent.defaultProps = {
};

export default MemberResourcesComponent;
