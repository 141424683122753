import styled from 'styled-components';
import {
  black,
  primaryColor,
} from '../../../assets/theme/themeVariables';

const Step2 = styled.div`
  .title {
    color: ${primaryColor};
  }

  .msg-text {
    margin-top: 14px;
    color: ${black};
    line-height: unset;
  }

  div > button {
    margin-right: 10px;
    margin-top: 5px;
  }

  @media screen and (max-width: 325px) {
    .btnWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    div > button {
      margin-right: 0;
    }
  }
`;

export default Step2;
