import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  transformToPrice,
} from '../../utils/serializer';
import MembershipWrapper from './style';

// import Button from '../common/Button';
import RenewalButton from '../RenewalButton';
import { H5, Paragraph } from '../common/Text';

const MembershipContainer = ({
  border,
  // buttonAction,
  // buttonTitle,
  fields,
  title,
}) => {
  const returnRow = useCallback((name, value, i) => (
    <div className="field" key={i}>
      <Paragraph>{`${name}:`}</Paragraph>
      <Paragraph className="value">{value}</Paragraph>
    </div>
  ), []);

  const renderButton = useCallback(() => {
    if (!(title === 'NATIONAL MEMBERSHIP')) {
      return null;
      // return (
      //   <Button
      //     onClick={buttonAction}
      //     title={buttonTitle}
      //   />
      // );
    }

    return <RenewalButton />;
  }, [
    // buttonAction,
    // buttonTitle,
    title,
  ]);

  const renderMembershipsList = useCallback(arr => (
    <>
      {
        arr.map((el, i) => (
          returnRow(`Membership ${i + 1}`, el, i)
        ))
      }
    </>
  ), [returnRow]);

  const renderAssociateMembership = useCallback(() => {
    const {
      firstName,
      lastName,
      regionalMemberships,
    } = fields;

    return (
      <div className="fields">
        <Paragraph className="associate-name">{`${firstName} ${lastName}`}</Paragraph>
        <div className="associate-memberships">
          {renderMembershipsList(Object.keys(regionalMemberships))}
        </div>
      </div>
    );
  }, [fields, renderMembershipsList]);

  const renderNationalMembership = useCallback(() => (
    <div className="fields">
      {returnRow('Member Number', fields.id)}
      {returnRow('Member Type', fields.nationalPlanName)}
      {returnRow('Year Joined', fields.yearJoined)}
      {returnRow(
        'Dues Expire',
        fields.duesExpire ? moment(fields.duesExpire).format('MM/DD/YYYY') : 'N/A',
      )}
      {returnRow(
        'Date Paid',
        fields.datePaid ? moment(fields.datePaid).format('MM/DD/YYYY') : 'N/A',
      )}
      {returnRow('Amount Paid', transformToPrice(fields.amountPaid))}
      {returnRow(
        'Museum Membership',
        fields.museumMembershipLevel == '' ? "None" : fields.museumMembershipLevel,
      )}
    </div>
  ), [
    fields,
    returnRow,
  ]);

  const renderRegionalMemberships = useCallback(() => (
    <div className="fields">
      {renderMembershipsList(Object.keys(fields))}
    </div>
  ), [fields, renderMembershipsList]);

  const renderMembership = useCallback(() => {
    if (!(fields && Object.values(fields).length)) {
      return (
        <div className="fields">
          <Paragraph>{`(no ${title.toLowerCase()} registered)`}</Paragraph>
        </div>
      );
    }

    if (title === 'NATIONAL MEMBERSHIP') {
      return renderNationalMembership();
    }
    if (title === 'REGIONAL MEMBERSHIP(S)') {
      return renderRegionalMemberships();
    }
    if (title === 'ASSOCIATE MEMBERSHIPS') {
      return renderAssociateMembership();
    }
  }, [
    fields,
    renderAssociateMembership,
    renderNationalMembership,
    renderRegionalMemberships,
    title,
  ]);

  return (
    <MembershipWrapper border={border}>
      <div>
        <H5>{title.toUpperCase()}</H5>
        {renderMembership()}
      </div>

      <div className="button-wrapper">
        {renderButton()}
      </div>
    </MembershipWrapper>
  );
};

MembershipContainer.propTypes = {
  border: PropTypes.bool,
  // buttonAction: PropTypes.func,
  // buttonTitle: PropTypes.string,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string.isRequired,
};

MembershipContainer.defaultProps = {
  border: false,
  // buttonAction: null,
  // buttonTitle: 'Edit Membership',
  fields: null,
};

export default MembershipContainer;
