import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchVehicleProfile from './styles';

import {
  H4,
  Link,
  Paragraph,
} from '../../../../components/common/Text';
import Loader from '../../../../components/common/Loader';

import {
  searchVehiclesActions,
  searchVehiclesSelectors,
} from '../../../../store/ducks/searchVehicles';
import {
  vehiclesActions,
  vehiclesSelectors,
} from '../../../../store/ducks/vehicles';

const returnBooleanValue = bool => (bool ? 'Yes' : 'No');

const SearchVehicleProfilePage = ({
  currentVehicle,
  getMakes,
  getVehicle,
  loading,
  makes,
  match: { params: { vehicleId } },
  requestErrors,
}) => {
  useEffect(() => {
    getVehicle(vehicleId);
  }, [getVehicle, vehicleId]);

  useEffect(() => {
    if (!makes) {
      getMakes(makes);
    }
  }, [getMakes, makes]);

  const renderRow = useCallback((title, values = [], className) => (
    <div className={`row ${className}`}>
      <Paragraph className="title mr-5">{title}</Paragraph>
      {values.length
        ? (
          <div className="values">
            {values.map((value, i) => (
              <Paragraph key={i} className="value">{value || '-'}</Paragraph>
            ))}
          </div>
        ) : (
          <Paragraph className="value">-</Paragraph>
        )}
    </div>
  ), []);

  const renderComponent = useCallback(() => {
    if (Object.keys(requestErrors).length) {
      return <Paragraph className="not-found-text">Not Found</Paragraph>;
    }

    if (!currentVehicle) {
      return null;
    }

    const {
      altered,
      bodyNumber,
      bodyStyle,
      bodyMaker,
      engineNumber,
      id,
      makeId,
      model,
      member: {
        addressLine1,
        addressLine2,
        cellPhone,
        city,
        duesExpire,
        email,
        firstName,
        lastName,
        memberNo,
        nationalPlanName,
        regionalMemberships,
        residentialPhone,
        state,
        yearJoined,
        zipCode,
      },
      newCoachWork,
      numberOfCylinders,
      ownedByAssociate,
      seniorNumber,
      serialNumber,
      year,
    } = currentVehicle;

    return (
      <div className="container">
        <div className="items">
          <div className="item-container">
            <H4 className="title">Car</H4>
            <div className="item-info">
              {renderRow('Car Number:', [id])}
              {renderRow('Owned By:', [(ownedByAssociate ? 'Associate' : 'Member')])}
              {renderRow('Make:', [(makes ? makes.find(e => e.id === makeId).name : '')])}
              {renderRow('Model:', [model])}
              {renderRow('Year:', [year])}
              {renderRow('Cylinders:', [numberOfCylinders])}
              {renderRow('Body Style:', [bodyStyle])}
              {renderRow('Coachbuilder:', [bodyMaker])}
              {renderRow('Engine Number:', [engineNumber])}
              {renderRow('Body Number:', [bodyNumber])}
              {renderRow('Serial Number:', [serialNumber])}
              {renderRow('Senior Number:', [seniorNumber])}
              {renderRow('New Coach Work:', [returnBooleanValue(newCoachWork)])}
              {renderRow('Altered:', [returnBooleanValue(altered)])}
              <Paragraph>Car is believed to be authentic</Paragraph>
            </div>
          </div>
          <div className="item-container">
            <H4 className="title">Member Info</H4>
            <div className="item-info">
              {renderRow('Member Number:', [memberNo])}

              {renderRow('Member Type:', [nationalPlanName], 'mt-15')}
              {renderRow('Year Joined:', [yearJoined])}
              {renderRow('Paid Through:', [(duesExpire || '').slice(0, 4)])}

              {renderRow('Name:', [`${firstName} ${lastName}`], 'mt-15')}
              {renderRow('Email:', [<Link externalLink href={`mailto:${email}`}>{email}</Link>])}

              {renderRow('Address:', [addressLine1, addressLine2, zipCode, city, state], 'column mt-15')}
              {renderRow('Home:', [residentialPhone], 'mt-15')}
              {renderRow('Cell:', [cellPhone])}
              {renderRow('Region(s):', regionalMemberships, 'column mt-15')}
            </div>
          </div>
        </div>
        <div className="link">
          <Link href="/my-profile/search/vehicle">« Return to Search</Link>
        </div>
      </div>
    );
  }, [currentVehicle, makes, renderRow, requestErrors]);

  return (
    <SearchVehicleProfile>
      {loading
        ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          renderComponent()
        )}
    </SearchVehicleProfile>
  );
};

SearchVehicleProfilePage.propTypes = {
  currentVehicle: PropTypes.object,
  getMakes: PropTypes.func.isRequired,
  getVehicle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  makes: PropTypes.array,
  match: PropTypes.object.isRequired,
  requestErrors: PropTypes.object,
};

SearchVehicleProfilePage.defaultProps = {
  currentVehicle: null,
  loading: false,
  makes: null,
  requestErrors: {},
};

const mapStateToProps = state => ({
  currentVehicle: searchVehiclesSelectors.selectCurrentVehicle(state),
  loading: searchVehiclesSelectors.selectLoadingVehicle(state),
  makes: vehiclesSelectors.selectMakes(state),
  requestErrors: searchVehiclesSelectors.selectCurrentVehicleErrors(state),
});

const mapDispatchToProps = {
  getMakes: vehiclesActions.getMakes,
  getVehicle: searchVehiclesActions.getVehicle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchVehicleProfilePage);
