import React from 'react';
import PropTypes from 'prop-types';
import SubMenu from './styles';

const SubMenuComponent = ({
  className,
  items,
  href,
  title,
}) => (
  <SubMenu className={className}>
    <a
      className="tab"
      href={href}
    >
      {title}
    </a>
    {items.length ? (
      <div className="submenu">
        {
          items.map(el => (
            <a
              className="item"
              key={el.title}
              href={el.href}
            >
              {el.title}
            </a>
          ))
        }
      </div>
    ) : (
      null
    )}
  </SubMenu>
);

SubMenuComponent.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SubMenuComponent.defaultProps = {
  className: '',
  items: [],
};

export default SubMenuComponent;
