import { action } from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import types from './actionTypes';
import { search } from '../../../services/api';

const clearVehicleListResults = () => (dispatch) => {
  dispatch(action(types.CLEAR_VEHICLE_LIST_RESULTS));
};

const getVehicle = id => (dispatch) => {
  dispatch(action(types.GET_VEHICLE_SEARCH_PROFILE_REQUEST));

  search.getVehicle(id)
    .then(res => dispatch(action(types.GET_VEHICLE_SEARCH_PROFILE_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_VEHICLE_SEARCH_PROFILE_FAIL, err));
    });
};

const getVehiclesList = params => (dispatch) => {
  dispatch(action(types.GET_VEHICLES_SEARCH_RESULTS_REQUEST));

  search.getVehiclesList(params)
    .then(res => dispatch(action(types.GET_VEHICLES_SEARCH_RESULTS_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_VEHICLES_SEARCH_RESULTS_FAIL, err));
    });
};

export default {
  clearVehicleListResults,
  getVehicle,
  getVehiclesList,
};
