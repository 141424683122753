const selectCountries = state => state.location.countries;
const selectLoadingCountries = state => state.location.loadingCountries;
const selectLoadingRegions = state => state.location.loadingRegions;
const selectRegions = state => state.location.regions;

export default {
  selectCountries,
  selectLoadingCountries,
  selectLoadingRegions,
  selectRegions,
};
