import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { transformDateToMoment } from '../../utils/serializer';
import RenewalButton, {ButtonContainer} from './styles';

import Button from '../common/Button';
import {
  Link,
  Paragraph,
} from '../common/Text';
import {
    useHistory,
} from 'react-router-dom';

import {
  memberSelectors,
} from '../../store/ducks/member';
import { renewalActions } from '../../store/ducks/renewal';
import {authActions} from "../../store/ducks/auth";

const RenewalButtonComponent = ({
  handleRenewConfirmation,
  handleLogout,
  lifelong,
  nationalMembership,
  renewalDate,
  renewalWindow,
}) => {
  const history = useHistory()
  const isBetween = useCallback((date, startDate, endDate) => (
    moment(date).isBetween(
      startDate && startDate.startOf('day'),
      endDate && endDate.endOf('day'),
      null,
      '[]',
    )
  ), []);

  const returnDate = useCallback(date => moment(date).format('MM/DD/YYYY'), []);

  const currentDate = moment();
  const startDate = renewalWindow && transformDateToMoment(renewalWindow.start);
  const endDate = renewalWindow && transformDateToMoment(renewalWindow.end);
  const renewedDate = renewalDate && transformDateToMoment(renewalDate);
  const expiredDate = nationalMembership && transformDateToMoment(nationalMembership.duesExpire);

  const dateIsExpired = moment(currentDate).isAfter(expiredDate, 'day');

  const isRenewButton = (
    (renewalWindow && isBetween(currentDate, startDate, endDate))
      && (!renewedDate || !isBetween(renewedDate, startDate, endDate))
  ) || dateIsExpired;

  let tooltipMessage = <>{`You can renew your membership from ${returnDate(startDate)} to ${returnDate(endDate)}`}</>;

  if (renewedDate && isBetween(renewedDate, startDate, endDate)) {
    tooltipMessage = (
      <>
        <Paragraph>
          If you need to make a change to your national membership selection,
        </Paragraph>
        <Paragraph>
          {'please contact us at '}
          <Link externalLink href="mailto:classiccarclub@aol.com">classiccarclub@aol.com</Link>
        </Paragraph>
      </>
    );
  }

  if (dateIsExpired) {
    tooltipMessage = <>Restart Your CCCA Membership</>;
  }

  if (lifelong) {
    return null;
  }
  const handleRedirectChangePassword = ()=> {
    return history.push("/my-profile/change-password")
  }
  return (
    <RenewalButton>
      <ButtonContainer>
        <span data-for="tooltip" data-tip>
          <Button
            disabled={!isRenewButton}
            onClick={handleRenewConfirmation}
            title="Renew My Membership"
          />
        </span>
        <Button
          onClick={handleRedirectChangePassword}
          title="Change Password"
        />
        <Button
          onClick={handleLogout}
          title="Log Out"
        />
      </ButtonContainer>
      <ReactTooltip
        className="tooltip"
        clickable
        border
        delayHide={500}
        effect="solid"
        getContent={() => tooltipMessage}
        id="tooltip"
        overridePosition={(position, currentEvent, currentTarget, node) => {
          const d = document.documentElement;
          let { left, top } = position;

          left = Math.min(d.clientWidth - node.clientWidth, left);
          top = Math.min(d.clientHeight - node.clientHeight, top);
          left = Math.max(0, left);
          top = Math.max(0, top - 20);

          return { top, left };
        }}
        type="light"
      />
    </RenewalButton>
  );
};

RenewalButtonComponent.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  handleRenewConfirmation: PropTypes.func.isRequired,
  lifelong: PropTypes.bool,
  nationalMembership: PropTypes.object,
  renewalDate: PropTypes.string,
  renewalWindow: PropTypes.object,
};

RenewalButtonComponent.defaultProps = {
  lifelong: false,
  nationalMembership: null,
  renewalDate: null,
  renewalWindow: null,
};

const mapStateToProps = state => ({
  lifelong: memberSelectors.selectLifelong(state),
  nationalMembership: memberSelectors.selectNationalMembership(state),
  renewalDate: memberSelectors.selectRenewalDate(state),
  renewalWindow: memberSelectors.selectRenewalWindow(state),
});

const mapDispatchToProps = {
  handleRenewConfirmation: renewalActions.handleRenewConfirmation,
  handleLogout: authActions.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenewalButtonComponent);
