import { action } from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import types from './actionTypes';
import { search } from '../../../services/api';

const clearMembersListResults = () => (dispatch) => {
  dispatch(action(types.CLEAR_MEMBER_LIST_RESULTS));
};

const getMember = id => (dispatch) => {
  dispatch(action(types.GET_MEMBER_SEARCH_PROFILE_REQUEST));

  search.getMember(id)
    .then(res => dispatch(action(types.GET_MEMBER_SEARCH_PROFILE_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_MEMBER_SEARCH_PROFILE_FAIL, err));
    });
};

const getMembersList = params => (dispatch) => {
  dispatch(action(types.GET_MEMBERS_SEARCH_RESULTS_REQUEST));

  search.getMembersList(params)
    .then(res => dispatch(action(types.GET_MEMBERS_SEARCH_RESULTS_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_MEMBERS_SEARCH_RESULTS_FAIL, err));
    });
};

export default {
  clearMembersListResults,
  getMembersList,
  getMember,
};
