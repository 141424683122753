import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { emailRegExp } from '../../utils/regExp';
import Login from './styles';

import {
  H1,
  Link,
  Paragraph,
} from '../../components/common/Text';
import Button from '../../components/common/Button';
import CheckboxField from '../../components/common/CheckboxField';
import InputField from '../../components/common/InputField';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import {
  authActions,
  authSelectors,
} from '../../store/ducks/auth';

const LOGIN_TEXT = 'Login to access your Member Profile, renew your membership and register for national or regional events. If you are not a CCCA member, there’s no better time to become one – ';

const LoginPage = ({
  requestError,
  loading,
  handleLogin,
}) => {

  const onSubmit = useCallback((fields) => {
    handleLogin(fields);
  }, [handleLogin]);

  return (
    <Login>
      <Header />
      <div className="login-container">
        <H1 className="title">Member Log-In</H1>
        <div className="hr" />
        <div className="login-content">
          <Paragraph className="login-message">
            {LOGIN_TEXT}
            <Link href="/join-the-ccca">Join Now</Link>
            .
          </Paragraph>

          <Formik
            initialValues={{
              email: '',
              password: '',
              rememberNextTime: false,
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string()
                  .required('Email is required')
                  .matches(emailRegExp, 'Wrong email format'),
                password: Yup.string()
                  .required('Password is required'),
              })
            }
            onSubmit={onSubmit}
          >
            {({ errors, resetForm, touched }) => (
              <Form id="cccaLogin">
                <div className="form-fields-container">
                  <div className="form-fields">
                    <InputField
                      autoComplete="on"
                      errors={errors}
                      label="Email address"
                      name="email"
                      touched={touched}
                      type="text"
                    />
                    <InputField
                      autoComplete="on"
                      errors={errors}
                      label="Password"
                      name="password"
                      touched={touched}
                      type="password"
                    />
                    <Link href="/reset-password">
                      &gt; Forgot Password
                    </Link>
                  </div>
                  <CheckboxField
                    className="checkbox"
                    errors={errors}
                    label="Remember me next time"
                    name="rememberNextTime"
                    touched={touched}
                  />
                </div>
                <div className="errors">
                  {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
                  {Object.keys(requestError).map(e => <p key={e} className="error">{requestError[e]}</p>)}
                </div>
                <div className="buttons-block">
                  <Button
                    disabled={errors.length}
                    loading={loading}
                    name="login"
                    title="Login"
                    type="submit"
                  />
                  <Button
                    loading={loading}
                    onClick={resetForm}
                    styleType="border-less"
                    title="Reset"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </Login>
  );
};

LoginPage.propTypes = {
  requestError: PropTypes.object,
  loading: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
  requestError: {},
  loading: false,
};

const mapStateToProps = state => ({
  requestError: authSelectors.selectErrorLogin(state),
  loading: authSelectors.selectLoadingLogin(state),
});

const mapDispatchToProps = {
  handleLogin: authActions.login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
