import React from 'react';
import PropTypes from 'prop-types';

import RegionalBoxes from './styles';
import CheckBox from './Checkboxes';

const RegionalBoxesComponent = ({
  data,
  onChange,
  regionalPlans,
  values,
}) => (
  <RegionalBoxes>
    {(regionalPlans || []).map(item => (
      <CheckBox
        data={data}
        key={item.id}
        item={item}
        onChange={onChange}
        values={values}
      />
    ))}
  </RegionalBoxes>
);

RegionalBoxesComponent.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  regionalPlans: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

export default RegionalBoxesComponent;
