import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { generateId } from '../../../utils/serializer';
import RegionalAdministratorMemberSearchForm from './style';

import InputField from '../../common/InputField';
import Button from '../../common/Button';
import SelectField from '../../common/Select';

import {
  locationActions,
  locationSelectors,
} from '../../../store/ducks/location';

import {
  memberSelectors,
} from '../../../store/ducks/member';


const RegionalAdministratorMemberSearchFormComponent = ({
  countries,
  getCountries,
  availableRegions,
  initData,
  loadingCountries,
  onReset,
  onSubmit,
  renewalWindow,
}) => {
  const [fromKey, setFormKey] = useState(generateId());

  useEffect(() => {
    if (!Object.keys(countries).length) {
      getCountries();
    }
  }, [countries, getCountries]);

  useEffect(() => {
    setFormKey(generateId());
  }, [availableRegions]);

  const afterJune = new Date().getMonth() >= 5;
  const dateAdder = afterJune ? 1 : 0

  const membershipStatusOptions = [
    { label: 'All', value: 'all_members'},
    { label: 'Current', value: 'current_members'},
    { label: `Renewed ${new Date(renewalWindow?.end).getFullYear() + dateAdder || ""}`, value: 'renewed_members'},
    { label: 'Not Renewed', value: 'not_renewed_members'}
  ]

  const countriesOptions = useMemo(() => (
    [
      { label: 'All', value: '' },
      ...Object.keys(countries).map(e => ({ label: e, value: countries[e] })),
    ]
  ), [countries]);

  const regionalIdOptions = useMemo(() => (
    Object.keys(availableRegions || {})
      .filter(e => availableRegions[e].administrator)
      .map(e => ({ label: e, value: availableRegions[e].regionCode }))
  ), [availableRegions]);

  const handleSubmit = useCallback((fields) => {
    const filteredValues = Object.keys(fields)
      .filter(e => fields[e])
      .reduce((obj, curr) => ({
        ...obj,
        [curr]: fields[curr],
      }), {});
    onSubmit(filteredValues);
  }, [onSubmit]);

  return (
    <RegionalAdministratorMemberSearchForm>
      <Formik
        key={JSON.stringify(initData)}
        initialValues={{
          firstName: initData.firstName || '',
          lastName: initData.lastName || '',
          email: initData.email || '',
          city: initData.city || '',
          country: initData.country || '',
          state: initData.state || '',
          region: (regionalIdOptions || []).length == 1 ? regionalIdOptions[0].value : '',
          regionalMemberStatus: ''
        }}
        validationSchema={
          Yup.object()
            .shape({
              firstName: Yup.string(),
              lastName: Yup.string(),
              email: Yup.string(),
              city: Yup.string(),
              country: Yup.string(),
              state: Yup.string(),
              region: Yup.string()
                .required('Regional Select is required'),
              regionalMemberStatus: Yup.string()
                .required('You must select the regional membership status')
            })
        }
        onSubmit={handleSubmit}
      >
        {({
          errors,
          resetForm,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <div className="form-container">
              <InputField
                errors={errors}
                label="FIRST NAME"
                name="firstName"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="LAST NAME"
                name="lastName"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="EMAIL"
                name="email"
                touched={touched}
              />
              <InputField
                errors={errors}
                label="CITY"
                name="city"
                touched={touched}
              />
              <SelectField
                errors={errors}
                label="COUNTRY"
                loading={loadingCountries}
                name="country"
                onChange={setFieldValue}
                options={countriesOptions}
                touched={touched}
                values={values}
              />
              <InputField
                errors={errors}
                label="STATE/PROVINCE"
                name="state"
                touched={touched}
              />
              <SelectField
                errors={errors}
                label="REGION"
                name="region"
                onChange={setFieldValue}
                required
                options={regionalIdOptions}
                touched={touched}
                values={values}
              />
              <SelectField
                errors={errors}
                label="REGIONAL MEMBER STATUS"
                name="regionalMemberStatus"
                options={membershipStatusOptions}
                onChange={setFieldValue}
                required
                touched={touched}
                values={values}
              />
            </div>
            <div className="errors">
              {Object.keys(errors)
                .map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
            </div>

            <div className="btn-wrapper">
              <Button
                disabled={!Object.values(values).find(e => Boolean(e))}
                title="SEARCH"
                type="submit"
              />
              <Button
                onClick={() => {
                  resetForm();
                  onReset();
                }}
                styleType="border-less"
                title="CLEAR SEARCH FORM"
              />
            </div>
          </Form>
        )}
      </Formik>
    </RegionalAdministratorMemberSearchForm>
  );
};

RegionalAdministratorMemberSearchFormComponent.propTypes = {
  countries: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  availableRegions: PropTypes.object,
  initData: PropTypes.object,
  loadingCountries: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renewalWindow: PropTypes.object,
};

RegionalAdministratorMemberSearchFormComponent.defaultProps = {
  initData: {},
  availableRegions: null,
  loadingCountries: true,
};

const mapStateToProps = state => ({
  countries: locationSelectors.selectCountries(state),
  loadingCountries: locationSelectors.selectLoadingCountries(state),
  availableRegions: memberSelectors.selectRegionalMemberships(state),
  renewalWindow: memberSelectors.selectRenewalWindow(state),
});

const mapDispatchToProps = {
  getCountries: locationActions.getCountries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegionalAdministratorMemberSearchFormComponent);
