import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Step6 from './styles';

import {
  HighlightedText,
} from '../../../components/common/Text';
import SelectField from '../../../components/common/Select';
import Button from '../../../components/common/Button';

const SELECT_OPTIONS = [
  { label: 'Select', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'No Changes', value: 'No Changes' },
];

const Step6Component = ({
  handleNextStep,
  prevStep,
}) => {
  const [selectedOption] = useState('Select'); // Initialize the local state

  return (
    <Step6>
      <HighlightedText className="title">STEP 6: VEHICLE INFORMATION</HighlightedText>
      <Formik initialValues={{ selectionConfirm: selectedOption }} onSubmit={handleNextStep}>
        {formikProps => (
          <Form>
            <SelectField
              className="select"
              label="Have you updated your vehicle information?"
              name="selectionConfirm"
              options={SELECT_OPTIONS}
              required
              placeholder={formikProps.values.selectionConfirm}
              onChange={(name, value) => {
                formikProps.setFieldValue(name, value);
              }}
              touched={formikProps.touched.selectionConfirm}
              onBlur={formikProps.handleBlur}
            />
            <p style={{ fontWeight: 'bold', marginBottom: '20px', color: 'rgb(77, 77, 77)' }}>
              Go to My Vehicles to verify that all of your vehicle information is correct
            </p>

            <div className="btnWrapper">
              <Button
                type="submit"
                title="CONTINUE TO STEP 7"
                disabled={formikProps.values.selectionConfirm === 'Select'} // Button is disabled if no option is selected
              />
              <Button
                title="BACK"
                styleType="border-less"
                onClick={prevStep}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Step6>
  );
};

Step6Component.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default Step6Component;
