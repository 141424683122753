const CREATE_VEHICLE_REQUEST = 'CREATE_VEHICLE_REQUEST';
const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
const CREATE_VEHICLE_FAIL = 'CREATE_VEHICLE_FAIL';

const DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST';
const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
const DELETE_VEHICLE_FAIL = 'DELETE_VEHICLE_FAIL';

const GET_MAKES_REQUEST = 'GET_MAKES_REQUEST';
const GET_MAKES_SUCCESS = 'GET_MAKES_SUCCESS';
const GET_MAKES_FAIL = 'GET_MAKES_FAIL';

const GET_VEHICLES_REQUEST = 'GET_VEHICLES_REQUEST';
const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
const GET_VEHICLES_FAIL = 'GET_VEHICLES_FAIL';

const RESET_STATE = 'RESET_STATE';

const RESET_VEHICLES_FORM_ERRORS = 'RESET_VEHICLES_FORM_ERRORS';

const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST';
const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL';

export default {
  CREATE_VEHICLE_REQUEST,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,

  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,

  GET_MAKES_REQUEST,
  GET_MAKES_SUCCESS,
  GET_MAKES_FAIL,

  GET_VEHICLES_REQUEST,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,

  RESET_STATE,

  RESET_VEHICLES_FORM_ERRORS,

  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
};
