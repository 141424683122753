import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MembershipDetails from './style';

import MembershipContainer from '../../../components/MemberShipContainer';

import { memberSelectors } from '../../../store/ducks/member';

const MembershipDetailsComponent = ({
  associateMemberships,
  nationalMembership,
  regionalMembership,
}) => (
  <MembershipDetails>
    <div className="wrapper">
      <MembershipContainer
        border
        fields={nationalMembership}
        title="NATIONAL MEMBERSHIP"
      />
      <MembershipContainer
        border
        buttonAction={null}
        buttonTitle="Edit Membership"
        fields={regionalMembership}
        title="REGIONAL MEMBERSHIP(S)"
      />
      <MembershipContainer
        buttonAction={null}
        buttonTitle={
          Object.keys(associateMemberships || {}).length
            ? 'Edit Membership' : 'Add Associate'
        }
        fields={associateMemberships}
        title="ASSOCIATE MEMBERSHIPS"
      />
    </div>
  </MembershipDetails>
);

MembershipDetailsComponent.propTypes = {
  associateMemberships: PropTypes.object,
  nationalMembership: PropTypes.object,
  regionalMembership: PropTypes.object,
};

MembershipDetailsComponent.defaultProps = {
  associateMemberships: null,
  nationalMembership: null,
  regionalMembership: null,
};

const mapStateToProps = state => ({
  associateMemberships: memberSelectors.selectAssociateMemberships(state),
  lifelong: memberSelectors.selectLifelong(state),
  nationalMembership: memberSelectors.selectNationalMembership(state),
  regionalMembership: memberSelectors.selectRegionalMemberships(state),
});

export default connect(
  mapStateToProps,
  null,
)(MembershipDetailsComponent);
