import styled from 'styled-components';
import { backgroundColor } from '../../assets/theme/themeVariables';

const Home = styled.div`
  min-height: 100vh;
  background-color: ${backgroundColor};
  padding-bottom: 278px;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-bottom: 300px;
  }
`;

export default Home;
