import {
  action,
} from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import { events } from '../../../services/api';
import types from './actionTypes';

const getAllEvents = () => (dispatch) => {
  dispatch(action(types.GET_ALL_EVENTS_REQUEST));

  events.getAllEvents()
    .then(res => dispatch(action(types.GET_ALL_EVENTS_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_ALL_EVENTS_FAIL, err));
    });
};

const getRegisteredEvents = () => (dispatch) => {
  dispatch(action(types.GET_REGISTERED_EVENTS_REQUEST));

  events.getRegisteredEvents()
    .then(res => dispatch(action(types.GET_REGISTERED_EVENTS_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_REGISTERED_EVENTS_FAIL, err));
    });
};

export default {
  getAllEvents,
  getRegisteredEvents,
};
