import styled from 'styled-components';
import {
  black,
  primaryColor,
} from '../../../assets/theme/themeVariables';

const Step3 = styled.div`
  .title {
    color: ${primaryColor};
  }

  .msg-text {
    margin-top: 14px;
    color: ${black};
    line-height: unset;
  }

  .form-container {
    margin-top: 15px;
  }
`;

export default Step3;
