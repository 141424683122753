import types from './actionTypes';

const initialState = {
  associateName: '',
  associateMemberships: null,
  lifelong: false,
  memberInfo: null,
  memberName: '',
  nationalMembership: null,
  regionalAdministrator: false,
  regionalMemberships: null,
  renewalWindow: null,
  updateMemberErrors: {},
};

const members = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_MEMBER_REQUEST: {
      return {
        ...state,
      };
    }
    case types.GET_MEMBER_SUCCESS: {
      const { data } = payload;
      const {
        firstName,
        lastName,
      } = data;

      return {
        ...state,
        memberInfo: data,
        memberName: `${firstName} ${lastName}`,
      };
    }
    case types.GET_MEMBER_FAIL: {
      return {
        ...state,
      };
    }
    case types.MEMBERSHIP_DETAILS_REQUEST: {
      return {
        ...state,
      };
    }
    case types.MEMBERSHIP_DETAILS_SUCCESS: {
      const {
        associate,
        lifelong,
        regionalAdministrator,
        regionalMemberships,
        renewalWindow,
        ...rest
      } = payload.data;
      const {
        firstName,
        lastName,
      } = associate;

      const associateName = (firstName && `${firstName} ${lastName}`) || '';

      return {
        ...state,
        associateMemberships: associate,
        associateName,
        lifelong,
        nationalMembership: rest,
        regionalAdministrator,
        regionalMemberships,
        renewalWindow,
      };
    }
    case types.UPDATE_MEMBER_REQUEST: {
      return {
        ...state,
        updateMemberErrors: {},
      };
    }
    case types.UPDATE_MEMBER_SUCCESS: {
      return {
        ...state,
        memberInfo: payload,
      };
    }
    case types.UPDATE_MEMBER_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        updateMemberErrors: data,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default members;
