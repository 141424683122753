import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
} from 'react-router-dom';
import SubMenu from './styles';

import Button from '../../../common/Button';
import {
  Paragraph,
} from '../../../common/Text';

const SubMenuComponent = ({
  className,
  items,
  title,
}) => {
  const renderOptions = (option) => {
    if (option.hidden) return null;

    if (option.type === 'button') {
      return (
        <Button
          key={option.title}
          {...option}
        />
      );
    }

    return (
      <NavLink
        activeClassName="active"
        className="item"
        key={option.title}
        to={option.href}
      >
        {option.title}
      </NavLink>
    );
  };

  return (
    <SubMenu className={className}>
      <Paragraph className="title">
        {title}
      </Paragraph>
      {items.length ? (
        <div className="submenu">
          {
            items.map(renderOptions)
          }
        </div>
      ) : (
        null
      )}
    </SubMenu>
  );
};

SubMenuComponent.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
};

SubMenuComponent.defaultProps = {
  className: '',
  items: [],
};

export default SubMenuComponent;
