import styled from 'styled-components';
import {
  primaryColor,
} from '../../../assets/theme/themeVariables';

const Step4 = styled.div`
  .select-container {
    max-width: 275px;
  }

  .form-container {
    margin-top: 15px;
    
    .fields {
      display: flex;
      flex-direction: row;

      & > div {
        width: auto;
        margin-right: 10px;
      }
    }
  }

  .error {
    color: ${primaryColor}
  }

  .btn-wrapper {
    margin-top: 40px;
  }

  div > button {
    margin-right: 10px;
    margin-top: 5px;
  }

  @media screen and (max-width: 576px) {
    .form-container {
      .fields {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 325px) {
    .btn-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    div > button {
      margin-right: 0;
    }
  }
`;

export default Step4;
