import styled from 'styled-components';
import {
  backgroundColor,
  black,
  gray5,
  primaryColor,
  white,
} from '../../../../assets/theme/themeVariables';

const SubMenu = styled.li`
  height: 78px;
  position: relative;

  &.active {
    color: ${black};

    .title {
      color: ${black};
    }
  }

  .title {
    font-weight: bold;
    color: ${gray5};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }

  .submenu {
    display: none;
    width: max-content;
    padding: 15px;
    background-color: ${white};
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    border-radius: 4px;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;

    .button {
      min-height: initial;
      margin: 0;
      margin-top: 2px;
      padding: 0;
      font-weight: bold;
    }
  }

  .item {
    padding: 5px;
    box-sizing: border-box;

    &.active {
      color: ${black} !important;
    }
  }

  &:hover {
    background-color: ${backgroundColor};

    .submenu {
      display: flex;
    }
  }

  @media screen and (max-width: 992px) {
    &.active {
      border-bottom: 2px solid ${primaryColor};
    }
  }
`;

export default SubMenu;
