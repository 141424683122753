import styled from 'styled-components';
import {
  backgroundColor,
} from '../../assets/theme/themeVariables';

const Checkout = styled.div`
`;

export const ModalContent = styled.div`
  width: 100%;
  background-color: ${backgroundColor};

  .title {
    margin: 0 auto;
    margin-bottom: 16px;
  }

  .payment-btns {
    margin: 0 auto;
  }
`;

export default Checkout;
