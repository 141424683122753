import types from './actionTypes';

const initialState = {
  isRenewalOpen: false,
  isRenewalConfirmation: false,
  loadingMemberInfo: false,
  loadingUpdateMemberInfo: false,
  memberInfo: null,
  updateRenewalMemberErrors: {},
};

const renewal = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_RENEWAL_MEMBER_REQUEST: {
      return {
        ...state,
        loadingMemberInfo: true,
      };
    }
    case types.GET_RENEWAL_MEMBER_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingMemberInfo: false,
        memberInfo: data,
      };
    }
    case types.GET_RENEWAL_MEMBER_FAIL: {
      return {
        ...state,
        loadingMemberInfo: false,
      };
    }
    case types.SET_RENEWAL_MODAL_VISIBILITY: {
      return {
        ...state,
        isRenewalOpen: !state.isRenewalOpen,
      };
    }
    case types.SET_RENEWAL_CONFIRMATION_MODAL_VISIBILITY: {
      return {
        ...state,
        isRenewalConfirmation: !state.isRenewalConfirmation,
      };
    }
    case types.UPDATE_RENEWAL_MEMBER_REQUEST: {
      return {
        ...state,
        loadingUpdateMemberInfo: true,
        updateRenewalMemberErrors: {},
      };
    }
    case types.UPDATE_RENEWAL_MEMBER_SUCCESS: {
      return {
        ...state,
        loadingUpdateMemberInfo: false,
      };
    }
    case types.UPDATE_RENEWAL_MEMBER_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        loadingUpdateMemberInfo: false,
        updateRenewalMemberErrors: data,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default renewal;
