import { action } from '../../../utils/serializer';
import { subscription } from '../../../services/api';
import types from './actionTypes';

const createMember = data => (dispatch) => {
  dispatch(action(types.CREATE_MEMBER_REQUEST));

  return subscription.createMember(data)
    .then((res) => {
      dispatch(action(types.CREATE_MEMBER_SUCCESS, res));
      return { status: 'success', params: res.data };
    })
    .catch((err) => {
      dispatch(action(types.CREATE_MEMBER_FAIL, err));

      return { status: 'fail' };
    });
};

const validateEmail = params => (dispatch) => {
  dispatch(action(types.VALIDATE_EMAIL_REQUEST));

  subscription.validateEmail(params)
    .then(res => dispatch(action(types.VALIDATE_EMAIL_SUCCESS, res)))
    .catch(err => dispatch(action(types.VALIDATE_EMAIL_FAIL, err)));
};

export default {
  createMember,
  validateEmail,
};
