import types from './actionTypes';

const initialState = {
  createMemberErrors: {},
  emailIsValid: true,
  loadingCreate: false,
  loadingUpdate: false,
  loadingValidateEmail: false,
  updateMemberErrors: {},
};

const subscriptions = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CREATE_MEMBER_REQUEST: {
      return {
        ...state,
        createMemberErrors: {},
        loadingCreate: true,
      };
    }
    case types.CREATE_MEMBER_SUCCESS: {
      return {
        ...state,
        loadingCreate: false,
      };
    }
    case types.CREATE_MEMBER_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        createMemberErrors: data,
        loadingCreate: false,
      };
    }
    case types.UPDATE_MEMBER_REQUEST: {
      return {
        ...state,
        loadingUpdate: true,
        updateMemberErrors: {},
      };
    }
    case types.UPDATE_MEMBER_SUCCESS: {
      return {
        ...state,
        loadingUpdate: false,
      };
    }
    case types.UPDATE_MEMBER_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        loadingUpdate: false,
        updateMemberErrors: data,
      };
    }
    case types.VALIDATE_EMAIL_REQUEST: {
      return {
        ...state,
        emailIsValid: true,
        loadingValidateEmail: true,
      };
    }
    case types.VALIDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        emailIsValid: true,
        loadingValidateEmail: false,
      };
    }
    case types.VALIDATE_EMAIL_FAIL: {
      return {
        ...state,
        emailIsValid: false,
        loadingValidateEmail: false,
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default subscriptions;
