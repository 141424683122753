import { toast } from 'react-toastify';
import { action } from '../../../utils/serializer';
import types from './actionTypes';
import { auth } from '../../../services/api';
import { authError } from '../../../utils/errorHandle';
import { commonActions } from '../common';

const changePassword = data => (dispatch) => {
  dispatch(action(types.CHANGE_PASSWORD_REQUEST));

  return auth.changePassword(data)
    .then((res) => {
      dispatch(action(types.CHANGE_PASSWORD_SUCCESS, res));
      toast.success('Your password was successfully updated');

      return 'success';
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.CHANGE_PASSWORD_FAIL, err));

      return 'fail';
    });
};

const login = ({ rememberNextTime, ...data }) => (dispatch) => {
  dispatch(action(types.LOGIN_REQUEST));

  auth.login(data)
    .then(res => dispatch(action(types.LOGIN_SUCCESS, { ...res, rememberNextTime })))
    .catch(err => dispatch(action(types.LOGIN_FAIL, err)));
};

const logout = () => (dispatch) => {
  dispatch(commonActions.resetState());
  auth.logout();
};

const logoutWithoutRequest = () => (dispatch) => {
  dispatch(commonActions.resetState());
};

const newPasswordOnResetPassword = data => (dispatch) => {
  dispatch(action(types.NEW_PASSWORD_ON_RESET_PASSWORD_REQUEST));

  return auth.newPasswordOnResetPassword(data)
    .then((res) => {
      dispatch(action(types.NEW_PASSWORD_ON_RESET_PASSWORD_SUCCESS, res));

      return 'success';
    })
    .catch((err) => {
      dispatch(action(types.NEW_PASSWORD_ON_RESET_PASSWORD_FAIL, err));

      return 'fail';
    });
};

const sendEmailOnResetPassword = data => (dispatch) => {
  dispatch(action(types.SEND_EMAIL_ON_RESET_PASSWORD_REQUEST));

  return auth.sendEmailOnResetPassword(data)
    .then((res) => {
      dispatch(action(types.SEND_EMAIL_ON_RESET_PASSWORD_SUCCESS, res));

      return 'success';
    })
    .catch((err) => {
      dispatch(action(types.SEND_EMAIL_ON_RESET_PASSWORD_FAIL, err));

      return 'fail';
    });
};

export default {
  changePassword,
  login,
  logout,
  logoutWithoutRequest,
  newPasswordOnResetPassword,
  sendEmailOnResetPassword,
};
