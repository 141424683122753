import React from 'react';
import { Switch } from 'react-router';
import {
  NavLink,
  Redirect,
  Route,
} from 'react-router-dom';
import SearchWrapper from './styles';

import MemberSearch from './MemberSearch';
import SearchVehicleProfile from './SearchVehicleProfile';
import SearchMemberProfile from './SearchMemberProfile';
import VehicleSearch from './VehicleSearch';

const renderContent = () => (
  <Switch>
    <Route
      exact
      path="/my-profile/search/member"
      component={MemberSearch}
    />
    <Route
      exact
      path="/my-profile/search/member/:memberNo"
      component={SearchMemberProfile}
    />
    <Route
      exact
      path="/my-profile/search/vehicle"
      component={VehicleSearch}
    />
    <Route
      exact
      path="/my-profile/search/vehicle/:vehicleId"
      component={SearchVehicleProfile}
    />
    <Redirect to="/my-profile/search/member" />
  </Switch>
);

const MyProfilePage = () => (
  <SearchWrapper>
    <ul className="tabs">
      <li className="tab">
        <NavLink to="/my-profile/search/member" exact activeClassName="active-tab">
          MEMBER SEARCH
        </NavLink>
      </li>
      <li className="tab">
        <NavLink to="/my-profile/search/vehicle" exact activeClassName="active-tab">
          VEHICLE SEARCH
        </NavLink>
      </li>
    </ul>
    {renderContent()}
  </SearchWrapper>
);

MyProfilePage.propTypes = {
};

MyProfilePage.defaultProps = {
};

export default MyProfilePage;
