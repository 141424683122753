import React from 'react';
import PropTypes from 'prop-types';
import Step1 from './styles';

import MembersInfoForm from '../../../components/Forms/MembersInfoForm';
import {
  HighlightedText,
  Paragraph,
} from '../../../components/common/Text';

const MESSAGE_TEXT = 'The CCCA includes many historians, journalists and students as members. While the club believes ownership of a Classic automobile enhances club membership, it is not necessary to own a Classic to be a significant contributing member of CCCA. We embrace individuals who share our interests, appreciate the camaraderie and who support the goals of the club.';

const Step1Component = ({
  data,
  handleNextStep,
  onCancel,
  prevStep,
}) => (
  <Step1>
    <div className="form-content">
      <HighlightedText className="title">STEP 1: BASIC INFO</HighlightedText>
      <Paragraph>{MESSAGE_TEXT}</Paragraph>
      <MembersInfoForm
        className="form"
        data={data}
        id={Object.keys(data).length || ''}
        isCancelButton
        onCancel={onCancel}
        onSubmit={handleNextStep}
        prevStep={prevStep}
      />
    </div>
  </Step1>
);

Step1Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default Step1Component;
