import React from 'react';
import ReactTable from 'react-table-6';
import PropTypes from 'prop-types';
import Table from './styles';

const TableComponent = ({
  columns,
  data,
}) => (
  <Table>
    <ReactTable
      data={data}
      columns={columns}
      className="table"
      defaultPageSize={10000}
      loading={false}
      loadingText={false}
      minRows={0}
      showPagination={false}
      sortable={false}
    />
  </Table>
);


TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};

TableComponent.defaultProps = {
  data: null,
};

export default TableComponent;
