import { action } from '../../../utils/serializer';
import types from './actionTypes';
import { location } from '../../../services/api';

const getCountries = () => (dispatch) => {
  dispatch(action(types.COUNTRIES_REQUEST));

  location.getCountries()
    .then(res => dispatch(action(types.COUNTRIES_SUCCESS, res)))
    .catch(err => dispatch(action(types.COUNTRIES_FAIL, err)));
};

const getRegions = params => (dispatch) => {
  dispatch(action(types.REGIONS_REQUEST));

  location.getRegions(params)
    .then(res => dispatch(action(types.REGIONS_SUCCESS, res)))
    .catch(err => dispatch(action(types.REGIONS_FAIL, err)));
};

export default {
  getCountries,
  getRegions,
};
