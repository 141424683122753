import styled from 'styled-components';
import {
  backgroundColor,
  gray1,
  gray4,
  gray7,
  primaryColor,
  primaryFont,
} from '../../../assets/theme/themeVariables';

const Step6 = styled.div`
  background-color: ${backgroundColor};

  .row,
  .item-price-row {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .separator {
      margin: 10px 0;
      background-color: ${gray1};
    }
  }

  .item {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      margin: auto 0;
    }
  }

  .table-header {
    color: ${gray4};
  }

  .remove-btn {
    color: ${primaryColor};
    font-family: ${primaryFont};
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }

  .item-price-row {
    height: unset;
    margin-right: 0 !important;

    .item-price {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      .price-text {
        width: 100%;
        height: max-content;
        min-height: 50px;
        margin: auto 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.associate {
          height: initial;
          margin: 0;
        }
      }
    }
  }

  .total-price {
    margin-right: 20px;
    color: ${gray7};
    display: flex;
    align-items: center;

    span:first-child {
      margin-right: 5px;
    }
  }

  .errors {
    padding-top: 20px;
  }

  .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 576px) {
    .item {
      min-height: 65px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: initial;

      &.associate {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .item-price-row {
      .item-price {
        .price-text {
          min-height: 65px;
        }
      }
    }

    .button-wrapper {
      margin-top: 0;
      flex-direction: column-reverse;
    }

    .total-price {
      margin-right: 0;
      margin-bottom: 20px;
      padding: 0 20px;
      justify-content: space-between;
    }
  }
`;

export default Step6;
