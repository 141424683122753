import { createGlobalStyle } from 'styled-components';
import { primaryColor, backgroundColor } from './themeVariables';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  *:focus {
    outline: 0;
  }

  a {
    color: initial;
    text-decoration: none;
  }

  body {
    width: 100%;
    font-family: 'komet', 'sans-serif';
    font-weight: normal;
  }

  button {
    border: none;
    color: initial;
    background-color: initial;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  ul {
    list-style: none;
  }

  #root {
    background-color: ${backgroundColor};
  }

  .error {
    color: ${primaryColor};
  }

  .tooltip {
    p {
      margin-bottom: 0 !important;
    }

    &::before {
      content: none !important;
    }

    &::after {
      content: none !important;
    }
  }

  .ReactModal {
    &__Content {
      background-color: ${backgroundColor} !important;

      .content {
        width: inherit;
        max-width: 100%;
        position: relative;
      }

      .close {
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        img {
          margin: -10px;
        }
      }

      @media screen and (max-width: 576px) {
        padding: 30px !important;
      }
    }
  }

  body.ReactModal__Body--open {
    overflow: hidden;

    #root {
        filter: blur(5px);
    }
  }

  .ReactModal__Overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModal__Content {
    max-height: calc(100vh - 2rem) !important;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto !important;
  }

  .ReactModal__Content::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .ReactModal__Content::-webkit-scrollbar:vertical {
    width: 11px;
  }

  .ReactModal__Content::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  .ReactModal__Content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }
`;

export default GlobalStyle;
