import styled from 'styled-components';
import {
  gray7,
  primaryColor,
} from '../../../assets/theme/themeVariables';

const SelectWrapper = styled.div`
  width: 100%;
  margin: 10px 0;

  label {
    color: ${gray7};
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
  }

  .select-control {
    margin-top: 5px;
    font-weight: normal;
  }

  .required-mark {
    color: ${primaryColor};
  }
`;

export default SelectWrapper;
