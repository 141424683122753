import styled from 'styled-components';
import {
  gray2,
  gray5,
  gray7,
  secondaryBackgroundColor,
} from '../../../assets/theme/themeVariables';

const Footer = styled.footer`
  width: 100%;
  height: 278px;
  position: absolute;
  bottom: 0;
  background-color: ${secondaryBackgroundColor};
  box-sizing: border-box;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .first-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .links {
      width: 45%;
      display: flex;
      justify-content: space-between;

      .link {
        color: ${gray7};
      }
    }
  }

  .hr {
    width: 100%;
    height: 1px;
    background-color: ${gray2};
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .button-wrapper {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-logo {
    max-height: 60px;
  }

  .paragraph {
    color: ${gray5};
    font-size: 14px;
    font-weight: bold;
  }

  .p-with-border {
    border-right: 1px solid ${gray5};
    margin-right: 6px;
    padding-right: 6px;
  }

  .signature-text {
    width: max-content;
    color: ${gray5};
    font-size: 14px;
    font-weight: bold;
  }

  .icon {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
    padding: 40px 60px;
    align-items: center;

    .first-row {
      display: flex;
      flex-direction: column;
      align-items: initial;
      justify-content: space-between;

      .links {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
    }

    .footer-logo {
      max-height: 50px;
    }

    .text-wrapper {
      margin-top: 15px;
      text-align: center;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 576px) {
    .first-row {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: initial;
      justify-content: space-between;

      .links {
        display: none;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .wrapper {
      margin-bottom: 20px;
    }
  }
`;

export default Footer;
