import React from 'react';
import PropTypes from 'prop-types';
import Cart from './styles';
import { transformToPrice } from '../../../../utils/serializer';

import {
  H3,
  H4,
  H5,
} from '../../../common/Text';

const DEFAULT_MSG = 'CCCA Membership';

const CartComponent = ({
  item: {
    cost,
    description,
    id,
    title,
  },
  onChange,
  value,
}) => (
  <Cart>
    <label className="radio-container" htmlFor={id}>
      <input
        checked={value === id}
        id={id}
        name="radioCart"
        onChange={() => onChange(id)}
        type="radio"
      />
      <span className="checkmark"/>
    </label>
    <H4 className="cart-title">{title}</H4>
    <H5>{description || DEFAULT_MSG}</H5>
    <H3>{transformToPrice(cost)}</H3>
  </Cart>
);

CartComponent.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

CartComponent.defaultProps = {
  value: null,
};

export default CartComponent;
