const selectIsRenewalOpen = state => state.renewal.isRenewalOpen;
const selectIsRenewalConfirmation = state => state.renewal.isRenewalConfirmation;
const selectLoadingMemberInfo = state => state.renewal.loadingMemberInfo;
const selectLoadingUpdateMemberInfo = state => state.renewal.loadingUpdateMemberInfo;
const selectMemberInfo = state => state.renewal.memberInfo;
const selectUpdateRenewalMemberErrors = state => state.renewal.updateRenewalMemberErrors;

export default {
  selectIsRenewalOpen,
  selectIsRenewalConfirmation,
  selectLoadingMemberInfo,
  selectLoadingUpdateMemberInfo,
  selectMemberInfo,
  selectUpdateRenewalMemberErrors,
};
