export default {
  advertise: 'https://www.classiccarclub.org/advertise',
  approvedClassics: 'https://www.classiccarclub.org/approved-classics',
  articles: 'https://www.classiccarclub.org/blog',
  biographies: 'https://www.classiccarclub.org/biographies',
  caravan: 'https://www.classiccarclub.org/caravan_handbook_full',
  joyOfTouring: 'https://www.classiccarclub.org/joy-of-touring',
  cccaBlog: 'https://blog.classiccarclub.org/ccca-blog',
  classifiedAdSubmission: 'https://www.classiccarclub.org/classified_ad',
  classifiedsFAQ: 'https://www.classiccarclub.org/faq',
  contactUs: 'https://www.classiccarclub.org/contact-us',
  donations: 'https://www.classiccarfoundation.org/donations',
  educationalFoundation: 'https://www.classiccarclub.org/educational_foundation',
  events: 'https://www.classiccarclub.org/events',
  concoursEvents: 'https://www.classiccarclub.org/concours-events',
  join: '/join-the-ccca',
  judging: 'https://www.classiccarclub.org/judging',
  login: '/login',
  mainDomain: 'https://www.classiccarclub.org',
  membershipFormPdf: 'https://dealer-marketing-assets.s3.amazonaws.com/CCCA/CCCA+Membership+Form.pdf',
  membershiphandbook: 'https://reader.mediawiremobile.com/classiccarclubofamerica/issues/208113/viewer',
  myProfile: '/my-profile',
  museum: 'https://www.classiccarclub.org/museum',
  museumContributions: 'https://cccamuseum.org',
  news: 'https://www.classiccarclub.org/news',
  ourClub: 'https://www.classiccarclub.org/our-club',
  privacyPolicy: 'https://www.classiccarclub.org/privacy-policy',
  regionalClubs: 'https://www.classiccarclub.org/regional-clubs',
  relatedLinks: 'https://www.classiccarclub.org/related-links',
  sale: 'https://cccastore.itemorder.com/sale',
  speedDigital: 'https://speeddigital.com',
  sponsors: 'https://www.classiccarclub.org/our-club#sponsors',
  store: 'https://www.classiccarclub.org/store',
  vehicles: 'https://www.classiccarclub.org/vehicles',
  board: 'https://www.classiccarclub.org/board',
};
