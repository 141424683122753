import styled from 'styled-components';
import {
  gray3,
  gray7,
  primaryColor,
} from '../../../../assets/theme/themeVariables';

const SearchVehicleProfile = styled.div`
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-found-text {
    text-align: center;
    font-size: 24px;
    color: ${gray3};
  }

  .container {
    padding: 0 60px 60px 60px;

    .link {
      width: 100%;
      margin: 20px 0;
      display: flex;
      justify-content: center;
    }
  }

  .title {
    color: ${primaryColor};
  }

  .items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    grid-gap: 20px;
  }

  .item-info {
    .row {
      display: flex;

      .title {
        color: ${gray7};
        font-weight: bold;
        white-space: nowrap;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 720px) {
    .container {
      padding: 0 30px 30px 30px;
    }
  }

  @media screen and (max-width: 576px) {
    .container {
      padding: 30px;
    }
  }
`;

export default SearchVehicleProfile;
