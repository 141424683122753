import startCase from 'lodash.startcase';
import { fieldNamesByKeys } from './constants';

import { authActions } from '../store/ducks/auth';

export const authError = error => (dispatch) => {
  if ((error.response || {}).status === 401) {
    dispatch(authActions.logoutWithoutRequest());
    return true;
  }
  return false;
};

export const formErrorParser = (error = {}) => (
  Object.keys(error).map((e) => {
    const errorType = typeof error[e];

    if (error[e] && errorType === 'string') {
      return error[e];
    }

    if (error[e] && errorType === 'number') {
      return '';
    }

    return (error[e] || []).reduce((acc, elem) => {
      if (!elem) {
        return acc;
      }
      if (!acc) {
        return `${fieldNamesByKeys[e] || startCase(e)} ${elem}`;
      }
      return `${acc}, ${elem}`;
    }, '');
  })
);
