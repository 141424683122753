import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyVehicles from './style';

import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal';
import MyVehicleList from '../../../components/MyVehicleList';
import VehicleForm from '../../../components/Forms/VehicleForm';

import {
  vehiclesActions,
} from '../../../store/ducks/vehicles';

const FORM_DESCRIPTION = 'Please note that vehicles which have been added or modified will need to be approved by CCCA before being eligible for event registration.';
const FORM_TITLE = 'ADD A VEHICLE';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    padding: '40px',
    top: 'auto',
    left: '',
    right: '0',
    bottom: 'auto',
    width: '100%',
    maxWidth: '560px',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
  },
};

const MyVehiclesComponent = ({
  getMakes,
  getVehicles,
}) => {
  const [editData, setEditData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(document.documentElement.offsetWidth);

  window.onresize = () => {
    setScreenWidth(document.documentElement.offsetWidth);
  };
  const smallScreenWidth = screenWidth <= 1200;

  useEffect(() => {
    getMakes();
    getVehicles();
  }, [getMakes, getVehicles]);

  useEffect(() => {
    if (editData && smallScreenWidth) {
      setModalOpen(true);
    }
  }, [editData, smallScreenWidth]);

  return (
    <MyVehicles>
      <Button
        className="add-btn"
        onClick={() => setModalOpen(true)}
        styleType="secondary"
        title="+ Add a Vehicle"
      />
      <MyVehicleList onModifyVehicle={setEditData} />
      {smallScreenWidth ? (
        <Modal
          customStyles={customStyles}
          isCloseBtn={!editData}
          isOpen={isModalOpen}
          onCloseCallback={() => setModalOpen(false)}
          onRequestClose={setModalOpen}
        >
          <VehicleForm
            data={editData}
            description={FORM_DESCRIPTION}
            onCloseCallback={() => setModalOpen(false)}
            onModifyVehicle={setEditData}
            title={FORM_TITLE}
          />
        </Modal>
      ) : (
        <VehicleForm
          data={editData}
          description={FORM_DESCRIPTION}
          onModifyVehicle={setEditData}
          title={FORM_TITLE}
        />
      )}
    </MyVehicles>
  );
};

MyVehiclesComponent.propTypes = {
  getMakes: PropTypes.func.isRequired,
  getVehicles: PropTypes.func.isRequired,
};

MyVehiclesComponent.defaultProps = {
};

const mapDispatchToProps = {
  getMakes: vehiclesActions.getMakes,
  getVehicles: vehiclesActions.getVehicles,
};

export default connect(
  null,
  mapDispatchToProps,
)(MyVehiclesComponent);
