import axios from 'axios';
import {
  camelToSnake,
  getTokenFromStorage,
  snakeToCamel,
} from '../utils/serializer';

const {
  NODE_ENV,
  REACT_APP_BASE_DEVELOPMENT_URL,
  REACT_APP_BASE_URL,
} = process.env;

const baseURL = NODE_ENV === 'development' ? REACT_APP_BASE_DEVELOPMENT_URL : REACT_APP_BASE_URL;

const API = axios.create({
  baseURL,
  responseType: 'json',
  transformResponse: [data => snakeToCamel({ data }).data],
});

const requests = {
  get: (url, params = {}, configs = {}) => API.get(url, {
    headers: { Authorization: `Bearer ${getTokenFromStorage()}` },
    params: camelToSnake(params),
    ...configs,
  }),
  post: (url, data, params = {}) => API.post(url, camelToSnake({ data }).data, {
    headers: { Authorization: `Bearer ${getTokenFromStorage()}` },
    params: camelToSnake(params),
  }),
  postFormData: (url, data, params = {}) => API.post(
    url,
    data,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromStorage()}`,
        'Content-Type': 'multipart/form-data',
      },
      params: camelToSnake(params),
    },
  ),
  put: (url, data, params = {}) => API.put(url, camelToSnake(data), {
    headers: { Authorization: `Bearer ${getTokenFromStorage()}` },
    params: camelToSnake(params),
  }),
  putFormData: (url, data, params = {}) => API.put(
    url,
    data,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromStorage()}`,
        'Content-Type': 'multipart/form-data',
      },
      params: camelToSnake(params),
    },
  ),
  delete: (url, params = {}) => API.delete(url, {
    headers: { Authorization: `Bearer ${getTokenFromStorage()}` },
    params: camelToSnake(params),
  }),
};

export const administration = {
  getRegionalReport: params => requests.get(
    '/api/v1/regional_administrators/report',
    params,
    { responseType: 'text/csv' },
  ),
};

export const auth = {
  changePassword: data => requests.put('/api/v1/members/change_password', { member: data }),
  login: data => requests.post('/api/v1/members/login', { member: data }),
  logout: () => requests.delete('/api/v1/members/logout'),
  newPasswordOnResetPassword: data => requests.put('/api/v1/members/reset_password', { member: data }),
  sendEmailOnResetPassword: params => requests.post('/api/v1/members/request_reset_password', {}, params),
};

export const events = {
  getAllEvents: () => requests.get('/api/v1/events'),
  getRegisteredEvents: () => requests.get('/api/v1/registrations'),
};

export const location = {
  getCountries: () => requests.get('/api/v1/countries'),
  getRegions: params => requests.get('/api/v1/regions', params),
};

export const member = {
  getMembershipDetails: () => requests.get('/api/v1/members/membership_details'),
  getMemberInfo: () => requests.get('/api/v1/members/profile'),
  updateMemberInfo: (id, data) => requests.put(`/api/v1/members/${id}`, { member: data }),
};

export const payment = {
  approve: data => requests.post('/orders/approve', data),
};

export const plans = {
  getNationalPlans: () => requests.get('/api/v1/national_membership_plans'),
  getRegionalPlans: () => requests.get('/api/v1/regional_membership_plans'),
};

export const renew = {
  getRenewalData: () => requests.get('/api/v1/members/renew_data'),
  updateMemberInfo: data => requests.put('/api/v1/members/renew', { member: data }),
};

export const search = {
  getMember: id => requests.get(`/api/v1//members/${id}/search_profile`),
  getMembersList: params => requests.get('/api/v1/members/search', params),
  getVehicle: id => requests.get(`/api/v1//vehicles/${id}/search_profile`),
  getVehiclesList: params => requests.get('/api/v1/vehicles/search', params),
};

export const subscription = {
  createMember: data => requests.post('/api/v1/members', { member: data }),
  validateEmail: params => requests.post('/api/v1/members/validate_unique_email', {}, params),
};

export const vehicle = {
  createVehicle: data => requests.postFormData('/api/v1/vehicles', data),
  getMakes: () => requests.get('/api/v1/makes'),
  getVehicles: () => requests.get('/api/v1/vehicles'),
  deleteVehicle: id => requests.delete(`/api/v1/vehicles/${id}`),
  updateVehicle: (id, data) => requests.putFormData(`/api/v1/vehicles/${id}`, data),
};
