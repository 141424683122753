import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import AssociateMember from './styles';

import InputField from '../../common/InputField';
import Button from '../../common/Button';
import SelectField from '../../common/Select';

const SELECT_OPTIONS = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];

const AssociateMemberComponent = ({
  data: {
    associate,
  },
  nextButtonTitle,
  handleNextStep,
  prevStep,
}) => (
  <AssociateMember>
    <Formik
      initialValues={{
        firstName: (associate || {}).firstName || '',
        lastName: (associate || {}).lastName || '',
        addAssociate: Boolean(associate && Object.keys(associate).length),
      }}
      validationSchema={
        Yup.object()
          .shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
          })
      }
      onSubmit={handleNextStep}
    >
      {({
        errors,
        setFieldValue,
        touched,
        values,
      }) => (
        <Form>
          <div className="select-container">
            <SelectField
              errors={errors}
              label=""
              name="addAssociate"
              options={SELECT_OPTIONS}
              onChange={setFieldValue}
              touched={touched}
              values={values}
            />
          </div>
          { values.addAssociate
            && (
            <div>
              <div className="fields">
                <InputField
                  errors={errors}
                  label="First Name"
                  name="firstName"
                  required
                  touched={touched}
                  type="text"
                />
                <InputField
                  errors={errors}
                  label="Last Name"
                  name="lastName"
                  required
                  touched={touched}
                  type="text"
                />
              </div>
              <div className="errors">
                {Object.keys(errors)
                  .map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
              </div>
            </div>
            )}
          <div className="btn-wrapper">
            <Button
              disabled={values.addAssociate && (!values.firstName || !values.lastName)}
              title={nextButtonTitle}
              type="submit"
            />
            {/* <Button
              onClick={() => {}}
              title="SAVE"
            /> */}
            <Button
              onClick={prevStep}
              styleType="border-less"
              title="BACK"
            />
          </div>
        </Form>
      )}
    </Formik>
  </AssociateMember>
);

AssociateMemberComponent.propTypes = {
  data: PropTypes.shape({
    associate: PropTypes.object,
  }),
  handleNextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextButtonTitle: PropTypes.string,
};

AssociateMemberComponent.defaultProps = {
  data: {
    associate: {},
  },
  nextButtonTitle: '',
};

export default AssociateMemberComponent;
