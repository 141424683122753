import styled from 'styled-components';

const MemberResources = styled.div`
  padding: 0 60px;

  .title {
    margin-bottom: 25px;
  }

  .links {
    display: flex;
    flex-direction: column;

    .link {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 0 30px;
  }
`;

export default MemberResources;
