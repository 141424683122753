import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import InputField from './styles';

import { Paragraph } from '../Text';

const InputFieldComponent = ({
  className,
  errors,
  label,
  name,
  required,
  tooltipIcon,
  tooltipMessage,
  touched,
  onChange,
  isTooltip,
  ...props
}) => (
  <InputField className={className} onChange={onChange} isTooltip={isTooltip} >
    <label htmlFor={name}>
      <span className="label">
        {label.toUpperCase()}
        {required && <span className="required-mark">*</span>}
      </span>
      <Field
        className={`${errors[name] && touched[name] && 'field-error'}`}
        name={name}
        {...props}
      />
    </label>
    {
      isTooltip && (
        <div className="tooltip-wrapper">
          <img data-tip data-for="tooltip" src={tooltipIcon} alt="" className="tooltip-icon"/>
          <ReactTooltip className="tooltip" id="tooltip" border effect="solid" type="light">
            <Paragraph>{tooltipMessage}</Paragraph>
          </ReactTooltip>
        </div>
      )
    }
  </InputField>
);

InputFieldComponent.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  isTooltip: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  tooltipIcon: PropTypes.string,
  tooltipMessage: PropTypes.string,
  touched: PropTypes.object.isRequired,
  type: PropTypes.string,
};

InputFieldComponent.defaultProps = {
  autoComplete: 'off',
  className: '',
  disabled: false,
  isTooltip: false,
  label: '',
  maxLength: '',
  onChange: null,
  placeholder: '',
  required: false,
  tooltipIcon: null,
  tooltipMessage: '',
  type: 'text',
};

export default InputFieldComponent;
