import React from 'react';
import { Switch } from 'react-router';
import {
  Redirect,
  Route,
} from 'react-router-dom';
import PrivateRoute from '../utils/privateRoute';
import PublicRoute from '../utils/publicRoute';

import Join from '../screens/Join';
import Home from '../screens/Home';
import Login from '../screens/Login';
import MyProfile from '../screens/MyProfile';
import ResetPassword from '../screens/ResetPassword';

const Routes = () => (
  <Switch>
    <PrivateRoute
      path="/my-profile"
      component={MyProfile}
    />
    <PublicRoute
      exact
      path="/join-the-ccca"
      component={Join}
    />
    <Route
      exact
      path="/home"
      component={Home}
    />
    <Route
      exact
      path="/reset-password/:token?"
      component={ResetPassword}
    />
    <PublicRoute
      exact
      path="/login"
      component={Login}
    />
    <Redirect exact from="/" to="/my-profile" />
    <Redirect to="/page-not-found" />
  </Switch>
);
export default Routes;
