const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST';
const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
const CREATE_MEMBER_FAIL = 'CREATE_MEMBER_FAIL';

const RESET_STATE = 'RESET_STATE';

const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
const VALIDATE_EMAIL_FAIL = 'VALIDATE_EMAIL_FAIL';

export default {
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAIL,

  RESET_STATE,

  VALIDATE_EMAIL_REQUEST,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAIL,
};
