import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NavLink,
} from 'react-router-dom';
import MobileHeader from './styles';

import { simpleLogo } from '../../../../assets/images';
import links from '../../../../utils/links';

import SubMenu from './SubMenu';

import {
  authSelectors,
} from '../../../../store/ducks/auth';

const MobileHeaderComponent = ({
  isNotificationClosed,
  token,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileHeader isNotificationClosed={isNotificationClosed}>
      <ul className="burger-tabs">
        <li className="burger-tab">
          <a href={links.mainDomain}>
            <img alt="Classic Car Club of America" src={simpleLogo} />
          </a>
        </li>
        <li className="burger-tab">
          <button className="burger-icon" onClick={() => setIsOpen(!isOpen)} type="button">
            <div className="burger-line" />
            <div className="burger-line" />
            <div className="burger-line" />
            <div className="burger-line" />
          </button>
        </li>
      </ul>
      <div className={`full-width-navbar ${isOpen && 'visible'}`}>
        <ul className="tabs">
          <SubMenu
            href={links.vehicles}
            title="CLASSIFIEDS"
            items={[
              {
                href: links.vehicles,
                title: 'VEHICLES',
              }, {
                href: links.classifiedAdSubmission,
                title: 'CLASSIFIED AD SUBMISSION',
              }, {
                href: links.classifiedsFAQ,
                title: 'CLASSIFIEDS FAQ',
              },
            ]}
          />
          <SubMenu
            href={links.ourClub}
            title="ABOUT"
            items={[
              {
                href: links.ourClub,
                title: 'OUR CLUB',
              }, {
                href: links.approvedClassics,
                title: 'APPROVED CLASSICS',
              }, {
                href: links.biographies,
                title: 'BIOGRAPHIES',
              }, {
                href: links.museum,
                title: 'MUSEUM',
              }, {
                href: links.educationalFoundation,
                title: 'EDUCATIONAL FOUNDATION',
              }, {
                href: links.news,
                title: "NEWS",
              },
            ]}
          />
          <SubMenu
            href={links.events}
            title="EVENTS"
            items={[
              {
                href: links.events,
                title: 'CCCA EVENTS',
              }, {
                href: links.concoursEvents,
                title: 'COLLECTOR & CONCOURS EVENTS',
              }
            ]}
          />
          <SubMenu
            className="unstyled"
            href={links.regionalClubs}
            title='REGIONAL CLUBS'
          />
          <SubMenu
            href={links.articles}
            title="RESOURCES"
            items={[
              {
                href: links.membershiphandbook,
                title: 'MEMBERSHIP HANDBOOK',
              }, {
                href: links.caravan,
                title: 'CARAVAN HANDBOOK',
              }, {
                href: links.joyOfTouring,
                title: 'JOY OF TOURING'
              }, {
                href: links.judging,
                title: 'JUDGING',
              }, {
                href: links.articles,
                title: 'ARTICLES',
              }, {
                href: links.relatedLinks,
                title: 'RELATED LINKS',
              },
            ]}
          />
          <SubMenu
            href={links.contactUs}
            title="CONTACT"
            items={[
              {
                href: links.contactUs,
                title: 'CONTACT US',
              }, {
                href: links.advertise,
                title: 'ADVERTISE',
              },
            ]}
          />
          <SubMenu
            className="unstyled"
            href={links.store}
            title="STORE"
          />
          <li className="tab unstyled">
            {
              token
                ? <NavLink to={links.myProfile} activeClassName="active-tab">MY PROFILE</NavLink>
                : <NavLink to={links.login} exact activeClassName="active-tab">SIGN IN</NavLink>
            }
          </li>
          <li className="tab unstyled">
            <NavLink to={links.join} activeClassName="active-tab">JOIN</NavLink>
          </li>
        </ul>
      </div>
    </MobileHeader>
  );
};

MobileHeaderComponent.propTypes = {
  isNotificationClosed: PropTypes.bool.isRequired,
  token: PropTypes.string,
};

MobileHeaderComponent.defaultProps = {
  token: '',
};

const mapStateToProps = state => ({
  token: authSelectors.selectToken(state),
});

export default connect(
  mapStateToProps,
  null,
)(MobileHeaderComponent);
