import { action } from '../../../utils/serializer';
import { authError } from '../../../utils/errorHandle';
import { renew } from '../../../services/api';
import types from './actionTypes';

const getRenewalData = () => (dispatch) => {
  dispatch(action(types.GET_RENEWAL_MEMBER_REQUEST));

  renew.getRenewalData()
    .then(res => dispatch(action(types.GET_RENEWAL_MEMBER_SUCCESS, res)))
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.GET_RENEWAL_MEMBER_FAIL, err));
    });
};

const handleRenewal = () => (dispatch) => {
  dispatch(action(types.SET_RENEWAL_MODAL_VISIBILITY));
};

const handleRenewConfirmation = () => (dispatch) => {
  dispatch(action(types.SET_RENEWAL_CONFIRMATION_MODAL_VISIBILITY));
};

const updateMemberInfo = data => (dispatch) => {
  dispatch(action(types.UPDATE_RENEWAL_MEMBER_REQUEST));

  return renew.updateMemberInfo(data)
    .then((res) => {
      dispatch(action(types.UPDATE_RENEWAL_MEMBER_SUCCESS, res));

      return { status: 'success', params: res.data };
    })
    .catch((err) => {
      if (dispatch(authError(err))) return;

      dispatch(action(types.UPDATE_RENEWAL_MEMBER_FAIL, err));

      return { status: 'fail' };
    });
};

export default {
  getRenewalData,
  handleRenewal,
  handleRenewConfirmation,
  updateMemberInfo,
};
