import styled from 'styled-components';
import {
} from '../../../assets/theme/themeVariables';

const RegionalAdministratorForm = styled.div`
  margin-top: 20px;

  .select-input {
    max-width: 300px;
  }

  .row {
    margin-bottom: 20px;
  }

  .btn {
    margin: 35px 0;
  }
`;

export default RegionalAdministratorForm;
