import styled from 'styled-components';

import {
  gray5,
  gray7,
  primaryColor,
  white,
} from '../../../assets/theme/themeVariables';

const DatePicker = styled.div`
  width: 100%;

  label {
    font-weight: bold;
    color: ${gray7};
    display: flex;
    flex-direction: column;
  }

  .react-date-picker {

    &__wrapper {
      height: 45px;
      margin-top: 5px;
      padding: 0 15px;
      border: none;
      background-color: ${white};
      font-weight: normal;
      color: ${gray5};
    }

    input {
      font-weight: normal;
      color: ${gray5};

      &:invalid {
        background-color: initial;
      }
    }

    &__button {
      &:focus,
      &:hover {
        .react-date-picker__button__icon {
          stroke: ${primaryColor} !important;
        }
      }
    }

    svg {
      stroke: ${gray5};
    }
  }

  .react-calendar {
    color: ${gray5};

    &__tile {
      &--active {
        background-color: ${primaryColor};

        &:focus,
        &:hover {
          background-color: ${gray5} !important;
        }
      }
    }
  }
`;

export default DatePicker;
