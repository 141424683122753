import styled from 'styled-components';

const Step1 = styled.div`
  display: flex;

  .form-content {
    padding-right: 40px;

    .title {
      margin-bottom: 10px;
    }

    .form {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    .form-content {
      padding-right: 0;
      border-right: none;
    }

    .basic-info {
      display: none;
    }
  }
`;

export default Step1;
