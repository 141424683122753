import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { concatArray } from '../../../../utils/serializer';
import SearchMemberProfile from './styles';

import {
  H4,
  Link,
  Paragraph,
} from '../../../../components/common/Text';
import Loader from '../../../../components/common/Loader';

import {
  searchMembersActions,
  searchMembersSelectors,
} from '../../../../store/ducks/searchMembers';
import {
  vehiclesActions,
  vehiclesSelectors,
} from '../../../../store/ducks/vehicles';

const returnBooleanValue = bool => (bool ? 'Yes' : 'No');

const SearchMemberProfilePage = ({
  currentMember,
  getMakes,
  getMember,
  loading,
  makes,
  match: { params: { memberNo } },
  requestErrors,
}) => {
  useEffect(() => {
    getMember(memberNo);
  }, [getMember, memberNo]);

  useEffect(() => {
    if (!makes) {
      getMakes(makes);
    }
  }, [getMakes, makes]);

  const renderRow = useCallback((title, values = [], className) => (
    <div className={`row ${className}`}>
      <Paragraph className="title mr-5">{title}</Paragraph>
      {values.length
        ? (
          <div className="values">
            {values.map((value, i) => (
              <Paragraph key={i} className="value">{value || '-'}</Paragraph>
            ))}
          </div>
        ) : (
          <Paragraph className="value">-</Paragraph>
        )}
    </div>
  ), []);

  const renderComponent = useCallback(() => {
    if (Object.keys(requestErrors).length) {
      return <Paragraph className="not-found-text">Not Found</Paragraph>;
    }

    if (!currentMember) {
      return null;
    }

    const {
      addressLine1,
      addressLine2,
      cellPhone,
      city,
      duesExpire,
      email,
      firstName,
      lastName,
      nationalPlanName,
      regionalMemberships,
      residentialPhone,
      state,
      vehicles,
      yearJoined,
      zipCode,
      associate,
    } = currentMember;

    return (
      <div className="container">
        <div className="member-container">
          <H4 className="title">Member Info</H4>
          <div className="member-info">
            {renderRow('Member Number:', [memberNo])}
            {renderRow('Name:', [`${firstName} ${lastName}`])}
            {renderRow('Email:', [<Link externalLink href={`mailto:${email}`}>{email}</Link>])}
            {renderRow('Address:', [addressLine1, addressLine2, zipCode, city, state], 'column')}
            {renderRow('Member Type:', [nationalPlanName])}
            {renderRow('Home:', [residentialPhone])}
            {renderRow('Cell:', [cellPhone])}
            {renderRow('Year Joined:', [yearJoined])}
            {renderRow('Paid Through:', [(duesExpire || '').slice(0, 4)])}
            {renderRow('Associate:', [associate])}
            {renderRow('Region(s):', [concatArray(regionalMemberships) || '-'], 'column')}
          </div>
          <div className="link">
            <Link href="/my-profile/search/member">« Return to Search</Link>
          </div>
        </div>
        <div className="cars">
          {(vehicles || []).map((car) => {
            const carMake = makes.find(e => e.id === car.makeId).name || '';
            return (
              <div key={car.id} className="car-container">
                <H4 className="title">{`${car.year} ${carMake} ${car.model}`}</H4>
                <div className="car-info">
                  {renderRow('Car Number:', [car.id])}
                  {renderRow('Owned By:', [(car.ownedByAssociate ? 'Associate' : 'Member')])}
                  {renderRow('Make:', [carMake])}
                  {renderRow('Model:', [car.model])}
                  {renderRow('Year:', [car.year])}
                  {renderRow('Cylinders:', [car.numberOfCylinders])}
                  {renderRow('Body Style:', [car.bodyStyle])}
                  {renderRow('Engine Number:', [car.engineNumber])}
                  {renderRow('Body Number:', [car.bodyNumber])}
                  {renderRow('Serial Number:', [car.serialNumber])}
                  {renderRow('Senior Number:', [car.seniorNumber])}
                  {renderRow('New Coach Work:', [returnBooleanValue(car.newCoachWork)])}
                  {renderRow('Altered:', [returnBooleanValue(car.altered)])}
                  <Paragraph>Car is believed to be authentic</Paragraph>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [currentMember, makes, memberNo, renderRow, requestErrors]);

  return (
    <SearchMemberProfile>
      {loading
        ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          renderComponent()
        )}
    </SearchMemberProfile>
  );
};

SearchMemberProfilePage.propTypes = {
  currentMember: PropTypes.object,
  getMakes: PropTypes.func.isRequired,
  getMember: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  makes: PropTypes.array,
  match: PropTypes.object.isRequired,
  requestErrors: PropTypes.object,
};

SearchMemberProfilePage.defaultProps = {
  currentMember: null,
  loading: false,
  makes: null,
  requestErrors: {},
};

const mapStateToProps = state => ({
  currentMember: searchMembersSelectors.selectCurrentMember(state),
  loading: searchMembersSelectors.selectLoadingMember(state),
  makes: vehiclesSelectors.selectMakes(state),
  requestErrors: searchMembersSelectors.selectCurrentMemberErrors(state),
});

const mapDispatchToProps = {
  getMakes: vehiclesActions.getMakes,
  getMember: searchMembersActions.getMember,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMemberProfilePage);
