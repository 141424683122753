import React, { useMemo, useState } from 'react';
import {
  NavLink,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import MobileHeader from './styles';

import Button from '../../common/Button';
import { H1 } from '../../common/Text/styles';


const MobileHeaderComponent = ({
  location,
  regionalAdministrator,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const currTabName = useMemo(() => (
    (location.pathname.split('/')[2] || '')
      .replace(/-/g, ' ')
      .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
  ), [location.pathname]);

  const renderTabs = (
    <>
      <ul className="tabs">
        <li className="tab">
          <NavLink to="/my-profile/member-info" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            MEMBER INFO
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/membership-details" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            MEMBERSHIP DETAILS
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/my-vehicles" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            MY VEHICLES
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/my-events" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            EVENT REGISTRATIONS
          </NavLink>
        </li>
        <li className={`tab ${!regionalAdministrator && 'hide'}`}>
          <NavLink to="/my-profile/regional-administrator" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            REGIONAL REPORTS
          </NavLink>
        </li>
        <li className={`tab ${!regionalAdministrator && 'hide'}`}>
          <NavLink to="/my-profile/regional-administrator-member-search" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            REGIONAL MEMBER SEARCH
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/search/member" activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            MEMBER SEARCH
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/search/vehicle" activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            VEHICLE SEARCH
          </NavLink>
        </li>
        <li className="tab">
          <NavLink to="/my-profile/member-resources" exact activeClassName="active-tab" onClick={() => setIsOpen(!isOpen)}>
            MEMBER RESOURCES
          </NavLink>
        </li>
      </ul>
    </>
  );

  return (
    <MobileHeader>
      <div className="text-wrapper">
        <button className="mobile-tabs-btn" onClick={() => setIsOpen(!isOpen)} type="button">
          <H1>
            {'My Profile: '}
            <span>
              {currTabName}
              <div className="burger-icon">
                <div className="burger-line" />
                <div className="burger-line" />
                <div className="burger-line" />
                <div className="burger-line" />
              </div>
            </span>
          </H1>
        </button>
        <div className={`mobile-tabs-wrapper ${isOpen && 'show'}`}>
          {renderTabs}
        </div>
        <div className={`separator`} />
      </div>
    </MobileHeader>
  );
};

MobileHeaderComponent.propTypes = {
  location: PropTypes.object.isRequired,
  regionalAdministrator: PropTypes.bool,
};

MobileHeaderComponent.defaultProps = {
  regionalAdministrator: false,
};

export default withRouter(MobileHeaderComponent);
