import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { formErrorParser } from '../../../utils/errorHandle';
import { passwordValidation } from '../../../utils/regExp';
import ChangePassword from './style';

import Button from '../../../components/common/Button';
import PasswordField from '../../../components/common/PasswordField';

import {
  authActions,
  authSelectors,
} from '../../../store/ducks/auth';

const ChangePasswordComponent = ({
  changePassword,
  loading,
  requestErrors,
}) => {
  const onSubmit = useCallback((fields, { resetForm }) => {
    changePassword(fields).then((status) => {
      if (status === 'success') {
        resetForm();
      }
    });
  }, [changePassword]);

  return (
    <ChangePassword>
      <Formik
        initialValues={{
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={
        Yup.object()
          .shape({
            currentPassword: Yup.string()
              .required('Current Password required'),
            password: Yup.string()
              .required('New Password required')
              .notOneOf([Yup.ref('currentPassword'), null], 'Input into "Current Password" and "New Password" fields cannot match. Please correct and re-submit.')
              .matches(passwordValidation, 'New Password does not meet security requirements. Password must be at least ten characters with at least one number, upper-case letter and lower-case letter.'),
            passwordConfirmation: Yup.string()
              .required('Confirm Password is required')
              .oneOf([Yup.ref('password'), null], 'Passwords do not Match: Please Try again.'),
          })
      }
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-wrapper">
              <div className="form">
                <PasswordField
                  autoComplete="new-password"
                  className="current-password"
                  errors={errors}
                  label="Current Password"
                  name="currentPassword"
                  touched={touched}
                />
                <div className="new-password-container">
                  <PasswordField
                    autoComplete="new-password"
                    errors={errors}
                    label="New Password"
                    name="password"
                    touched={touched}
                  />
                  <PasswordField
                    autoComplete="new-password"
                    errors={errors}
                    label="Confirm Password"
                    name="passwordConfirmation"
                    touched={touched}
                  />
                </div>
              </div>
              <div className="errors">
                {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
                {formErrorParser(requestErrors).map(e => <p key={e} className="error">{e}</p>)}
              </div>
              <div className="btn-wrapper">
                <Button
                  loading={loading}
                  title="Change Password"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ChangePassword>
  );
};

ChangePasswordComponent.propTypes = {
  changePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  requestErrors: PropTypes.object,
};

ChangePasswordComponent.defaultProps = {
  loading: false,
  requestErrors: {},
};

const mapStateToProps = state => ({
  loading: authSelectors.selectLoadingChangePassword(state),
  requestErrors: authSelectors.selectErrorsChangePassword(state),
});

const mapDispatchToProps = {
  changePassword: authActions.changePassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordComponent);
