import styled from 'styled-components';
import {
  gray4,
  gray5,
  primaryColor,
  primaryFont,
  white,
} from '../../../assets/theme/themeVariables';

const Button = styled.button`
  min-height: 45px;
  padding: 0 30px;
  font-family: ${primaryFont};
  font-weight: bold;
  font-size: 16px;

  &.border-less {
    padding: 0 30px;
    color: ${gray5};
  }

  &.primary {
    background-color: ${primaryColor};
    color: ${white};
  }

  &.secondary {
    border: 2px solid ${primaryColor};
    color: ${primaryColor};
  }

  &.secondary-gray {
    border: 2px solid ${gray5};
    color: ${gray5};
  }

  &.gray {
    background-color: ${gray4};
    color: ${white};
    border: none;
  }
`;

export default Button;
