import styled from 'styled-components';

const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
  }

  @media screen and (max-width: 420px) {
    .buttons-wrapper {
      flex-direction: column;
    }
  }
`;

export default ConfirmationModal;
