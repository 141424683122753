import styled from 'styled-components';

const PaypalButton = styled.div`
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payment-area {
    h3 {
      margin-bottom: 20px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error {
      text-align: center;
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 70px;
      margin-bottom: 20px;
    }

    .btn {
      width: 150px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
`;

export default PaypalButton;
