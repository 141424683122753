import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkboxes from './styles';
import { transformToPrice } from '../../../../utils/serializer';

const CheckBoxesComponent = ({
  data,
  item,
  onChange,
  values,
}) => {
  const {
    associateCost,
    cost,
    id,
    title,
  } = item;
  const currValue = values.find(e => e.regionalId === id);

  const onChangeAssociate = useCallback((e) => {
    const newValue = e.target.checked;
    const plans = values.map((el) => {
      if (el.regionalId === id) {
        return { regionalId: id, associate: newValue };
      }
      return el;
    });
    onChange(plans);
  }, [
    id,
    onChange,
    values,
  ]);

  const onChangePlan = useCallback((e) => {
    const newValue = e.target.checked;
    let plans = values;

    if (newValue) {
      plans = [
        ...plans,
        { regionalId: id, associate: Boolean(data.associate) },
      ];
    } else {
      plans = values.filter(el => (el.regionalId !== id));
    }

    onChange(plans);
  }, [
    data.associate,
    id,
    onChange,
    values,
  ]);

  const renderAssociate = useCallback(() => {
    if (data.associate && (currValue || {}).regionalId) {
      return (
        <div className="wrapper">
          <div className="container">
            <input
              type="checkbox"
              checked={Boolean((currValue || {}).associate)}
              onChange={onChangeAssociate}
            />
            <div className="text-wrapper">
              <p>Add Associate</p>
              <span>{`${transformToPrice(associateCost)} / year`}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [
    associateCost,
    currValue,
    data.associate,
    onChangeAssociate,
  ]);

  return (
    <Checkboxes>
      <div className="upper-wrapper">
        <div className="container">
          <input
            type="checkbox"
            checked={Boolean((currValue || {}).regionalId)}
            onChange={onChangePlan}
          />
          <div className="text-wrapper">
            <p>{title}</p>
            <span>{`${transformToPrice(cost)} / year`}</span>
          </div>
        </div>
      </div>
      {renderAssociate()}
    </Checkboxes>
  );
};

CheckBoxesComponent.propTypes = {
  data: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

export default CheckBoxesComponent;
