import styled from 'styled-components';
import {
  gray5,
  gray7,
} from '../../../assets/theme/themeVariables';

const VehicleForm = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding-left: 40px;
  border-left: 1px solid ${gray5};

  .row {
    display: flex;
    flex-direction: row;

    .select-input {
      margin: 0;
      margin-bottom: 10px;
    }

    .text-input {
      margin: 0;
      margin-bottom: 20px;
    }

    .half-input {
      max-width: 50%;
      padding-right: 5px;
      box-sizing: border-box;
    }

    .checkbox-input {
      width: 100%;
      margin-bottom: 20px;
      color: ${gray7};
    }

    .first-child {
      margin-right: 10px;
    }
  }

  .field-description {
    margin-bottom: 20px;
    font-size: 12px;
    color: ${gray5};
  }

  .btn {
    margin-top: 20px;
  }

  @media screen and (max-width: 1400px) {
    max-width: 400px;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 50px;
    max-width: 500px;
    padding-left: 0;
    border-left: none;
  }

  @media screen and (max-width: 520px) {
    .row {
      flex-direction: column;
    }
  }
`;

export default VehicleForm;
