// font variables
export const fontWeight = {
  thin: 100,
  light: 200,
  book: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  heavy: 800,
};

export const primaryFont = "'komet', sans-serif";
export const titleFont = "'acumin pro extra condensed', sans-serif";

// color variables
export const transparent = 'transparent';
export const black = '#000000';
export const white = '#ffffff';

export const gray0 = '#efefef';
export const gray1 = '#d6d6d6';
export const gray2 = '#d8d8d8';
export const gray3 = '#a2a2a2';
export const gray4 = '#999999';
export const gray5 = '#878787';
export const gray6 = '#666666';
export const gray7 = '#4d4d4d';

export const primaryColor = '#d11342';
export const secondaryColor = '#900c2d';
export const backgroundColor = '#f5f5f5';
export const secondaryBackgroundColor = '#ebebeb';
