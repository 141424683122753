import styled from 'styled-components';
import {
  backgroundColor,
  black,
} from '../../../assets/theme/themeVariables';

const MembershipDetails = styled.div`
  .wrapper {
    background-color: ${backgroundColor};
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin-bottom: 10px;
    color: ${black};
    line-height: unset;
  }

  @media screen and (max-width: 991px) {
    .wrapper {
      background-color: ${backgroundColor};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 720px) {
    .wrapper {
      padding: 0;
    }
  }
`;

export default MembershipDetails;
