import moment from 'moment';

const isObject = o => o === Object(o) && !Array.isArray(o) && typeof o !== 'function';

const checkAndMapArray = (item, checker, cb) => {
  if (Array.isArray(item)) {
    return item.map(el => (checker(el) ? cb(el) : el));
  }
  return item;
};

export const action = (type, payload = {}) => ({ type, payload });

export const concatArray = arr => (arr || []).reduce((acc, elem) => (!acc ? elem : `${acc}, ${elem}`), '');

export const generateId = () => Math.random().toString(36).substr(2, 9);

export const transformToPrice = sum => `$${(sum / 100).toFixed(2)}`;

export const getTokenFromStorage = () => localStorage.getItem('token') || sessionStorage.getItem('token') || '';

export const camelToSnake = (camelObj) => {
  if (!camelObj) return null;

  const snakeObj = {};
  Object.keys(camelObj).forEach((oldKey) => {
    const newKey = oldKey.replace(/([A-Z])/g, '_$1').toLowerCase();
    const value = isObject(camelObj[oldKey])
      ? camelToSnake(camelObj[oldKey]) : checkAndMapArray(camelObj[oldKey], isObject, camelToSnake);
    snakeObj[newKey] = value;
  });
  return snakeObj;
};

export const objectToFormData = (obj, wrapKey) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      return Object.values(obj[key]).map(el => el && formData.append(`${wrapKey}[${key}][]`, el));
    }
    formData.append(`${wrapKey}[${key}]`, obj[key]);
  });

  return formData;
};

export const snakeToCamel = (snakeObj) => {
  if (!snakeObj) return null;

  const camelObj = {};
  Object.keys(snakeObj).forEach((oldKey) => {
    const newKey = oldKey.replace(/_(\w)/g, (arr => arr[1].toUpperCase()));
    const value = isObject(snakeObj[oldKey])
      ? snakeToCamel(snakeObj[oldKey]) : checkAndMapArray(snakeObj[oldKey], isObject, snakeToCamel);
    camelObj[newKey] = value;
  });
  return camelObj;
};

export const transformDateToMoment = (date) => {
  if (date) {
    return moment(date, 'YYYY-MM-DD');
  }
  return null;
};

export const transformMomentToRequestFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export const creatDateInterval = (start, end = null) => {
  const momentStart = transformDateToMoment(start);
  const momentEnd = transformDateToMoment(end);

  if (!end || start === end) {
    return momentStart.format('MMMM DD, YYYY');
  }

  // check whether the year and month of the start date and end date are the same
  if (start.slice(0, 7) === end.slice(0, 7)) {
    return `${momentStart.format('MMMM DD')} - ${momentEnd.format('DD, YYYY')}`;
  }

  return `${momentStart.format('MMMM DD')} - ${momentEnd.format('MMMM DD, YYYY')}`;
};

export const strippedAfterChar = (str, char) => str.substring(0, str.indexOf(char)).trim();
