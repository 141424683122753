import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formErrorParser } from '../../../utils/errorHandle';
import { transformToPrice } from '../../../utils/serializer';
import ReviewSection from './styles';

import {
  H5,
  Paragraph,
} from '../../common/Text';
import Table from '../../Table';
import Button from '../../common/Button';

import {
  plansSelectors,
} from '../../../store/ducks/plans';

const ReviewSectionComponent = ({
  data,
  loading,
  nationalPlans,
  onChange,
  onSubmit,
  regionalPlans,
  requestErrors,
  prevStep,
}) => {
  const priceList = useMemo(() => {
    const currNationalPlan = (nationalPlans || []).find(e => e.id === +data.nationalPlan);
    const currRegionalPlans = (regionalPlans || [])
      .reduce((arr, curr) => {
        const planVal = data.regionalPlans
          .find(el => curr.id === el.regionalId);
        return planVal ? [...arr, { ...curr, associate: planVal.associate }] : arr;
      }, []);

    const donates = [
      ...(data.educationalFoundation
        ? [{
          title: 'The Classic Car Club of America Education Foundation donate',
          cost: data.educationalFoundationAmount,
          isNotPlan: true,
        }] : []),
      ...(data.museum
        ? [{
          title: 'The Classic Car Club of America Museum donate',
          cost: data.museumAmount,
          isNotPlan: true,
        }] : []),
    ];

    const museumMembership = [
      ...(data.museumMembershipLevel > 0
        ? [{
          title: 'The Classic Car Club of America Museum Membership',
          cost: data.museumMembershipLevel * 100,
          isNotPlan: true,
        }] : []),
    ];

    return [
      { ...currNationalPlan, isNationalPlan: true },
      ...currRegionalPlans,
      ...donates,
      ...museumMembership,
    ];
  }, [
    data.educationalFoundationAmount,
    data.educationalFoundation,
    data.museum,
    data.museumAmount,
    data.nationalPlan,
    data.regionalPlans,
    nationalPlans,
    regionalPlans,
    data.museumMembershipLevel,
  ]);

  const removeRegionPlan = useCallback((id, isAssociate = false) => {
    let newRegionalPlans;

    if (isAssociate) {
      newRegionalPlans = data.regionalPlans.map(e => (
        (e.regionalId === id) ? { ...e, associate: false } : e
      ));
    } else {
      newRegionalPlans = data.regionalPlans.filter(e => e.regionalId !== id);
    }

    onChange({
      ...data,
      regionalPlans: newRegionalPlans,
    });
  }, [data, onChange]);

  const countTotalPrice = useCallback(() => {
    const arrSum = priceList.map((e) => {
      const { cost } = e; // general plan cost
      if (e.isNationalPlan && data.associate) { // national associate cost
        return cost + ((e.associatePlan || {}).cost || 0);
      }
      if (e.associate && data.associate) { // regional associate cost
        return cost + e.associateCost;
      }
      return cost;
    });
    return arrSum.reduce((a, b) => a + b, 0);
  }, [data.associate, priceList]);

  const renderAssociatePlanRow = useCallback((row) => {
    const {
      id,
      associate,
      associatePlan,
      isNationalPlan,
    } = row.original;

    if (isNationalPlan && !data.associate) return null;
    if (!isNationalPlan && !associate) return null;
    if (isNationalPlan && !associatePlan) return null;

    const titleText = isNationalPlan ? associatePlan.title : 'Associate Plan';

    return (
      <React.Fragment key={row.index}>
        <div className="separator" />
        <div className="item associate">
          <H5>{titleText}</H5>
          {!isNationalPlan && (
            <button
              className="remove-btn"
              onClick={() => removeRegionPlan(id, true)}
              type="button"
            >
              &gt; Remove
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }, [data.associate, removeRegionPlan]);

  const renderAssociatePriceRow = useCallback((row) => {
    const {
      associate,
      associateCost,
      associatePlan,
      isNationalPlan,
    } = row.original;

    if (isNationalPlan && !data.associate) return null;
    if (!isNationalPlan && !associate) return null;
    if (isNationalPlan && !associatePlan) return null;

    const cost = isNationalPlan ? associatePlan.cost : associateCost;

    return (
      <React.Fragment key={row.index}>
        <div className="separator" />
        <H5 className="price-text associate">
          {transformToPrice(cost)}
        </H5>
      </React.Fragment>
    );
  }, [data.associate]);

  const renderRow = useCallback((row) => {
    const {
      id,
      isNationalPlan,
      isNotPlan,
      title,
    } = row.original;

    const isRegionalPlan = !isNationalPlan && !isNotPlan;

    const titleText = isRegionalPlan ? `Regional Membership: ${title}` : title;

    return (
      <div className="item">
        <H5>{titleText}</H5>
        {isRegionalPlan && (
          <button
            className="remove-btn"
            onClick={() => removeRegionPlan(id)}
            type="button"
          >
            &gt; Remove
          </button>
        )}
      </div>
    );
  }, [removeRegionPlan]);

  const renderPriceRow = useCallback((row) => {
    const { cost } = row.original;

    return (
      <H5 className="price-text">
        {transformToPrice(cost)}
      </H5>
    );
  }, []);

  const totalPrice = countTotalPrice();

  const columns = [
    {
      Header: <Paragraph className="table-header">Item</Paragraph>,
      Cell: row => (
        <div className="row">
          {renderRow(row)}
          {renderAssociatePlanRow(row)}
        </div>
      ),
    },
    {
      Header: <Paragraph className="table-header">Item price</Paragraph>,
      accessor: 'itemPrice',
      className: 'item-price-row',
      width: 80,
      Cell: row => (
        <div className="item-price">
          {renderPriceRow(row)}
          {renderAssociatePriceRow(row)}
        </div>
      ),
    },
  ];

  return (
    <ReviewSection>
      <Table columns={columns} data={priceList} />
      <div className="errors">
        {formErrorParser(requestErrors).map(e => <p key={e} className="error">{e}</p>)}
      </div>
      <div className="button-wrapper">
        <div>
          <Button
            loading={loading}
            onClick={() => onSubmit((totalPrice / 100).toFixed(2))}
            title="Checkout"
          />
          <Button
            onClick={prevStep}
            styleType="border-less"
            title="Back"
          />
        </div>
        <H5 className="total-price">
          <span>GRAND TOTAL:</span>
          <span>{transformToPrice(totalPrice)}</span>
        </H5>
      </div>
    </ReviewSection>
  );
};

ReviewSectionComponent.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  nationalPlans: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  regionalPlans: PropTypes.array.isRequired,
  requestErrors: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
};

ReviewSectionComponent.defaultProps = {
  loading: false,
};

const mapStateToProps = state => ({
  nationalPlans: plansSelectors.selectNationalPlans(state),
  regionalPlans: plansSelectors.selectRegionalPlans(state),
});

export default connect(
  mapStateToProps,
  null,
)(ReviewSectionComponent);
