import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AvailableEventsList from './styles';

import {
  HighlightedText,
  Paragraph,
} from '../common/Text';
import EventCard from './EventCard';
import Loader from '../common/Loader';

import {
  eventsActions,
  eventsSelectors,
} from '../../store/ducks/events';

const DESCRIPTION = 'Select an event from the list below to begin the registration process and check back often as  events may be added or updated.';
const NOTE = 'Note: You may register a 2nd vehicle for an event that allows by submitting the form multiple times.'
const EMPTY_LIST_MSG = 'No available events found';

const AvailableEventsListComponent = ({
  events,
  getAllEvents,
  loadingEvents,
  loadingRegisteredEvents,
  registeredEvents,
}) => {
  useEffect(() => {
    if (!events) {
      getAllEvents();
    }
  }, [
    events,
    getAllEvents,
  ]);

  const renderList = useCallback(() => {
    let filteredEvents = [];

    if (events && registeredEvents) {
      filteredEvents = events.filter(event => (
        !registeredEvents.find(registeredEvent => (event.formId === registeredEvent.event.formId))
      ));
    }

    return (
      (events != null && events.length) ? (
        <div className="list">
          {
            events.map(event => (
              <EventCard
                key={event.formId}
                {...event}
              />
            ))
          }
        </div>
      ) : (
        <Paragraph className="no-items">{EMPTY_LIST_MSG}</Paragraph>
      )
    );
  }, [events, registeredEvents]);

  return (
    <AvailableEventsList>
      <HighlightedText className="title">AVAILABLE EVENTS</HighlightedText>
      <Paragraph className="description">{DESCRIPTION}</Paragraph>
      <Paragraph className="description note">{NOTE}</Paragraph>
      {
        loadingEvents || loadingRegisteredEvents
          ? <div className="loader"><Loader /></div>
          : renderList()
      }
    </AvailableEventsList>
  );
};

AvailableEventsListComponent.propTypes = {
  events: PropTypes.array,
  getAllEvents: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool,
  loadingRegisteredEvents: PropTypes.bool,
  registeredEvents: PropTypes.array,
};

AvailableEventsListComponent.defaultProps = {
  events: null,
  loadingEvents: false,
  loadingRegisteredEvents: false,
  registeredEvents: null,
};

const mapStateToProps = state => ({
  events: eventsSelectors.selectEvents(state),
  loadingEvents: eventsSelectors.selectLoadingEvents(state),
  loadingRegisteredEvents: eventsSelectors.selectLoadingRegisteredEvents(state),
  registeredEvents: eventsSelectors.selectRegisteredEvents(state),
});

const mapDispatchToProps = {
  getAllEvents: eventsActions.getAllEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailableEventsListComponent);
