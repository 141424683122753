import styled from 'styled-components';

const RenewalButton = styled.div``;
export const  ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
`;

export default RenewalButton;
