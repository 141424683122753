import styled from 'styled-components';
import {
  gray0,
  gray7,
  primaryColor,
  white,
} from '../../assets/theme/themeVariables';

const SearchResults = styled.div`
  margin-top: 60px;

  .loader-container {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination {
    display: flex;

    li {
      width: 45px;
      height: 45px;
      margin-left: 1px;
      background-color: ${white};

      a {
        width: 100%;
        height: 100%;
        color: ${gray7};
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        a {
          color: ${primaryColor};
        }
      }

      &.disabled {
        background-color: ${gray0};
        a {
          cursor: default;
        }
      }
    }
  }
`;

export default SearchResults;
