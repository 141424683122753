import styled from 'styled-components';
import {
  black,
  gray4,
  gray5,
  gray6,
  primaryColor,
  white,
} from '../../../../assets/theme/themeVariables';

const Checkbox = styled.div`
  display: flex;
  flex-direction: column;

  .upper-wrapper {
    width: auto;
    background-color: ${white};
    min-width: 167px;
  }

  .wrapper {
    width: auto;
    background-color: ${white};
    min-width: 167px;
    margin-top: 20px;
  }

  div {
    > input {
      width: 20px;
      position: relative;
      padding-left: 20px;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: -1px;
        width: 20px;
        height: 20px;
        background: ${white};
        border: 1px solid ${gray4};
      }

      &:after {
        content: 'Г';
        position: absolute;
        top: -7px;
        left: 6px;
        font-size: 18px;
        font-family: sans-serif;
        font-weight: 500;
        color: ${gray5};
        transform: rotate(225deg);
      }
    }

    > input:not(:checked) {
      &:after {
        opacity: 0;
      }
    }

    > input:disabled:not(:checked) {
      &:before {
        box-shadow: none;
        cursor: default;
      }
    }

    > input:checked {
      &:after {
        opacity: 1;
      }
    }

    > input:disabled:checked {
      &:after {
        color: ${black};
        cursor: default;
      }
    }
    > input:disabled {
      color: ${black};
      cursor: default;
    }
  }

  p {
    font-size: 14px;
    color: ${primaryColor};
  }

  span {
    font-size: 14px;
    color: ${gray6};
  }

  .container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-wrapper {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
`;

export default Checkbox;
