import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  emailRegExp,
  passwordValidation,
} from '../../../utils/regExp';
import Step2 from './styles';
import { questionMark } from '../../../assets/images';

import Button from '../../../components/common/Button';
import InputField from '../../../components/common/InputField';
import {
  HighlightedText,
  H5,
  Paragraph,
  Link,
} from '../../../components/common/Text';

import {
  subscriptionsActions,
  subscriptionsSelectors,
} from '../../../store/ducks/subscriptions';
import PasswordFieldComponent from '../../../components/common/PasswordField/index';

const EMAIL_VALIDATION_TEXT1 = 'This email address is already associated with a CCCA member profile. To log into your existing account or to reset your password, please click here';
const EMAIL_VALIDATION_TEXT2 = 'To contact us for assistance, please click here';
const USERNAME_TOOLTIP_MESSAGE = 'Your log-in for the CCCA member portal will be your email address.';

const Step2Component = ({
  data,
  emailIsValid,
  emailValidation,
  handleNextStep,
  loadingValidateEmail,
  prevStep,
}) => {
  const [emailChanged, setEmailChanged] = useState(false);

  const validateEmail = useCallback(
    debounce(email => emailValidation({ email }), 2000),
    [emailValidation],
  );

  useEffect(() => emailValidation({ email: data.email }), [data.email, emailValidation]);
  useEffect(() => {
    if (loadingValidateEmail) {
      setEmailChanged(false);
    }
  }, [loadingValidateEmail]);

  const onChangeEmail = useCallback((e) => {
    const email = e.target.value;
    setEmailChanged(true);
    validateEmail(email);
  }, [validateEmail]);

  return (
    <Step2>
      <HighlightedText className="title">STEP 2: CREATE PASSWORD</HighlightedText>
      <Formik
        initialValues={{
          email: data.email || '',
          password: data.password || '',
          passwordConfirmation: data.passwordConfirmation || '',
        }}
        validationSchema={
          Yup.object().shape({
            email: Yup.string()
              .required('Email is required')
              .matches(emailRegExp, 'Wrong email format'),
            password: Yup.string()
              .required('Password is required')
              .matches(passwordValidation, 'Password does not meet security requirements. Password must be at least ten characters with at least one number, upper-case letter and lower-case letter.'),
            passwordConfirmation: Yup.string()
              .required('Confirm Password is required')
              .oneOf([Yup.ref('password'), null], 'Input into "password" and "confirm password" fields does not match. Please correct and re-submit.'),
          })
        }
        onSubmit={handleNextStep}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-fields">
              <div className="email-wrapper">
                <InputField
                  autoComplete="on"
                  errors={{ email: !emailIsValid, ...errors }}
                  isTooltip
                  label="Email"
                  name="email"
                  onChange={onChangeEmail}
                  required
                  tooltipIcon={questionMark}
                  tooltipMessage={USERNAME_TOOLTIP_MESSAGE}
                  touched={{ email: true, ...touched }}
                  type="email"
                />
              </div>
              <div className="password-wrapper">
                <PasswordFieldComponent
                  autoComplete="new-password"
                  errors={errors}
                  label="Password"
                  name="password"
                  touched={touched}
                />
                <PasswordFieldComponent
                  autoComplete="new-password"
                  errors={errors}
                  label="Confirm Password"
                  name="passwordConfirmation"
                  touched={touched}
                />
              </div>
            </div>
            <div className="instructions">
              <H5>PASSWORD REQUIREMENTS</H5>
              <H5>Password must contain:</H5>
              <Paragraph>1. at least 10 characters</Paragraph>
              <Paragraph>2. at least one number</Paragraph>
              <Paragraph>3. at least one lower-case letter</Paragraph>
              <Paragraph>4. at least one upper-case letter</Paragraph>
            </div>
            <div className="errors">
              {Object.keys(errors).map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
              {!emailIsValid && (
              <p className="error">
                {`${EMAIL_VALIDATION_TEXT1}`}
                <Link href="/login">Login.</Link>
                {EMAIL_VALIDATION_TEXT2}
                <Link externalLink href="https://www.classiccarclub.org/contact-us">Contact form.</Link>
              </p>
              )}
            </div>
            <div className="button-wrapper">
              <Button
                disabled={!emailIsValid || loadingValidateEmail || emailChanged}
                title="SAVE & CONTINUE TO STEP 3"
                type="submit"
              />
              {/* <Button
                onClick={() => {}}
                title="SAVE"
              /> */}
              <Button
                title="BACK"
                styleType="border-less"
                onClick={prevStep}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Step2>
  );
};

Step2Component.propTypes = {
  data: PropTypes.object.isRequired,
  emailIsValid: PropTypes.bool.isRequired,
  emailValidation: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  loadingValidateEmail: PropTypes.bool,
  prevStep: PropTypes.func.isRequired,
};

Step2Component.defaultProps = {
  loadingValidateEmail: false,
};

const mapStateToProps = state => ({
  emailIsValid: subscriptionsSelectors.selectEmailIsValid(state),
  loadingValidateEmail: subscriptionsSelectors.selectLoadingValidateEmail(state),
});

const mapDispatchToProps = {
  emailValidation: subscriptionsActions.validateEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step2Component);
