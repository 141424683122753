import types from './actionTypes';

const initialState = {
  loadingNationalPlans: true,
  loadingRegionalPlans: true,
  nationalPlans: null,
  regionalPlans: null,
};

const plans = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.NATIONAL_PLANS_REQUEST: {
      return {
        ...state,
        loadingNationalPlans: true,
      };
    }
    case types.NATIONAL_PLANS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingNationalPlans: false,
        nationalPlans: data,
      };
    }
    case types.NATIONAL_PLANS_FAIL: {
      return {
        ...state,
        loadingNationalPlans: false,
        nationalPlans: [],
      };
    }
    case types.REGIONAL_PLANS_REQUEST: {
      return {
        ...state,
        loadingRegionalPlans: true,
      };
    }
    case types.REGIONAL_PLANS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loadingRegionalPlans: false,
        regionalPlans: data,
      };
    }
    case types.REGIONAL_PLANS_FAIL: {
      return {
        ...state,
        loadingRegionalPlans: false,
        regionalPlans: [],
      };
    }

    case types.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default plans;
