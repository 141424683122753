import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Step3 from './styles';
import { transformToPrice } from '../../../utils/serializer';
import {
  plansSelectors,
} from '../../../store/ducks/plans';

import { H5, Paragraph } from '../../../components/common/Text';
import AssociateMember from '../../../components/Forms/AssociateMember';

const Step3Component = ({
  data,
  handleNextStep,
  nationalPlans,
  prevStep,
}) => {
  const currPlan = useMemo(() => (
    (nationalPlans || []).find(e => e.id === +data.nationalPlan) || {}
  ), [data.nationalPlan, nationalPlans]);

  const getPlanPrice = useCallback(() => (
    transformToPrice((currPlan.associatePlan || {}).cost || 0)
  ), [currPlan]);

  const handleFormData = useCallback(({
    addAssociate,
    firstName,
    lastName,
  }) => {
    const { regionalPlans } = data;
    let newRegionPlans;
    let associate = null;

    if (addAssociate) {
      associate = { firstName, lastName };
    } else {
      newRegionPlans = (regionalPlans || []).map(e => ({ ...e, associate: false }));
    }

    handleNextStep({
      ...(regionalPlans ? { regionalPlans: newRegionPlans || regionalPlans } : {}),
      associate,
    });
  }, [data, handleNextStep]);

  const messageText = currPlan.lifelong
    ? `A lifetime CCCA member can add an associate member at a national level for a one-time cost of ${getPlanPrice()}.`
    : `CCCA Member can add an associate member at a national level for a cost of ${getPlanPrice()} per year.`;

  return (
    <Step3>
      <H5 className="title">STEP 3: REGISTER ASSOCIATE</H5>
      <Paragraph className="msg-text">
        {`${messageText} Would you like to add an associate member?`}
      </Paragraph>
      <div className="form-container">
        <H5>Add Associate</H5>
        <AssociateMember
          data={data}
          nextButtonTitle="SAVE & CONTINUE TO STEP 4"
          handleNextStep={handleFormData}
          prevStep={prevStep}
        />
      </div>
    </Step3>
  );
};

Step3Component.propTypes = {
  data: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  nationalPlans: PropTypes.array,
  prevStep: PropTypes.func.isRequired,
};

Step3Component.defaultProps = {
  nationalPlans: [],
};

const mapStateToProps = state => ({
  nationalPlans: plansSelectors.selectNationalPlans(state),
});

export default connect(
  mapStateToProps,
  null,
)(Step3Component);
