import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Join from './styles';

import links from '../../utils/links';

import { H1, Link } from '../../components/common/Text';
import Checkout from '../../components/Checkout';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

import {
  plansActions,
  plansSelectors,
} from '../../store/ducks/plans';
import {
  subscriptionsActions,
  subscriptionsSelectors,
} from '../../store/ducks/subscriptions';

const JoinPage = ({
  createMember,
  createMemberErrors,
  getNationalPlans,
  getRegionPlans,
  history,
  loading,
  nationalPlans,
  regionalPlans,
}) => {
  const [data, setData] = useState({});
  const [showPaymentModal, setSetShowPaymentModal] = useState(false);
  const [step, setStep] = useState(1);
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    if (!nationalPlans && !regionalPlans) {
      getNationalPlans();
      getRegionPlans();
    }
  }, [
    getNationalPlans,
    getRegionPlans,
    nationalPlans,
    regionalPlans,
  ]);

  const nextStep = useCallback(() => setStep(step + 1), [step]);
  const prevStep = useCallback(() => setStep(step - 1), [step]);

  const onSubmit = useCallback((totalPriceArg) => {
    createMember(data).then(({ status, params }) => {
      if (status === 'success') {
        var description = 'New member: ' + data['firstName'] + '' + data['lastName'] + ' - ' + data['email'];
        setPaymentData([
          {
            description: description,
            invoice_id: params.orderId,
            amount: {
              currency_code: 'USD',
              value: totalPriceArg || 0,
            },
          },
        ]);
        setSetShowPaymentModal(true);
      }
    });
  }, [createMember, data]);

  const handleNextStep = useCallback((newData) => {
    setData({
      ...data,
      ...newData,
    });

    nextStep();
  }, [data, nextStep]);

  const stepComponent = useCallback((Component, props) => (
    <Component
      {...props}
      data={data}
      prevStep={prevStep}
      handleNextStep={handleNextStep}
    />
  ), [
    data,
    handleNextStep,
    prevStep,
  ]);

  const renderHeader = useCallback(() => (
    <div className="join-header">
      <H1>Join the CCCA</H1>
      <div className="separator" />
    </div>
  ), []);

  const renderStep = useCallback(() => {
    switch (step) {
      case 1: return stepComponent(Step1, {
        onCancel: () => history.replace(links.login),
      });
      case 2: return stepComponent(Step2);
      case 3: return stepComponent(Step3);
      case 4: return stepComponent(Step4);
      case 5: return stepComponent(Step5);
      case 6: return stepComponent(Step6);
      case 7: return stepComponent(Step7, {
        loading,
        onChange: setData,
        onSubmit,
        requestErrors: createMemberErrors,
      });

      default: return null;
    }
  }, [
    createMemberErrors,
    history,
    loading,
    onSubmit,
    step,
    stepComponent,
  ]);

  const successPaymentMessage = (
    <>
      {'Thank you for joining the Classic Car Club of America! Please click '}
      <Link
        href={links.login}
      >
        here
      </Link>
      {' to login to your online account.'}
    </>
  );

  return (
    <Join>
      <Header />
      <div className="content">
        {renderHeader()}
        {renderStep()}
      </div>
      <Footer />
      <Checkout
        additionalData={data}
        callback={() => history.push('/login')}
        isOpen={showPaymentModal}
        onRequestClose={() => setSetShowPaymentModal(!showPaymentModal)}
        paymentData={paymentData}
        successMessage={successPaymentMessage}
      />
    </Join>
  );
};

JoinPage.propTypes = {
  createMember: PropTypes.func.isRequired,
  createMemberErrors: PropTypes.object.isRequired,
  getNationalPlans: PropTypes.func.isRequired,
  getRegionPlans: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  nationalPlans: PropTypes.array,
  regionalPlans: PropTypes.array,
};

JoinPage.defaultProps = {
  loading: false,
  nationalPlans: null,
  regionalPlans: null,
};

const mapStateToProps = state => ({
  createMemberErrors: subscriptionsSelectors.selectCreateMemberErrors(state),
  loading: subscriptionsSelectors.selectLoadingCreate(state),
  nationalPlans: plansSelectors.selectNationalPlans(state),
  regionalPlans: plansSelectors.selectRegionalPlans(state),
});

const mapDispatchToProps = {
  getNationalPlans: plansActions.getNationalPlans,
  getRegionPlans: plansActions.getRegionPlans,
  createMember: subscriptionsActions.createMember,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(JoinPage));
