const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
const GET_MEMBER_FAIL = 'GET_MEMBER_FAIL';

const MEMBERSHIP_DETAILS_REQUEST = 'MEMBERSHIP_DETAILS_REQUEST';
const MEMBERSHIP_DETAILS_SUCCESS = 'MEMBERSHIP_DETAILS_SUCCESS';
const MEMBERSHIP_DETAILS_FAIL = 'MEMBERSHIP_DETAILS_FAIL';

const RESET_STATE = 'RESET_STATE';

const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
const UPDATE_MEMBER_FAIL = 'UPDATE_MEMBER_FAIL';

export default {
  GET_MEMBER_REQUEST,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAIL,

  MEMBERSHIP_DETAILS_REQUEST,
  MEMBERSHIP_DETAILS_SUCCESS,
  MEMBERSHIP_DETAILS_FAIL,

  RESET_STATE,

  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
};
