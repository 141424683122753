import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generateId } from '../../../utils/serializer';
import MemberInfo from './style';

import MembersInfoForm from '../../../components/Forms/MembersInfoForm';
import RenewalButton from '../../../components/RenewalButton';
import {
  H5,
  Paragraph,
} from '../../../components/common/Text';

import {
  memberActions,
  memberSelectors,
} from '../../../store/ducks/member';

const BENEFITS_TEXT_1 = 'The CCCA includes many historians, journalists and students as members. While the club believes ownership of a Classic  automobile enhances club membership, it is not necessary to own a Classic to be a significant contributing member of CCCA. We embrace individuals who share our interests, appreciate the camaraderie and who support the goals of the club.';
const BENEFITS_TEXT_2 = 'The CCCA and its regions offer the Classic car enthusiast a unique opportunity to not only enjoy these special automobiles but to maintain old friendships while meeting new friends within the hobby.';

const MemberInfoComponent = ({
  memberInfo,
  updateMemberErrors,
  updateMemberInfo,
}) => {
  const [memberFormId, setMemberFormId] = useState(generateId());

  useEffect(() => {
    setMemberFormId(generateId());
  }, [memberInfo]);

  const onSubmit = useCallback(fields => (
    updateMemberInfo(memberInfo.id, fields)
  ), [memberInfo, updateMemberInfo]);

  return (
    <MemberInfo>
      <div className="form-wrapper">
        <MembersInfoForm
          data={memberInfo || {}}
          id={memberFormId}
          isRenderModal
          isProfile
          onSubmit={onSubmit}
          requestErrors={updateMemberErrors}
        />
      </div>

      <div className="renew-my-membership">
        <RenewalButton />
        <div className="text-wrapper">
          <H5 className="title">THE BENEFITS OF YOUR MEMBERSHIP</H5>
          <Paragraph className="first-paragraph">{BENEFITS_TEXT_1}</Paragraph>
          <Paragraph>{BENEFITS_TEXT_2}</Paragraph>
        </div>
      </div>
    </MemberInfo>
  );
};

MemberInfoComponent.propTypes = {
  memberInfo: PropTypes.object,
  updateMemberErrors: PropTypes.object,
  updateMemberInfo: PropTypes.func.isRequired,
};

MemberInfoComponent.defaultProps = {
  memberInfo: null,
  updateMemberErrors: {},
};

const mapStateToProps = state => ({
  memberInfo: memberSelectors.selectMemberInfo(state),
  updateMemberErrors: memberSelectors.selectUpdateMemberErrors(state),
});

const mapDispatchToProps = {
  updateMemberInfo: memberActions.updateMemberInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberInfoComponent);
