import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import {
  creatDateInterval,
  strippedAfterChar,
} from '../../../utils/serializer';
import EventCard from './styles';

import {
  H4,
  Link,
  Paragraph,
} from '../../common/Text';

import SelectField from '../../common/Select';

import {
  memberSelectors,
} from '../../../store/ducks/member';

import {
  vehiclesSelectors,
} from '../../../store/ducks/vehicles';

const EventCardComponent = ({
  dateEnd,
  dateStart,
  formId,
  memberInfo,
  regionName,
  title,
  vehicles,
  makes,
}) => {
  const [customUrl, setCustomUrl] = useState('');
  const formik = useFormik({
    initialValues: {
      vehicle: null,
    },
  });

  const make = useCallback(makeId => (
      ((makes || []).find(e => e.id === makeId) || {}).name || ''
  ), []);

  const vehicleOptions = useCallback(() => (
    vehicles.map(v => ({
      label: `${v.year} ${make(v.makeId)} ${v.model}${v.serialNumber ? ` : ${v.serialNumber}` : ''}`,
      value: v,
    }))
  ), [make, vehicles]);

  const populatingFieldsToUrl = useCallback(() => {
    const formURL = `https://form.jotformeu.com/${formId}`;

    if (!memberInfo) {
      return formURL;
    }

    const vehicleData = formik.values.vehicle ? {
      year: formik.values.vehicle.year,
      make: make(formik.values.vehicle.makeId),
      model: formik.values.vehicle.model,
      bodyStyle: formik.values.vehicle.bodyStyle,
      bodyMaker: formik.values.vehicle.bodyMaker,
      serialNumber: formik.values.vehicle.serialNumber,
      engineNumber: formik.values.vehicle.engineNumber,
      seniorNumber: formik.values.vehicle.seniorNumber,
      cylinders: formik.values.vehicle.numberOfCylinders,
    } : {};

    const populatedData = {
      email: memberInfo.email,
      'memberAddress[addr_line1]': memberInfo.addressLine1,
      'memberAddress[addr_line2]': memberInfo.addressLine2,
      'memberAddress[city]': memberInfo.city,
      'memberAddress[postal]': memberInfo.zipCode,
      'memberAddress[state]': memberInfo.state,
      'memberName[first]': memberInfo.firstName,
      'memberName[last]': memberInfo.lastName,
      memberId: memberInfo.id,
      memberNo: memberInfo.memberNo,
      ...vehicleData,
    };

    const transformedData = Object.keys(populatedData)
      .reduce((str, key) => {
        const value = populatedData[key];
        return !value ? str : str ? `${str}&${key}=${value}` : `${key}=${value}`;
      }, '');

    setCustomUrl(encodeURI(`${formURL}?${transformedData}`));
  }, [formId, formik.values.vehicle, make, memberInfo]);

  useEffect(() => {
    populatingFieldsToUrl();
  }, [populatingFieldsToUrl, formik.values.vehicle]);

  return (
    <EventCard>
      <div className="info-block">
        <Paragraph className="additional-info">
          {creatDateInterval(dateStart, dateEnd)}
        </Paragraph>
        <H4 className="title">{strippedAfterChar(title, '[')}</H4>
        <Paragraph className="additional-info">
          {regionName}
        </Paragraph>
      </div>
      <div className="btns-block">
        <SelectField
          label="Select Vehicle"
          name="vehicle"
          placeholder="None"
          options={vehicleOptions()}
          values={formik.values}
          onChange={formik.setFieldValue}
        />
      </div>
      <div className="btns-block">
        <Link
          externalLink
          href={customUrl}
        >
          Register
        </Link>
      </div>
    </EventCard>
  );
};

EventCardComponent.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  formId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  memberInfo: PropTypes.object,
  regionName: PropTypes.string,
  title: PropTypes.string,
  vehicles: PropTypes.array,
  makes: PropTypes.array,
};

EventCardComponent.defaultProps = {
  dateEnd: '',
  dateStart: '',
  memberInfo: null,
  regionName: '',
  title: '',
  vehicles: [],
  makes: null,
};

const mapStateToProps = state => ({
  memberInfo: memberSelectors.selectMemberInfo(state),
  vehicles: vehiclesSelectors.selectVehicles(state),
  makes: vehiclesSelectors.selectMakes(state),
});


export default connect(
  mapStateToProps,
  null,
)(EventCardComponent);
