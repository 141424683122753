import styled from 'styled-components';
import {
  gray0,
} from '../../../assets/theme/themeVariables';

const EmailVerification = styled.div`
  .form-wrapper {
    margin-bottom: 60px;

    .form {
      max-width: 570px;
      display: flex;
      flex-direction: column;

      input {
        max-width: 330px;
        margin-bottom: 10px;
        background-color: ${gray0};
      }
    }

    .errors {
      padding-top: 20px;
    }

    .btn-wrapper {
      margin: 20px 0;
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    form {
      width: inherit;
    }
  }
`;

export default EmailVerification;
