import { getTokenFromStorage } from '../../../utils/serializer';
import types from './actionTypes';

const initialState = {
  detailInfo: null,
  errorsChangePassword: {},
  errorLogin: {},
  errorsResetPassword: {},
  loadingChangePassword: false,
  loadingLogin: false,
  loadingResetPassword: false,
  token: getTokenFromStorage(),
};

const auth = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        errorsChangePassword: {},
        loadingChangePassword: true,
      };
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loadingChangePassword: false,
      };
    }
    case types.CHANGE_PASSWORD_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        errorsChangePassword: data || { error: 'Internet connection error' },
        loadingChangePassword: false,
      };
    }
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        errorLogin: {},
        loadingLogin: true,
      };
    }
    case types.LOGIN_SUCCESS: {
      const {
        data,
        headers,
        rememberNextTime,
      } = payload;
      const token = headers.authorization.replace(/Bearer /gi, '');

      if (rememberNextTime) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }

      return {
        ...state,
        detailInfo: data,
        loadingLogin: false,
        token,
      };
    }
    case types.LOGIN_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        errorLogin: data || { error: 'Internet connection error' },
        loadingLogin: false,
      };
    }
    case types.NEW_PASSWORD_ON_RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        errorsResetPassword: {},
        loadingResetPassword: true,
      };
    }
    case types.NEW_PASSWORD_ON_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loadingResetPassword: false,
      };
    }
    case types.NEW_PASSWORD_ON_RESET_PASSWORD_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        errorsResetPassword: data || { error: 'Internet connection error' },
        loadingResetPassword: false,
      };
    }
    case types.SEND_EMAIL_ON_RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        errorsResetPassword: {},
        loadingResetPassword: true,
      };
    }
    case types.SEND_EMAIL_ON_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loadingResetPassword: false,
      };
    }
    case types.SEND_EMAIL_ON_RESET_PASSWORD_FAIL: {
      const { data } = (payload.response || {});

      return {
        ...state,
        errorsResetPassword: data || { error: 'Internet connection error' },
        loadingResetPassword: false,
      };
    }

    case types.RESET_STATE: {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');

      return {
        ...initialState,
        token: '',
      };
    }

    default:
      return state;
  }
};

export default auth;
