import React from 'react';
import PropTypes from 'prop-types';
import NotificationModal from './styles';

import { Paragraph } from '../Text';
import Button from '../Button';
import Modal from '../Modal';

const defaultCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    width: '90%',
    maxWidth: '750px',
    height: 'max-content',
    minHeight: '150px',
    padding: '32px',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
};

const NotificationModalComponent = ({
  customStyles,
  confirmButton,
  children,
  isOpen,
  onRequestClose,
}) => (
  <Modal
    customStyles={{ ...defaultCustomStyles, ...customStyles }}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <NotificationModal>
      <Paragraph>
        {children}
      </Paragraph>
      <div className="buttons-wrapper">
        <Button
          title={confirmButton}
          onClick={onRequestClose}
        />
      </div>
    </NotificationModal>
  </Modal>
);

NotificationModalComponent.propTypes = {
  confirmButton: PropTypes.string,
  customStyles: PropTypes.object,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onRequestClose: PropTypes.func.isRequired,
};

NotificationModalComponent.defaultProps = {
  confirmButton: 'Confirm',
  customStyles: {},
  children: '',
  isOpen: false,
};

export default NotificationModalComponent;
