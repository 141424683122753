import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authSelectors } from '../store/ducks/auth';

const PrivateRoute = ({
  component: Component,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (token ? (
      <Redirect to="/my-profile" />
    ) : (
      <Component {...props} {...rest}/>
    ))}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  token: authSelectors.selectToken(state),
});

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute);
