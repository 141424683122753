import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectField from './style';
import {
  gray0,
  gray3,
  primaryColor,
  white,
} from '../../../assets/theme/themeVariables';

const SelectFieldComponent = ({
  className,
  defaultValue,
  disabled,
  errors,
  isLabel,
  label,
  loading,
  name,
  options,
  onChange,
  placeholder,
  required,
  touched,
  values,
}) => (
  <SelectField className={className}>
    <label htmlFor={name}>
      {isLabel && (
        <span className="label">
          {label.toUpperCase()}
          {required && <span className="required-mark">*</span>}
        </span>
      )}
      <Select
        className="select-control"
        defaultValue={defaultValue}
        isDisabled={disabled}
        isLoading={loading}
        name={name}
        onChange={option => onChange(name, option.value)}
        options={options}
        isClearable={false}
        menuColor="red"
        placeholder={placeholder}
        value={options.find(e => e.value === values[name]) || null}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: gray3,
            primary20: gray3,
            primary25: gray0,
          },
        })}
        styles={{
          control: base => ({
            ...base,
            backgroundColor: disabled ? gray0 : white,
            borderColor: primaryColor,
            borderWidth: errors[name] && touched[name] ? '1px' : 0,

            '&:hover': {
              borderColor: 'none',
            },
          }),
          menu: base => ({
            ...base,
            color: '#878787',
          }),
          singleValue: base => ({
            ...base,
            color: '#878787',
          }),
          option: base => ({
            ...base,
          }),
          dropdownIndicator: base => ({
            ...base,
            color: primaryColor,
          }),
          indicatorSeparator: () => ({
          }),
          valueContainer: base => ({
            ...base,
            height: 45,
          }),
        }}
      />
    </label>
  </SelectField>
);

SelectFieldComponent.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  isLabel: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.object,
  values: PropTypes.object,
};

SelectFieldComponent.defaultProps = {
  className: '',
  defaultValue: null,
  disabled: false,
  errors: {},
  isLabel: true,
  label: '',
  loading: false,
  options: [],
  placeholder: 'Search...',
  required: false,
  touched: {},
  values: {},
};

export default SelectFieldComponent;
