import styled from 'styled-components';
import {
  gray4,
} from '../../assets/theme/themeVariables';

const Renewal = styled.div`
  width: 100%;
  height: max-content;
  margin-bottom: 20px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;

    h1 {
      line-height: 1;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: ${gray4};
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

export default Renewal;
