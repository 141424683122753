import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from './store';
import history from './history';
import GlobalFonts from './assets/theme/fonts';
import GlobalStyles from './assets/theme/global';

import BrowserCompatibilityChecker from './components/BrowserCompatibilityChecker';
import NotificationPopup from './components/common/NotificationPopup';
import Routes from './components/Routes';

// console.log('.env file: ', process.env);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <GlobalFonts />
      <GlobalStyles />
      <NotificationPopup />

      <BrowserCompatibilityChecker>
        <Routes />
      </BrowserCompatibilityChecker>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
