import styled from 'styled-components';

import { gray5 } from '../../assets/theme/themeVariables';

const RegisteredEventsList = styled.div`
  width: 100%;
  min-width: 50%;
  margin-right: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .no-items,
  .loader {
    height: 100%;
    min-height: 200px;
    color: ${gray5};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .note {
    font-family: italic;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }
`;

export default RegisteredEventsList;
