import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CheckboxField from './styles';

const CheckboxFieldComponent = ({
  errors,
  label,
  name,
  ...props
}) => {
  const id = `${name} ${new Date().getTime()}`;

  return (
    <CheckboxField
      error={errors[name]}
      {...props}
    >
      <Field
        component={({ field, type }) => (
          <div>
            <input className="input" id={id} {...field} type={type} />
            <label className="label" htmlFor={id}>
              {label}
            </label>
          </div>
        )}
        name={name}
        type="checkbox"
      />
    </CheckboxField>
  );
};

CheckboxFieldComponent.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(['gray', 'white']),
};

CheckboxFieldComponent.defaultProps = {
  className: '',
  errors: {},
  label: '',
  styleType: 'gray',
};

export default CheckboxFieldComponent;
