const selectAssociateMemberships = state => state.member.associateMemberships;
const selectAssociateName = state => state.member.associateName;
const selectLifelong = state => state.member.lifelong;
const selectMemberInfo = state => state.member.memberInfo;
const selectMemberName = state => state.member.memberName;
const selectNationalMembership = state => state.member.nationalMembership;
const selectRegionalAdministrator = state => state.member.regionalAdministrator;
const selectRegionalMemberships = state => state.member.regionalMemberships;
const selectRenewalDate = state => (state.member.nationalMembership || {}).renewalDate;
const selectRenewalWindow = state => state.member.renewalWindow;
const selectUpdateMemberErrors = state => state.member.updateMemberErrors;

export default {
  selectAssociateMemberships,
  selectAssociateName,
  selectLifelong,
  selectMemberInfo,
  selectMemberName,
  selectNationalMembership,
  selectRegionalAdministrator,
  selectRegionalMemberships,
  selectRenewalDate,
  selectRenewalWindow,
  selectUpdateMemberErrors,
};
