import styled from 'styled-components';
import {
  gray7,
} from '../../../assets/theme/themeVariables';

const RegionalAdministrator = styled.div`
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;

  h4 {
    margin-bottom: 20px;
    color: ${gray7};
  }

  @media screen and (max-width: 720px) {
    padding: 0 30px;
  }
`;

export default RegionalAdministrator;
