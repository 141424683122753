import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import {
  Redirect,
  Route,
} from 'react-router-dom';
import MyProfileWrapper from './style';

import ChangePassword from './ChangePassword';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import MemberInfo from './MemberInfo';
import MemberResources from './MemberResources';
import MembershipDetails from './MembershipDetails';
import Modal from '../../components/common/Modal';
import MyEvents from './MyEvents';
import MyProfileTabs from '../../components/MyProfileTabs';
import MyVehicles from './MyVehicles';
import RegionalAdministrator from './RegionalAdministrator';
import Renewal from '../Renewal';
import Search from './Search';
import RegionalAdministratorMemberSearch from './RegionalAdministratorMemberSearch';

import {
  memberActions,
  memberSelectors,
} from '../../store/ducks/member';
import {
  renewalActions,
  renewalSelectors,
} from '../../store/ducks/renewal';

const CONFIRM_MSG = 'Thank you for choosing to renew your CCCA Membership. We will walk you step-by-step through this process on the following screens. For you convenience, we have auto-populated your prior selections when applicable. You must complete the application in one sitting, or you will need to start from the beginning upon your return to the page';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    padding: '62px',
    top: 'auto',
    left: '0',
    right: 'auto',
    bottom: '0',
    width: '100%',
    height: 'calc(100vh - 100px)',
    boxSizing: 'border-box',
    display: 'flex',
  },
};

const renderContent = ({
  regionalAdministrator,
}) => (
  <Switch>
    <Route
      path="/my-profile/change-password"
      component={ChangePassword}
    />
    <Route
      path="/my-profile/member-info"
      component={MemberInfo}
    />
    <Route
      path="/my-profile/member-resources"
      component={MemberResources}
    />
    <Route
      path="/my-profile/membership-details"
      component={MembershipDetails}
    />
    <Route
      path="/my-profile/my-events"
      component={MyEvents}
    />
    <Route
      path="/my-profile/my-vehicles"
      component={MyVehicles}
    />
    {regionalAdministrator
      ? (
        <Route
          path="/my-profile/regional-administrator"
          component={RegionalAdministrator}
        />
      ) : (
        null
      )}

    {regionalAdministrator
      ? (
        <Route
          path="/my-profile/regional-administrator-member-search"
          component={RegionalAdministratorMemberSearch}
        />
      ) : (
        null
      )}
    <Route
      path="/my-profile/search"
      component={Search}
    />
    <Redirect to="/my-profile/member-info" />
  </Switch>
);

renderContent.propTypes = {
  regionalAdministrator: PropTypes.bool.isRequired,
};

const MyProfilePage = ({
  getMember,
  getMembershipDetails,
  isRenewalOpen,
  isRenewalConfirmation,
  handleRenewal,
  handleRenewalConfirmation,
  regionalAdministrator,
}) => {
  useEffect(() => {
    getMember();
    getMembershipDetails();
  }, [getMember, getMembershipDetails]);

  return (
    <MyProfileWrapper>
      <Header />
      <MyProfileTabs />
      {renderContent({ regionalAdministrator })}
      <Footer />
      <Modal
        customStyles={customStyles}
        isCloseBtn
        isOpen={isRenewalOpen}
        onRequestClose={handleRenewal}
      >
        <Renewal />
      </Modal>
      <ConfirmationModal
        cancelButton="Return later"
        confirmButton="Continue"
        isOpen={isRenewalConfirmation}
        message={CONFIRM_MSG}
        onSubmit={handleRenewal}
        onRequestClose={handleRenewalConfirmation}
      />
    </MyProfileWrapper>
  );
};

MyProfilePage.propTypes = {
  getMember: PropTypes.func.isRequired,
  getMembershipDetails: PropTypes.func.isRequired,
  isRenewalOpen: PropTypes.bool,
  isRenewalConfirmation: PropTypes.bool,
  handleRenewal: PropTypes.func.isRequired,
  handleRenewalConfirmation: PropTypes.func.isRequired,
  regionalAdministrator: PropTypes.bool,
};

MyProfilePage.defaultProps = {
  isRenewalOpen: false,
  isRenewalConfirmation: false,
  regionalAdministrator: false,
};


const mapStateToProps = state => ({
  isRenewalOpen: renewalSelectors.selectIsRenewalOpen(state),
  isRenewalConfirmation: renewalSelectors.selectIsRenewalConfirmation(state),
  regionalAdministrator: memberSelectors.selectRegionalAdministrator(state),
});

const mapDispatchToProps = {
  getMember: memberActions.getMemberInfo,
  getMembershipDetails: memberActions.getMembershipDetails,
  handleRenewal: renewalActions.handleRenewal,
  handleRenewalConfirmation: renewalActions.handleRenewConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyProfilePage);
