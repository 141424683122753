import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  checkIfOnlyDigits,
  nonzeroNumber,
} from '../../../utils/regExp';
import GivingOpportunities from './styles';

import links from '../../../utils/links';

import {
  H4,
  H5,
  Link,
  Paragraph,
} from '../../common/Text';
import Button from '../../common/Button';
import CheckboxField from '../../common/CheckboxField';
import InputField from '../../common/InputField';
import SelectField from '../../common/Select';

const EDUCATION_FOUNDATION_DESCRIPTION_1 = 'Please join your friends and supporters of the CCCA Education Foundation in making a generous gift for the purpose of preserving the legacy of the Classic Era and Full Classic®️ Automobiles that we may share this rich history of the Classic Era with the public.';
const EDUCATION_FOUNDATION_DESCRIPTION_2 = 'Contributions for the CCCA Education Foundation are welcome as a one-time gift, a pledge payable over five years, appreciated securities or as part of your estate planning. The gifts can be made in your name, in commemoration of someone that you’d like to honor or as a memorial to a loved one or friend. Many special naming opportunities are available or one that particularly appeals to you can be created.';
const EDUCATION_FOUNDATION_DESCRIPTION_3 = 'The CCCA Education Foundation is organized as a not-for-profit Illinois corporation and is recognized by the Internal Revenue Service as a public, tax-exempt foundation under Section 501(c)(3) of the Internal Revenue Code. Your contributions to the Foundation should be tax-deductible from your federal income tax.';

const EDUCATION_FOUNDATION_LINK_MESSAGE = 'Click Here for More Information on Giving Opportunities to the CCCA Education Foundation';

const MUSEUM_DESCRIPTION_1 = 'The Classic Car Club of America Museum is the CCCA\'s physical face to the world. Its magnificent collection of Full Classics, mascots (one of the largest and finest in the world) and memorabilia is on display on the campus of the Gilmore Automobile Museum in Hickory Corners, MI. The Gilmore has recently been named the most popular tourist attraction in Michigan, with annual attendance approaching 150,000. The Museum also regularly displays cars from its collection at leading car shows and concours events throughout the United States and Canada, serving as ambassadors to the hobby and the public while promoting both the CCCA and the Museum.';
const MUSEUM_DESCRIPTION_2 = 'The Museum maintains an extensive research library and has conserved, digitized and made available historically important archives from coachbuilders like Judkins and Derham and from Classic Era designers, Raymond Dietrich and Gordon Buerig. The Museum has also digitized every issue of the Classic Car magazine and the Bulletin and made them available in a searchable format on the CCCA website as well as the Museum\'s.';
const MUSEUM_DESCRIPTION_3 = 'Your membership in the Museum and tax deductible contribution will allow the Museum to continue to fulfill its primary mission for the last 35 years: to preserve and display these fine automobiles which we define as Full Classics for the education and enjoyment of the public for decades to come.';

const MUSEUM_LINK_MESSAGE = 'Click Here for More Information on Contributing to the CCCA Museum';

const SELECT_OPTIONS = [
  { label: 'None', value: 0 },
  { label: '$50 - Regular', value: 50 },
  { label: '$100 - Sustaining', value: 100 },
  { label: '$500 - Heritage', value: 500 },
  { label: '$1000 - Life', value: 1000 },
];

const GivingOpportunitiesComponent = ({
  buttonSkipText,
  buttonText,
  data,
  loading,
  handleNextStep,
  prevStep,
  renewalProcess,
}) => {
  const onSubmit = useCallback(({ isMembershipLevel, ...fields }) => {
    const {
      educationalFoundation,
      educationalFoundationAmount,
      museum,
      museumAmount,
      museumMembershipLevel,
    } = fields;
    const newFields = {
      ...fields,
      educationalFoundationAmount: educationalFoundation ? educationalFoundationAmount * 100 : null,
      museumAmount: museum ? museumAmount * 100 : null,
      museumMembershipLevel: isMembershipLevel ? museumMembershipLevel : 0,
    };
    handleNextStep(newFields);
  }, [handleNextStep]);

  return (
    <GivingOpportunities>
      <Formik
        initialValues={{
          educationalFoundation: data.educationalFoundation || false,
          educationalFoundationAmount: data.educationalFoundationAmount / 100 || '',
          isMembershipLevel: Boolean(data.museumMembershipLevel),
          museumMembershipLevel: data.museumMembershipLevel || 0,
          museum: data.museum || false,
          museumAmount: data.museumAmount / 100 || '',
        }}
        validationSchema={
          Yup.object()
            .shape({
              educationalFoundation: Yup.boolean(),
              educationalFoundationAmount: Yup.string()
                .when('educationalFoundation', {
                  is: educationalFoundation => Boolean(educationalFoundation),
                  then: Yup.string()
                    .required('\'In the Amount of\' field is required')
                    .max(6, '\'In the Amount of\' field may not exceed 6 characters')
                    .matches(checkIfOnlyDigits, '\'In the Amount of\' must be an integer')
                    .matches(nonzeroNumber, '\'In the Amount of\' can not be zero'),
                }),
              isMembershipLevel: Yup.boolean(),
              museumMembershipLevel: Yup.string()
                .when('isMembershipLevel', {
                  is: isMembershipLevel => Boolean(isMembershipLevel),
                  then: Yup.string()
                    .required('Membership Level field is required')
                    .notOneOf(['None'], 'Membership Level field is required'),
                }),
              museum: Yup.boolean(),
              museumAmount: Yup.string()
                .when('museum', {
                  is: museum => Boolean(museum),
                  then: Yup.string()
                    .required('\'In the Amount of\' field is required')
                    .max(6, '\'In the Amount of\' field may not exceed 6 characters')
                    .matches(checkIfOnlyDigits, '\'In the Amount of\' must be an integer')
                    .matches(nonzeroNumber, '\'In the Amount of\' can not be zero'),
                }),
            })
        }
        onSubmit={onSubmit}
      >
        {({
          errors,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form>
            <div className="section">
              <H4 className="mb-15">Classic Car Club of America Education Foundation</H4>
              <H5 className="mb-5">Your personal support for the Classic Car Club of America Education Foundation</H5>
              <Paragraph className="mb-10">{EDUCATION_FOUNDATION_DESCRIPTION_1}</Paragraph>
              <Paragraph className="mb-10">{EDUCATION_FOUNDATION_DESCRIPTION_2}</Paragraph>
              <H5 className="mb-5">Make a Contribution Now to the CCCA Education Foundation</H5>
              <Paragraph>{EDUCATION_FOUNDATION_DESCRIPTION_3}</Paragraph>

              <div className="fields">
                <CheckboxField
                  className="checkbox"
                  label="I would like to make a contribution to the Classic Car Club of America Education Foundation"
                  name="educationalFoundation"
                  styleType="white"
                />
                <InputField
                  className="text-input"
                  disabled={!values.educationalFoundation}
                  errors={errors}
                  label="In the Amount of"
                  name="educationalFoundationAmount"
                  required={values.educationalFoundation}
                  touched={touched}
                  type="number"
                />
              </div>
              <Link
                externalLink
                href={links.donations}
              >
                {EDUCATION_FOUNDATION_LINK_MESSAGE}
              </Link>
            </div>

            <div className="section">
              <H4 className="mb-15">Classic Car Club of America Museum</H4>
              <H5 className="mb-5">Your personal contribution and/or membership to the Classic Car Club of America Museum</H5>
              <Paragraph className="mb-10">{MUSEUM_DESCRIPTION_1}</Paragraph>
              <Paragraph className="mb-10">{MUSEUM_DESCRIPTION_2}</Paragraph>
              <Paragraph className="mb-10">{MUSEUM_DESCRIPTION_3}</Paragraph>

              <div className="fields">
                <CheckboxField
                  className="checkbox"
                  label={`I would like to ${renewalProcess ? 'renew my' : 'sign-up for a'} CCCA Museum membership`}
                  name="isMembershipLevel"
                  styleType="white"
                />
                <SelectField
                  className="select"
                  disabled={!values.isMembershipLevel}
                  errors={errors}
                  label="Choose a Membership Level"
                  name="museumMembershipLevel"
                  options={SELECT_OPTIONS}
                  onChange={setFieldValue}
                  required={values.isMembershipLevel}
                  touched={touched}
                  values={values}
                />
                <CheckboxField
                  className="checkbox"
                  label="I would like to make a tax-deductible contribution to the Classic Car Club of America Museum"
                  name="museum"
                  styleType="white"
                />
                <InputField
                  className="text-input"
                  disabled={!values.museum}
                  errors={errors}
                  label="In the Amount of"
                  name="museumAmount"
                  required={values.museum}
                  touched={touched}
                  type="number"
                />
              </div>
              <Link
                externalLink
                href={links.museumContributions}
              >
                {MUSEUM_LINK_MESSAGE}
              </Link>

              <Paragraph className="mb-10 mt-15">Choose a donation type(s) and add amount(s) above to continue, or click below to skip.</Paragraph>
            </div>

            <div className="errors">
              {Object.keys(errors)
                .map(e => touched[e] && <p key={e} className="error">{errors[e]}</p>)}
            </div>

            <div className="button-wrapper">
              <Button
                loading={loading}
                title={
                  (values.educationalFoundation || values.isMembershipLevel || values.museum)
                    ? buttonText
                    : buttonSkipText
                }
                type="submit"
              />
              <Button
                onClick={prevStep}
                styleType="border-less"
                title="Back"
              />
            </div>
          </Form>
        )}
      </Formik>
    </GivingOpportunities>
  );
};

GivingOpportunitiesComponent.propTypes = {
  buttonSkipText: PropTypes.string,
  buttonText: PropTypes.string,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleNextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  renewalProcess: PropTypes.bool,
};

GivingOpportunitiesComponent.defaultProps = {
  buttonSkipText: 'Skip at this time',
  buttonText: 'Save Donation & Continue',
  loading: false,
  renewalProcess: false,
};

export default GivingOpportunitiesComponent;
