const selectErrorsChangePassword = state => state.auth.errorsChangePassword;
const selectErrorLogin = state => state.auth.errorLogin;
const selectErrorsResetPassword = state => state.auth.errorsResetPassword;
const selectLoadingChangePassword = state => state.auth.loadingChangePassword;
const selectLoadingLogin = state => state.auth.loadingLogin;
const selectLoadingResetPassword = state => state.auth.loadingResetPassword;
const selectToken = state => state.auth.token;

export default {
  selectErrorsChangePassword,
  selectErrorLogin,
  selectErrorsResetPassword,
  selectLoadingChangePassword,
  selectLoadingLogin,
  selectLoadingResetPassword,
  selectToken,
};
