export const atLeastOneNumber = /\d/;
export const atLeastOneLowerCaseLetter = /[a-z]/;
export const atLeastOneUpperCaseLetter = /[A-Z]/;

export const checkIfOnlyDigits = /^[0-9]*$/;

export const emailRegExp = /^[\w+\-.]+@[a-z\d-]+(\.[a-z\d]+)*\.[a-z]+$/i;

export const nonzeroNumber = /^(?!0)\d+$/;

export const passwordValidation = /^(?!.*[\s])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/;
