import styled from 'styled-components';
import {
  gray1,
  gray3,
  gray7,
  primaryColor,
} from '../../../../assets/theme/themeVariables';

const VehicleSearch = styled.div`
  .vehicle-search-container {
    padding: 0 60px 60px 60px;
  }

  .title {
    color: ${primaryColor};
  }

  .no-results {
    width: 100%;
    margin: 30px 0;
    text-align: center;
    color: ${gray3};
  }

  .sort-buttons {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .sort-btn {
      margin-right: 10px;

      button {
        color: ${gray7};
      }

      img {
        margin-left: 5px;
        opacity: 0;
      }

      &.active-sort {
        button {
          color: ${primaryColor};
        }

        img {
          opacity: 1;
        }
      }
    }
  }

  .list {
    .item {
      padding: 20px 0;
      border-top: 1px solid ${gray1};
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
    }

    .column {
      .underlined {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .vehicle-search-container {
      padding: 0 30px 30px 30px;
    }
  }

  @media screen and (max-width: 576px) {
    .list {
      .item {
        grid-template-columns: initial;
      }
    }
  }
`;

export default VehicleSearch;
