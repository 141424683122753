import React from 'react';
import RegionalAdministrator from './style';

import RegionalAdministratorForm from '../../../components/Forms/RegionalAdministratorForm';
import {
  H4,
  Paragraph,
} from '../../../components/common/Text';

const RegionalAdministratorComponent = () => (
  <RegionalAdministrator>
    <H4>Regional Administrator</H4>
    <Paragraph>Welcome to the Region Administrator page of the CCCA Website!</Paragraph>
    <Paragraph>This page provides you access to member information for your region(s).</Paragraph>
    <RegionalAdministratorForm />
  </RegionalAdministrator>
);

export default RegionalAdministratorComponent;
