import React from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  HighlightedText,
  LinkStyled,
  Paragraph,
} from './styles';

const Link = ({
  children,
  externalLink,
  href,
  ...props
}) => (
  externalLink
    ? (
      <LinkStyled>
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          {...props}
        >
          {children}
        </a>
      </LinkStyled>
    ) : (
      <LinkStyled>
        <RouterLink to={href} {...props}>
          {children}
        </RouterLink>
      </LinkStyled>
    )
);

Link.propTypes = {
  children: PropTypes.any.isRequired,
  externalLink: PropTypes.bool,
  href: PropTypes.string.isRequired,
};

Link.defaultProps = {
  externalLink: false,
};

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  HighlightedText,
  Link,
  Paragraph,
};
