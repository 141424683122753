import administration from './administration';
import auth from './auth';
import common from './common';
import events from './events';
import location from './location';
import member from './member';
import payment from './payment';
import plans from './plans';
import renewal from './renewal';
import searchMembers from './searchMembers';
import searchVehicles from './searchVehicles';
import subscriptions from './subscriptions';
import vehicles from './vehicles';

export default {
  administration,
  auth,
  common,
  events,
  location,
  member,
  payment,
  plans,
  searchMembers,
  searchVehicles,
  subscriptions,
  renewal,
  vehicles,
};
