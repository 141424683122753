import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Renewal from './styles';

import links from '../../utils/links';

import { H1 } from '../../components/common/Text';
import TextButton from '../../components/common/TextButton';
import Checkout from '../../components/Checkout';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

import {
  memberActions,
} from '../../store/ducks/member';
import {
  plansActions,
  plansSelectors,
} from '../../store/ducks/plans';
import {
  renewalActions,
  renewalSelectors,
} from '../../store/ducks/renewal';

const RenewalPage = ({
  getMemberInfo,
  getMembershipDetails,
  getNationalPlans,
  getRegionPlans,
  getRenewalData,
  handleRenewal,
  history,
  loadingMemberInfo,
  loadingUpdateMemberInfo,
  memberInfo,
  nationalPlans,
  regionalPlans,
  updateMemberErrors,
  updateMemberInfo,
}) => {
  const [data, setData] = useState({});
  const [showPaymentModal, setSetShowPaymentModal] = useState(false);
  const [step, setStep] = useState(1);
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    if (!nationalPlans && !regionalPlans) {
      getNationalPlans();
      getRegionPlans();
    }
  }, [
    getNationalPlans,
    getRegionPlans,
    nationalPlans,
    regionalPlans,
  ]);

  useEffect(() => {
    getRenewalData();
  }, [getRenewalData]);

  useEffect(() => {
    if (memberInfo) {
      setData(memberInfo);
    }
  }, [memberInfo]);

  const nextStep = useCallback(() => setStep(step + 1), [step]);
  const prevStep = useCallback(() => setStep(step - 1), [step]);

  const onSubmit = useCallback((totalPriceArg) => {
    updateMemberInfo(data).then(({ status, params }) => {
      if (status === 'success') {
        var description = 'Renew the CCCA: ' + data['firstName'] + '' + data['lastName'] + ' - ' + data['email'];
        setPaymentData([
          {
            description: description,
            invoice_id: params.orderId,
            amount: {
              currency_code: 'USD',
              value: totalPriceArg || 0,
            },
          },
        ]);
        setSetShowPaymentModal(true);
      }
    });
  }, [data, updateMemberInfo]);

  const handleNextStep = useCallback((newData) => {
    setData({
      ...data,
      ...newData,
    });

    nextStep();
  }, [
    data,
    nextStep,
  ]);

  const stepComponent = useCallback((Component, props) => (
    <Component
      {...props}
      data={data}
      prevStep={prevStep}
      handleNextStep={handleNextStep}
    />
  ), [
    data,
    handleNextStep,
    prevStep,
  ]);

  const renderHeader = useCallback(() => (
    <>
      <div className="title-wrapper">
        <H1>CCCA Membership Renewal</H1>
      </div>
      <div className="separator" />
    </>
  ), []);

  const renderStep = useCallback(() => {
    switch (step) {
      case 1: return stepComponent(Step1, { loading: loadingMemberInfo, onCancel: handleRenewal });
      case 2: return stepComponent(Step2);
      case 3: return stepComponent(Step3);
      case 4: return stepComponent(Step4);
      case 5: return stepComponent(Step5);
      case 6: return stepComponent(Step6);
      case 7:
        return stepComponent(
          Step7,
          {
            loading: loadingUpdateMemberInfo,
            onChange: setData,
            onSubmit,
            requestErrors: updateMemberErrors,
          },
        );

      default: return null;
    }
  }, [
    handleRenewal,
    loadingMemberInfo,
    loadingUpdateMemberInfo,
    onSubmit,
    step,
    stepComponent,
    updateMemberErrors,
  ]);

  const successPaymentMessage = (
    <>
      {'Thank you for renewing your membership with the Classic Car Club of America! Please click '}
      <TextButton
        onClick={() => {
          handleRenewal();
          history.push(links.myProfile);
        }}
        title="here"
      />
      {' to return to your member info page.'}
    </>
  );

  return (
    <Renewal>
      {renderHeader()}
      <div className="renew-step">
        {renderStep()}
      </div>
      <Checkout
        callback={handleRenewal}
        isOpen={showPaymentModal}
        onRequestClose={() => setSetShowPaymentModal(!showPaymentModal)}
        onSuccess={() => {
          getMemberInfo();
          getMembershipDetails();
        }}
        paymentData={paymentData}
        successMessage={successPaymentMessage}
      />
    </Renewal>
  );
};

RenewalPage.propTypes = {
  getMemberInfo: PropTypes.func.isRequired,
  getMembershipDetails: PropTypes.func.isRequired,
  getNationalPlans: PropTypes.func.isRequired,
  getRegionPlans: PropTypes.func.isRequired,
  getRenewalData: PropTypes.func.isRequired,
  handleRenewal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadingMemberInfo: PropTypes.bool,
  loadingUpdateMemberInfo: PropTypes.bool,
  memberInfo: PropTypes.object,
  nationalPlans: PropTypes.array,
  regionalPlans: PropTypes.array,
  updateMemberErrors: PropTypes.object.isRequired,
  updateMemberInfo: PropTypes.func.isRequired,
};

RenewalPage.defaultProps = {
  loadingMemberInfo: false,
  loadingUpdateMemberInfo: false,
  memberInfo: null,
  nationalPlans: null,
  regionalPlans: null,
};

const mapStateToProps = state => ({
  loadingMemberInfo: renewalSelectors.selectLoadingMemberInfo(state),
  loadingUpdateMemberInfo: renewalSelectors.selectLoadingUpdateMemberInfo(state),
  memberInfo: renewalSelectors.selectMemberInfo(state),
  nationalPlans: plansSelectors.selectNationalPlans(state),
  regionalPlans: plansSelectors.selectRegionalPlans(state),
  updateMemberErrors: renewalSelectors.selectUpdateRenewalMemberErrors(state),
});

const mapDispatchToProps = {
  getMemberInfo: memberActions.getMemberInfo,
  getMembershipDetails: memberActions.getMembershipDetails,
  getNationalPlans: plansActions.getNationalPlans,
  getRegionPlans: plansActions.getRegionPlans,
  getRenewalData: renewalActions.getRenewalData,
  handleRenewal: renewalActions.handleRenewal,
  updateMemberInfo: renewalActions.updateMemberInfo,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenewalPage));
